import { styled } from "styled-components";
import { ChevronDown, Dot } from "react-bootstrap-icons";

export const CommentHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Title = styled.div`
color: ${({ theme }) => theme.neutralText};
  font-weight: bold;
`;

export const TabSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
`;

export const TabList = styled.div`
  display: flex;
`;

interface TabProps {
  $active: boolean;
}

export const Tab = styled.button<TabProps>`
  all: unset;
  padding: 8px 0 8px 0;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ $active, theme }) => ($active ? theme.primaryBorder : "transparent")};
  color: ${({ $active, theme }) =>
    $active ? theme.neutralText : theme.neutralTextWeak};

  &:hover {
    color: ${({ $active, theme }) =>
      $active ? theme.primaryText : theme.neutralTextWeak};
  }
`;

export const SortSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
`;

export const SortButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  cursor: pointer;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const SortLabel = styled.span`
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const SortValue = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const SortIcon = styled(ChevronDown)<{ $isOpen: boolean }>`
  width: 16px;
  height: 16px;
  transform: rotate(${({ $isOpen }) => ($isOpen ? 180 : 0)}deg);
  transition: transform 0.2s ease-in-out;
`;

export const SortDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 8px;
  width: 182px;
  p {
    padding: 4px;
    font-size: 10px;
    width: 100%;
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const DropdownItem = styled.button`
  all: unset;
  display: flex;
  width: -webkit-fill-available;
  padding: 6px 4px;
  font-size: 14px;
  /* text-align: left; */
  cursor: pointer;
  color: ${({ theme }) => theme.neutralText};
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.neutralBackgroundHover};
    border-radius: 4px;
  }
`;

export const DotIcon = styled(Dot)`
  color: ${({ theme }) => theme.primaryText};
`;

export const ActionSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AddButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryText};
  background: ${({ theme }) => theme.neutralBackground};

  &:hover {
    background: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;

export const OptionButton = styled.button<{ $active: boolean }>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryText};

  background: ${({ $active, theme }) =>
    $active ? theme.neutralBackgroundHover : "transparent"};

  &:hover {
    background: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;

export const OptionDropdown = styled.div<{ $top?: string; $right?: string }>`
  position: absolute;
  top: ${({ $top }) => $top ? $top : "30px" };
  right: ${({ $right }) => $right ? $right : "38px" };
  margin-top: 8px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 8px;
  width: 200px;
`;
