import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import {
  CheckboxLabel,
  CheckboxWrapper,
  InteractivityBlock,
  InteractivityDescription,
  InteractivityHeadingWrapper,
  InteractivityLabel,
  InteractivityTitle,
  InteractivityWrapper,
} from "../styles";
import {
  getDraftPageSettings,
  getPageSettings,
} from "../../../../store/selectors/projects";
import { getModalCreateOptions } from "../../../../store/selectors/modals";
import {
  setDraftPageSettings,
  setPageSettings,
} from "../../../../store/slices/projectPages";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";

type Label = "showFilters" | "showSearch" | "showTooltip"

export const Interactivity = () => {
  const dispatch = useDispatch();
  const pageSettings = useSelector(getPageSettings);
  const pageDraftSettings = useSelector(getDraftPageSettings);
  const createNewProject = useSelector(getModalCreateOptions);
  const settings = createNewProject ? pageDraftSettings : pageSettings;
  const openedIneractivity =
    settings?.showFilters || settings?.showSearch || settings.showTooltip;
  const [interactivityState, setInteractivityState] =
    useState(openedIneractivity);

  const switchSettings = (labels: Label[]) => {
    const updatedSettings = { ...settings };
    labels.forEach((label) => {
      updatedSettings[label] = !settings[label];
    });

    dispatch(
      createNewProject
        ? setDraftPageSettings(updatedSettings)
        : setPageSettings(updatedSettings)
    );
  };

  return (
    <InteractivityWrapper>
      <InteractivityHeadingWrapper>
        <InteractivityTitle>Interactivity</InteractivityTitle>
        <SwitchComponent
          disableMargin
          checked={interactivityState}
          label={interactivityState ? "Hide" : "Show"}
          onChange={() => {
            setInteractivityState(!interactivityState);
          }}
        />
      </InteractivityHeadingWrapper>
      <InteractivityDescription>
        Can greatly enhance the user experience by making the data more
        accessible and easier to manipulate.
      </InteractivityDescription>
      {interactivityState ? (
        <InteractivityBlock>
          <InteractivityLabel>
            What functionality would you like to add?
          </InteractivityLabel>
          <CheckboxWrapper className="interactivity">
            <CheckBox
              checked={settings.showSearch && settings.showFilters}
              onChange={() => {
                switchSettings(["showSearch","showFilters"]);
              }}
            />
            <CheckboxLabel>Search + Filter</CheckboxLabel>
          </CheckboxWrapper>
          <CheckboxWrapper className="interactivity">
            <CheckBox
              checked={settings.showTooltip}
              onChange={() => switchSettings(["showTooltip"])}
            />
            <CheckboxLabel>Tooltips</CheckboxLabel>
          </CheckboxWrapper>
        </InteractivityBlock>
      ) : null}
    </InteractivityWrapper>
  );
};
