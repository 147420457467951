import { ProjectDTO } from "../../../../models/Projects";
import { WidgetItem } from "../../../../models/Widgets";
import { Button } from "../../../Button";
import { DatasetsItem } from "../../../DatasetsItem";
import RadioButton from "../../../Inputs/CustomRadio/RadioButton";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";

import {
  MapSettings,
  MapSettingsControls,
  MapSettingsSelectLayout,
  MapSettingsSelectLayoutHeading,
  MapSettingsSelectLayoutOptions,
} from "./styles";

import {
  MapSettingsControl,
  MapSettingsControlName,
  MapSettingsDatasets,
  MapSettingsDatasetsHeading,
  MapSettingsDatasetsWrapper,
  MapSettingsLeftSide,
  MapSettingsRightSide,
  SwitchDataset,
} from "../../styles";
import { ChartTypeSelect } from "../ChartTypeSelect";

interface MapConfigProps {
  currentWidget: WidgetItem;
  widgetData: WidgetItem;
  setWidgetData: React.Dispatch<React.SetStateAction<WidgetItem>>;
  projectData: ProjectDTO;
  selectedDimensions: string[];
  fullScreenLayout: boolean;
  setSelectedChart: (chart?: string) => void;
}

const MapConfig = ({
  currentWidget,
  widgetData,
  setWidgetData,
  projectData,
  selectedDimensions,
  fullScreenLayout,
  setSelectedChart,
}: MapConfigProps) => {
  return (
    <>
      <MapSettingsLeftSide>
        <MapSettingsDatasetsWrapper>
          <MapSettingsDatasetsHeading>
            Parameters
            {/* <Button
              onClick={() => {}}
              variant="primary"
              size="xs"
              name="Switch dataset"
              icon={<SwitchDataset />}
            /> */}
          </MapSettingsDatasetsHeading>

          <MapSettingsDatasets>
            {projectData.datasets.map((dataset) => (
              <DatasetsItem
                key={dataset.filePath}
                dataset={dataset}
                // selectedDimensions={selectedDimensions}
                // isDraggable
                disablePreview
              />
            ))}
          </MapSettingsDatasets>
        </MapSettingsDatasetsWrapper>

        <ChartTypeSelect
          currentWidget={currentWidget}
          widgetData={widgetData}
          setWidgetData={setWidgetData}
          setSelectedChart={setSelectedChart}
        />
      </MapSettingsLeftSide>

      <MapSettingsRightSide>
        <MapSettingsSelectLayout>
          <MapSettingsSelectLayoutHeading>
            Layout
          </MapSettingsSelectLayoutHeading>

          <MapSettingsSelectLayoutOptions>
            {[
              { label: "Card", value: "card", disabled: false },
              { label: "Minimalist", value: "minimalist", disabled: false },
              {
                label: "Full screen",
                value: "fullScreen",
                disabled: !fullScreenLayout,
              },
            ].map(({ label, value, disabled }) => (
              <RadioButton
                key={value}
                name="mapLayout"
                checked={
                  widgetData.layout
                    ? widgetData.layout === value
                    : label === "Card"
                }
                onChange={(e) =>
                  setWidgetData((prev) => ({
                    ...prev,
                    layout: e.target.value,
                  }))
                }
                label={label}
                value={value}
                disabled={disabled}
              />
            ))}
          </MapSettingsSelectLayoutOptions>
        </MapSettingsSelectLayout>

        <MapSettings>
          <MapSettingsControls>
            <MapSettingsControlName>Terrain background</MapSettingsControlName>

            <MapSettingsControl>
              <SwitchComponent
                label={widgetData.terrain ? "Show" : "Don't show"}
                checked={widgetData.terrain}
                onChange={(e) =>
                  setWidgetData((prev) => ({
                    ...prev,
                    terrain: e.target.checked,
                  }))
                }
              />
            </MapSettingsControl>
          </MapSettingsControls>

          <MapSettingsControls>
            <MapSettingsControlName>Search</MapSettingsControlName>

            <MapSettingsControl>
              <SwitchComponent
                label={widgetData.search ? "Show" : "Don't show"}
                checked={widgetData.search}
                onChange={(e) =>
                  setWidgetData((prev) => ({
                    ...prev,
                    search: e.target.checked,
                  }))
                }
              />
            </MapSettingsControl>
          </MapSettingsControls>

          <MapSettingsControls>
            <MapSettingsControlName>Timeline</MapSettingsControlName>

            <MapSettingsControl>
              <SwitchComponent
                label={widgetData.timeline ? "Show" : "Don't show"}
                checked={widgetData.timeline}
                onChange={(e) =>
                  setWidgetData((prev) => ({
                    ...prev,
                    timeline: e.target.checked,
                  }))
                }
              />
            </MapSettingsControl>
          </MapSettingsControls>
        </MapSettings>
      </MapSettingsRightSide>
    </>
  );
};

export default MapConfig;
