import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "leaflet/dist/leaflet.css";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  LoaderWrapper,
  MapWrapper,
  SelectBageBlock,
} from "./styles";
import { mapInitialization } from "./Config/mapInitialization";
import { MapLegend } from "../../MapLegend";
import { setActiveModal } from "../../../store/slices/modals";
import { trackZoomLevel } from "./Config/trackZoomLevel";
import { geoMarker } from "./Config/marker";
import { mapLayers } from "./Config/layers";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getPageSettings } from "../../../store/selectors/projects";
import { colorsPalettes } from "../../../constants";
import { MapYear } from "../../MapYear";
import {
  DataItem,
  WidgetItem,
} from "../../../models/Widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { ColorRangeI } from "../../../models/Pages";
import { Loader } from "../../Loader";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { generateBreakPoints } from "../../../helpers/generateBreakPoints";
import useMeasure from "react-use-measure";
import { useSharedMapControls } from "../../../hooks/useShareControls";
import { getAvailableWidgetTypes } from "../widgetHelpers";
import { SettingsButtonWrapper, DatavizRecommendedCount, DatavizSettingsIcon } from "../styles";

interface MapChartInterface {
  showSettings?: boolean;
  showLegend?: boolean;
  recommended?: boolean;
  selected?: boolean;
  isFullScreen?: boolean;
  currentWidget: WidgetItem;
  uniqueMapId?: string;
  isSharedControls?: boolean;
}

export const LeafletMap = ({
  showSettings = true,
  showLegend = true,
  recommended = false,
  selected = false,
  isFullScreen = false,
  currentWidget,
  uniqueMapId,
  isSharedControls = false,
}: MapChartInterface) => {
  const isEditMode = useSelector(getIsEditMode);
  const [mapType] = useState<"counties" | "states">("counties");
  const dispatch = useDispatch();
  const isPublicRoute = useSelector(getIsPublicMode);
  const mapRef = useRef<any>();
  const [ref, bounds] = useMeasure();
  const geoJSONLayersRef = useRef<any[]>([]);
  const markersRef = useRef<any[]>([]);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [layerData, setLayerData] = useState<DataItem[]>(
    []
  );
  const [timeValues, setTimeValues] = useState<string[] | []>([]);
  const [average, setAverage] = useState<number | undefined>();
  const [values, setValues] = useState<number[]>([]);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const currentStyle = styleId || "default";
  const colorPalette = colorsPalettes.find(
    (palette) => palette.id === currentStyle
  );
  const colorPaletteVariations = colorPalette?.colors[0].variations;

  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const isCard = !isSharedControls && currentWidget.layout === "card";
  const layer = currentWidget?.layers?.at(0);
  const year = layer?.data && [...new Set(layer.data.map(item => item['year']))].sort();
  const timePeriod = layer?.timePeriod
  const metricField = layer?.arrangeByMetric?.at(0) || "value";

  const id = useMemo(
    () =>
      recommended ? "recommended_map" : uniqueMapId || "map",
    [recommended, uniqueMapId]
  );

  const {
    showControls,
    currentPeriod,
    handleSetPeriod,
    currentLeftSlider,
    currentRightSlider,
    handleSetLeftSlider,
    handleSetRightSlider
  } = useSharedMapControls({ isSharedControls, id });

  const generateColorRanges = useCallback(() => {
    if (colorPaletteVariations?.length && values) {
      const colors = colorPaletteVariations?.slice()?.reverse();
      const sortedValues = [...new Set(values)].sort((a, b) => a - b);
      const steps = generateBreakPoints(sortedValues);
      
      const newColorRanges = steps?.map((c, index) => {
        const end = index === steps?.length - 1 
          ? Math.max(...sortedValues)
          : steps[index + 1];
          
        return {
          color:
            index >= currentLeftSlider && index <= currentRightSlider
              ? colors[index]
              : "#E0DDDC",
          start: parseInt(c?.toString()),
          end: parseInt(end?.toString()),
        };
      });
      setColorRanges(newColorRanges);
    }
  }, [colorPaletteVariations, values, currentLeftSlider, currentRightSlider]);

  useEffect(() => {
    if (colorPaletteVariations?.length) {
      generateColorRanges();
    }
  }, [colorPaletteVariations?.length, values, generateColorRanges]);

  useEffect(() => {
    if (!(mapRef && mapRef.current) && currentWidget?.id && id) {
      try {
        mapInitialization(mapRef, id, isFullScreen, currentWidget.terrain);
      } catch (e) {}
    }
    trackZoomLevel({ map: mapRef.current, setZoomLevel: setZoomLevel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWidget?.id, id]);

  useEffect(() => {
    if (currentWidget) {
      const layerData = layer?.data;
      const newValues: number[] = [];
      const sum = layerData?.reduce((acc, curr) => {
        const value = parseFloat(curr?.[metricField]);
        newValues.push(value);
        return acc + value;
      }, 0) || 0;
      
      setValues(newValues);
      setAverage(sum / (layerData?.length || 1));
      setLayerData(layerData || []);
      
      if (timePeriod?.values?.length) {
        handleSetPeriod(timePeriod.values[0]);
        setTimeValues(timePeriod.values);
      } else if (year?.length) {
        handleSetPeriod(year[0]);
        setTimeValues(year);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWidget, layer?.data, metricField]);

  useEffect(() => {
    geoMarker({
      markersRef,
      mapRef,
      zoomLevel,
      mapType,
      aiSuggestions,
    });
  }, [zoomLevel, mapType, aiSuggestions]);

  useEffect(() => {
    if (colorRanges?.length && mapRef && mapRef.current) {
      const field = timePeriod?.field || 'year';
      const data = layerData?.filter((d) => String(d[field]) === currentPeriod);
      const widgetTooltip = currentWidget.tooltip;
      const widgetPreview = recommended;

      mapLayers({
        geoJSONLayersRef,
        mapRef,
        mapType,
        colorRanges,
        data,
        showTooltip,
        widgetTooltip,
        widgetPreview,
        metricField,
        timePeriodField: field,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapType, colorRanges, layerData, currentPeriod]);

  useEffect(() => {
    if (mapRef.current) {
      const mapInstance = mapRef.current.leafletElement || mapRef.current;
      mapInstance.invalidateSize();
    }
  }, [bounds.height, bounds.width]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  return (
    <Suspense>
      <Container>
        <MapWrapper
          ref={ref}
          id={id}
          style={{
            position: "absolute",
            width: `calc(100% - ${isCard ? "32px" : "0px"})`,
            height: `calc(100% - ${
              isCard
                ? recommended || !currentWidget.timeline
                  ? "56px"
                  : "114px"
                : "0px"
            })`,
            top: 0,
            left: 0,
            margin: `${
              isCard
                ? recommended || !currentWidget.timeline
                  ? "40px 16px 16px"
                  : "40px 16px 74px"
                : "0px"
            }`,
            borderRadius: "6px",
            
          }}
        />
        {layerData?.length ? (
          <>
            {!isPublicRoute && isEditMode && !recommended && !isSharedControls ? (
              <SettingsButtonWrapper
                $modalOpen={!showSettings}
                $map
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {showLegend && showControls ? (
              <MapLegend
                colorRanges={colorRanges}
                average={average!}
                leftSlider={currentLeftSlider}
                rightSlider={currentRightSlider}
                setLeftSlider={handleSetLeftSlider}
                setRightSlider={handleSetRightSlider}
                timeline={currentWidget.timeline!}
                isCard={isCard}
              />
            ) : null}
            {timeValues?.length && currentWidget.timeline && !recommended && showControls ? (
              <MapYear
                years={timeValues}
                setYear={handleSetPeriod}
                selectedYear={currentPeriod!}
                isCard={isCard}
                isSharedControls={isSharedControls}
              />
            ) : null}
            {recommended ? (
              <SelectBageBlock>
                <SelectBage selected={selected} />
              </SelectBageBlock>
            ) : null}
          </>
        ) : (
          <LoaderWrapper>
            <Loader blur={false} />
          </LoaderWrapper>
        )}
      </Container>
    </Suspense>
  );
};
