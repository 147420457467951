import styled from "styled-components";

export const Menu = styled.div`
  display: flex;
  height: 36px;
  gap: 10px;
  position: relative;
  background: ${({ theme }) => theme.neutralBackgroundBase};
`;

export const SortButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const Text = styled.span`
  width:111px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  align-self: center;
  color: ${({ theme }) => theme.neutralText};
  cursor: pointer;
`;
