import styled from "styled-components";

const getSize = (size?: 'default' | 'base') => {
  if (size === 'base') {
    return '40px'
  }
  return '36px'
}

export const InputContainer = styled.div<{ $disablePaddings?: boolean; }>`
  padding: ${({ $disablePaddings }) => $disablePaddings ? '' : '0 20px'};
  margin-bottom: ${({ $disablePaddings }) => $disablePaddings ? '' : '16px'};
`;

export const Heading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const InputWrapper = styled.div<{ $size?: 'default' | 'base', $disablePaddings?: boolean; }>`
  display: flex;
  height: ${({ $size }) => getSize($size)};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 5px;
  margin-top: ${({ $disablePaddings }) => $disablePaddings ? '' : '8px'};
  width: 100%;
  overflow: hidden;

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`;

export const StyledInput = styled.input<{ $size?: 'default' | 'base'; }>`
  width: 100%;
  border-style: none;
  padding: 0 14px;
  height: ${({ $size }) => getSize($size)};
  align-self: center;
  border-radius: 5px;
  outline: none;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus-within {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
  }
`;
