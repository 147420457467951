import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DatasetOverviewLoaderWrapper,
  HeadingIcon,
  HeadingText,
  Icon,
  ModalContent,
  ModalFooter,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  Separator,
  Table,
  TableColumn,
  TableHeading,
  TableRow,
  TotalFields,
} from "./styles";
import {
  requestGetDatasetContents,
  resetDatasetContents,
} from "../../../store/slices/datasets";
import { Search } from "../../SearchBar";
import { getSelectedDataset } from "../../../store/selectors/datasets";
import { CloseIcon } from "../styles";
import { Loader } from "../../Loader";
import { getIconSrc } from "../../../helpers/getIconType";
import { Button } from "../../Button";
import { useDebounceCallback } from "usehooks-ts";

interface DatasetsOverviewModalProps {
  closeModal: () => void;
}

export const DatasetsOverviewModal = ({
  closeModal,
}: DatasetsOverviewModalProps) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState<string>("");
  const selectedDataset = useSelector(getSelectedDataset);
  const headers = selectedDataset?.fields;
  const [selectedDatasetContents, setDatasetContents] = useState<any[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [isLoading] = useState<boolean>(false);
  const [showLoad, setShowLoad] = useState<boolean>(false);

  const resetSearch = (value: string) => {
    setSkip(0);
    setDatasetContents([]);
    setSearchInput(value);
  };

  const debouncedSearch = useDebounceCallback(resetSearch, 500);

  useEffect(() => {
    dispatch(
      requestGetDatasetContents({
        id: selectedDataset?.id!,
        limit: 20,
        skip,
        search: searchInput,
        callbacks: {
          onSuccess: (data) => {
            const newItems = [...selectedDatasetContents, ...data.items];
            setShowLoad(data.count > newItems?.length);
            setDatasetContents(newItems);
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, searchInput]);

  const loadMore = () => {
    setSkip(skip + 20);
  };

  return (
    <ModalOverlay onClick={closeModal}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <ModalHeadingWrapper>
          <ModalHeading>
            {selectedDataset?.name.charAt(0).toUpperCase()! +
              selectedDataset?.name.slice(1, -4)}
            <TotalFields>{selectedDataset?.fields?.length}</TotalFields>
          </ModalHeading>
          <Search width="xs" placeholder="Search" onChange={debouncedSearch} />
          <CloseIcon
            onClick={() => {
              closeModal();
              dispatch(resetDatasetContents());
            }}
          />
        </ModalHeadingWrapper>
        <Separator $top />
        <ModalContent>
          {isLoading ? (
            <DatasetOverviewLoaderWrapper>
              <Loader />
            </DatasetOverviewLoaderWrapper>
          ) : (
            <Table>
              {headers?.map((datasetItem, idx) => {
                return (
                  <TableColumn key={idx}>
                    <TableHeading>
                      <HeadingIcon>
                        <Icon
                          src={getIconSrc({
                            type: datasetItem.type,
                            subtype: datasetItem.subtype,
                          })}
                        />
                      </HeadingIcon>
                      <HeadingText>
                        {datasetItem.label?.charAt(0)?.toUpperCase()! +
                          datasetItem.label?.slice(1)}
                      </HeadingText>
                    </TableHeading>
                    {selectedDatasetContents?.map((item: any, idx: number) => {
                      return (
                        <TableRow key={idx}>
                          {item[datasetItem?.key]
                            ? item[datasetItem?.key]
                            : "No data"}
                        </TableRow>
                      );
                    })}
                  </TableColumn>
                );
              })}
            </Table>
          )}
          {showLoad ? (
            <ModalFooter>
              <Button
                onClick={loadMore}
                variant={"primary"}
                size="xs"
                name={"Load More"}
              />
            </ModalFooter>
          ) : null}
        </ModalContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};
