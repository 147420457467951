import React, { useState, useEffect, useRef, useCallback } from "react";
import { toPng } from "html-to-image";
import * as s from "./styled";
import { getPageSettings } from "../../../store/selectors/projects";
import { useSelector } from "react-redux";
import { getIsOpenThumbnailsScroll, getThumbnailForceRegenerate } from "../../../store/selectors/reportPDF";

interface ThumbnailData {
  id: string;
  image: string;
  pageNumber: number;
}

interface MiniatureNavigationProps {
  containerRef: React.RefObject<HTMLElement>;
  loading: boolean;
  scrollOffset?: number;
  thumbnailDelay?: number;
  maxRetries?: number;
}

const IMAGE_OPTIONS = {
  width: 200,
  height: 258,
  style: {
    transform: "scale(0.2)",
    transformOrigin: "top left",
    width: "1000px",
    height: "1290px",
  },
  quality: 1,
  pixelRatio: 0.8,
  backgroundColor: "#FFFFFF",
  cacheBust: true,
  fontEmbedCSS:
    '@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");',
};

const MiniatureNavigation: React.FC<MiniatureNavigationProps> = ({
  containerRef,
  loading,
  scrollOffset = 137,
  thumbnailDelay = 500,
  maxRetries = 3,
}) => {
  const currentPage = useSelector(getPageSettings);
  const thumbnailForceRegenerate = useSelector(getThumbnailForceRegenerate);
  const isOpenThumbnailsScroll = useSelector(getIsOpenThumbnailsScroll);

  const [thumbnails, setThumbnails] = useState<ThumbnailData[]>([]);
  const [selectedPage, setSelectedPage] = useState<number | null>(null);
  const elementRefs = useRef<(Element | null)[]>([]);
  const navigationRef = useRef<HTMLDivElement>(null);
  const isScrollingRef = useRef(false);
  const isMouseOverRef = useRef(false);
  const retryCountRef = useRef<{ [key: string]: number }>({});
  const imageVersionRef = useRef<number>(0);

  const delay = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const trackImageChanges = async (element: HTMLElement): Promise<void> => {
    const currentVersion = ++imageVersionRef.current;

    return new Promise((resolve) => {
      const observer = new MutationObserver(async (mutations) => {
        const hasImageChanges = mutations.some((mutation) =>
          Array.from(mutation.addedNodes).some(
            (node) =>
              node instanceof HTMLImageElement ||
              (node instanceof HTMLElement && node.querySelector("img"))
          )
        );

        if (hasImageChanges) {
          observer.disconnect();
          await delay(100);
          if (currentVersion === imageVersionRef.current) {
            resolve();
          }
        }
      });

      observer.observe(element, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["src"],
      });

      setTimeout(() => {
        observer.disconnect();
        resolve();
      }, thumbnailDelay);
    });
  };

  const loadImage = async (img: HTMLImageElement): Promise<void> => {
    const imgSrc = img.src;
    retryCountRef.current[imgSrc] = retryCountRef.current[imgSrc] || 0;

    return new Promise((resolve, reject) => {
      if (img.complete && img.naturalHeight !== 0) {
        resolve();
        return;
      }

      const loadHandler = () => {
        cleanup();
        resolve();
      };

      const errorHandler = async () => {
        cleanup();
        retryCountRef.current[imgSrc]++;

        if (retryCountRef.current[imgSrc] < maxRetries) {
          try {
            const newSrc = `${imgSrc.split("?")[0]}?t=${Date.now()}&retry=${
              retryCountRef.current[imgSrc]
            }`;
            img.src = newSrc;

            await delay(
              Math.min(
                1000 * Math.pow(2, retryCountRef.current[imgSrc] - 1),
                5000
              )
            );
            await loadImage(img);
            resolve();
          } catch (error) {
            reject(error);
          }
        } else {
          console.warn(
            `Failed to load image after ${maxRetries} retries:`,
            imgSrc
          );
          resolve();
        }
      };

      const cleanup = () => {
        img.removeEventListener("load", loadHandler);
        img.removeEventListener("error", errorHandler);
      };

      img.addEventListener("load", loadHandler);
      img.addEventListener("error", errorHandler);
    });
  };

  const waitForImages = async (element: HTMLElement): Promise<void> => {
    await trackImageChanges(element);

    const images = element.getElementsByTagName("img");
    if (images.length === 0) return;

    const imageLoadPromises = Array.from(images).map((img) => loadImage(img));

    try {
      await Promise.all(imageLoadPromises);
      await delay(thumbnailDelay);
    } catch (error) {
      console.error("Error waiting for images:", error);
    }
  };

  const generateThumbnail = async (
    element: Element | null,
    index: number,
    retryCount = 0
  ): Promise<ThumbnailData | null> => {
    if (!element) return null;

    try {
      await delay(100);
      await waitForImages(element as HTMLElement);

      if (!document.contains(element)) {
        throw new Error("Element no longer in document");
      }

      const dataUrl = await toPng(element as HTMLElement, {
        ...IMAGE_OPTIONS,
        cacheBust: true,
        imagePlaceholder:
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
      });

      return {
        id: `page-${index + 1}`,
        image: dataUrl,
        pageNumber: index + 1,
      };
    } catch (error) {
      console.error("Error generating thumbnail:", error);

      if (retryCount < maxRetries) {
        await delay(Math.min(1000 * Math.pow(2, retryCount), 5000));
        return generateThumbnail(element, index, retryCount + 1);
      }

      return null;
    }
  };

  const scrollToSelectedPage = useCallback(
    (index: number, immediate = false) => {
      const element = elementRefs.current[index];
      if (!element) return;

      requestAnimationFrame(() => {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - scrollOffset;

        isScrollingRef.current = true;

        if (immediate) {
          window.scrollTo(0, offsetPosition);
        } else {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }

        setTimeout(
          () => {
            isScrollingRef.current = false;
          },
          immediate ? 0 : 1000
        );
      });
    },
    [scrollOffset]
  );

  const handleThumbnailClick = useCallback(
    (index: number) => {
      setSelectedPage(index);
      scrollToSelectedPage(index);
    },
    [scrollToSelectedPage]
  );

  const generateThumbnails = async () => {
    if (!containerRef.current) return;

    imageVersionRef.current++;
    retryCountRef.current = {};

    const sections = {
      firstPage: containerRef.current.querySelector("#first-page"),
      tableOfContent: containerRef.current.querySelector("#table-of-content"),
      content: containerRef.current.querySelector("#content"),
      lastPage: containerRef.current.querySelector("#last-page"),
    };

    const newThumbnails: ThumbnailData[] = [];
    elementRefs.current = [];

    const processSection = async (element: Element | null, index: number) => {
      if (!element) return;
      const thumbnail = await generateThumbnail(element, index);
      if (thumbnail) {
        newThumbnails.push(thumbnail);
        elementRefs.current.push(element);
      }
    };

    let currentIndex = 0;

    try {
      if (sections.firstPage && currentPage.showHeader) {
        await processSection(sections.firstPage, currentIndex++);
      }

      if (sections.tableOfContent && currentPage.tableOfContents) {
        for (const section of Array.from(
          sections.tableOfContent.children || []
        )) {
          await processSection(section as Element, currentIndex++);
        }
      }

      if (sections.content) {
        for (const section of Array.from(sections.content.children || [])) {
          await processSection(section as Element, currentIndex++);
        }
      }

      if (sections.lastPage && currentPage.showHeader) {
        await processSection(sections.lastPage, currentIndex++);
      }

      const currentVersion = imageVersionRef.current;
      if (newThumbnails.length > 0) {
        setThumbnails((prevThumbnails) => {
          if (currentVersion === imageVersionRef.current) {
            requestAnimationFrame(() => {
              if (selectedPage !== null) {
                scrollToSelectedPage(selectedPage, true);
              }
            });
            return newThumbnails;
          }
          return prevThumbnails;
        });
      }
    } catch (error) {
      console.error("Error generating thumbnails:", error);
    }
  };

  const handleScroll = useCallback(() => {
    if (isScrollingRef.current || isMouseOverRef.current || !thumbnails.length)
      return;

    if (containerRef.current && navigationRef.current) {
      const containerHeight = containerRef.current.scrollHeight;
      const navigationHeight = navigationRef.current.scrollHeight;
      const scrollPercentage =
        window.scrollY / (containerHeight - window.innerHeight);
      const scrollPosition =
        scrollPercentage *
        (navigationHeight - navigationRef.current.clientHeight);
      navigationRef.current.scrollTop = scrollPosition;

      const viewportMiddle = window.innerHeight / 2;
      let closestElement = null;
      let closestDistance = Infinity;

      elementRefs.current.forEach((el, index) => {
        if (el) {
          const rect = el.getBoundingClientRect();
          const elementMiddle = rect.top + rect.height / 2;
          const distance = Math.abs(elementMiddle - viewportMiddle);

          if (distance < closestDistance) {
            closestDistance = distance;
            closestElement = index;
          }
        }
      });

      if (closestElement !== null && closestElement !== selectedPage) {
        setSelectedPage(closestElement);
      }
    }
  }, [thumbnails.length, containerRef, selectedPage]);

  useEffect(() => {
    if (loading) return;

    const timeoutId = setTimeout(() => {
      generateThumbnails();
    }, 100);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    currentPage,
    thumbnailDelay,
    maxRetries,
    thumbnailForceRegenerate,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <s.NavigationContainer
      ref={navigationRef}
      onMouseEnter={() => (isMouseOverRef.current = true)}
      onMouseLeave={() => (isMouseOverRef.current = false)}
      $isHidden={!isOpenThumbnailsScroll}
    >
      {!thumbnails.length ? (
        <>
          {Array.from({ length: 6 }).map((_, index) => (
            <s.SkeletonContainer key={index}>
              <s.Skeleton />
              <s.PageNumber>{index + 1}.</s.PageNumber>
            </s.SkeletonContainer>
          ))}
        </>
      ) : (
        <>
          {thumbnails.map((thumbnail, index) => (
            <s.ThumbnailContainer key={thumbnail.id}>
              <s.Thumbnail
                $isSelected={selectedPage === index}
                onClick={() => handleThumbnailClick(index)}
              >
                <s.ThumbnailImage
                  src={thumbnail.image}
                  alt={`Page ${thumbnail.pageNumber}`}
                />
              </s.Thumbnail>
              <s.PageNumber>{thumbnail.pageNumber}.</s.PageNumber>
            </s.ThumbnailContainer>
          ))}
          ;
        </>
      )}
      ;
    </s.NavigationContainer>
  );
};

export default MiniatureNavigation;
