import styled from "styled-components";


export const WidgetWrapper = styled.div<{
  $storytelling?: boolean;
  $map?: boolean;
  $selected?: boolean;
  $recommended?: boolean;
  $isFullTemplate?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${({ $storytelling, $map }) =>
    $storytelling || $map ? "" : "12px 16px 12px 16px"};

  border: ${({ theme, $storytelling, $selected, $recommended }) =>
    $selected && !$recommended
      ? `1px solid transparent`
      : !$storytelling && !$selected
      ? `1px solid ${theme.neutralBorder}`
      : `1px solid transparent`};
  border-radius: 8px;
  height: ${({ $storytelling }) => ($storytelling ? "360px" : "100%")};
  width: 100%;
  min-width: 300px;
  background: ${({ theme, $selected, $recommended }) =>
    $selected && $recommended
      ? theme.primaryBackground
      : theme.neutralBackgroundBase};

  & .barchartAxisLine {
    stroke: ${({ theme }) => theme.neutralBorderMedium} !important;
  }
`;

export const HeaderWrapper = styled.div<{
  $isRow?: boolean;
}>`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  flex-direction: ${({ $isRow }) => ($isRow ? "row" : "column")};
  align-items: flex-start;
  gap: 8px;
`;

export const HeadingNameAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
  line-height: 20px;
`;
