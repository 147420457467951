import { RequestCallbacks, ServerResponse } from "../../models";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  clonePage,
  createPage,
  getAllPages,
  getAllPublicPages,
  getPageById,
  getPublicPageById,
  PagesQuery,
  patchPage,
  removePage,
} from "../../api/pages";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  resetDraftSettings,
  resetPageSettings,
  setAllPages,
  setCurrentPageIsLoading,
  setIsLoading,
  setPageSettings,
  setPageWidgets,
  updatePagesItems,
} from "../slices/projectPages";
import { PageSettingsDTO, UpdatePageDTO } from "../../models/Pages";
import { toast } from "react-toastify";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import { getCurrentProjectId } from "../selectors/projects";
import {
  getPublicProjectPage,
  getPublicProjectPages,
} from "../../api/publicProjects";
import { setReportActionType, setIsPageTypeSelect } from "../slices/appConfig";

export function* handleGetAllPages(action: PayloadAction<Partial<PagesQuery>>) {
  yield put(setIsLoading(true));
  const {
    limit,
    skip,
    projectId,
    orderBy = "createdAt",
    orderDirection = "ASC",
  } = action.payload;
  const { response }: ServerResponse = yield call(getAllPages, {
    limit,
    projectId,
    skip,
    orderBy,
    orderDirection,
  });
  if (response) {
    yield put(setIsLoading(false));
  }
  if (response?.status === 200) {
    yield put(setAllPages(response.data));
  }
}

export function* handleGetAllPublicPages(action: PayloadAction<Partial<PagesQuery>>) {
  yield put(setIsLoading(true));
  const { limit, skip, projectId } = action.payload;
  const { response }: ServerResponse = yield call(getAllPublicPages, {
    limit,
    projectId,
    skip,
  });
  if (response) {
    yield put(setIsLoading(false));
  }
  if (response?.status === 200) {
    yield put(setAllPages(response.data));
  }
}

export function* handleCreatePage(action: PayloadAction<PageSettingsDTO>): any {
  yield put(setCurrentPageIsLoading(true))
  const { callbacks } = action.payload
  const { response }: ServerResponse = yield call(createPage, action.payload);
  if (response?.status === 201) {
    const currentProjectId = yield select(getCurrentProjectId);
    yield put(resetPageSettings());
    yield put(resetDraftSettings());
    yield put(setPageWidgets({ items: [], count: 0 }));
    yield put(setPageSettings(response.data));
    yield put(updatePagesItems(response.data));
    yield put(setIsPageTypeSelect(false))
    yield put(setReportActionType("unset"))
    toast.success("Page created successfully");
    callbacks?.onSuccess(response.data);
    if (response.data.id) {
      action.payload.navigate!(
        `/projects/${currentProjectId}/${response.data.id}`
      );
    }
  }
  yield put(setCurrentPageIsLoading(false))
}

export function* handleClonePageById(
  action: PayloadAction<{
    id: string;
    projectId: string;
    navigate?: NavigateFunction;
    callbacks?: RequestCallbacks<any>;
  }>
) {
  yield put(setCurrentPageIsLoading(true))

  const { response }: ServerResponse = yield call(
    clonePage,
    {
      id: action.payload.id,
      projectId: action.payload.projectId
    }
  );

  if (response?.status === 200) {
    yield put(resetPageSettings());
    yield put(
      resetDraftSettings()
    );
    yield put(updatePagesItems(response.data));
    action?.payload?.callbacks?.onSuccess(response.data);
    if (response.data.id) {
      action.payload.navigate!(
        `/projects/${action.payload.projectId}/${response.data.id}`
      );
    }
  }
  
  yield put(setCurrentPageIsLoading(false))
}

export function* handleGetPageById(
  action: PayloadAction<{ id: string; callbacks?: RequestCallbacks<any> }>
) {
  const { id, callbacks } = action.payload;
  const { response }: ServerResponse = yield call(getPageById, id);
  if (response?.status === 200) {
    callbacks?.onSuccess(response.data);
    yield put(setPageSettings(response.data));
  }
}

export function* handleGetPublicPageById(
  action: PayloadAction<{ id: string; callbacks?: RequestCallbacks<any> }>
) {
  const { id, callbacks } = action.payload;
  const { response }: ServerResponse = yield call(getPublicPageById, id);
  if (response?.status === 200) {
    callbacks?.onSuccess(response.data);
    yield put(setPageSettings(response.data));
  }
}


export function* handleRemovePage(
  action: PayloadAction<{ id: string; navigate: NavigateFunction }>
): any {
  const { response }: ServerResponse = yield call(
    removePage,
    action.payload.id
  );

  if (response?.status === 200) {
    const projectId = yield select(getCurrentProjectId);
    action.payload.navigate(`/projects/${projectId}`);
    toast.success("Page removed successfully");
  }
}

export function* handleUpdatePage(action: PayloadAction<UpdatePageDTO>) {
  const { response }: ServerResponse = yield call(patchPage, {
    id: action.payload.id,
    pageData: { ...action.payload },
  });
  if (response?.status === 200) {
    toast.success("Changes saved");
    if (!action.payload.disableSaveCurrentPage) { yield put(setPageSettings(response.data)); }
    action.payload.callbacks?.onSuccess(response.data);
  }
}

export function* handleUpdatePageInBackgound(action: PayloadAction<UpdatePageDTO>) {
  yield call(patchPage, {
    id: action.payload.id,
    pageData: { ...action.payload },
  });
}

export function* handlePublicProjectPagesId(action: PayloadAction<string>) {
  yield put(setIsLoading(true));
  const { response }: ServerResponse = yield call(
    getPublicProjectPages,
    action.payload
  );
  if (response) {
    yield put(setIsLoading(false));
  }
  if (response?.status === 200) {
    if (response.data?.items?.length) {
      yield put(setPageSettings(response.data?.items[0]));
    }
    yield put(setAllPages(response.data));
  }
}

export function* requestPublicProjectPageById(
  action: PayloadAction<{
    id: string;
    pageId: string;
  }>
) {
  const { response }: ServerResponse = yield call(
    getPublicProjectPage,
    action.payload
  );
  if (response?.status === 200) {
    yield put(setPageWidgets(response.data));
  }
}

export function* watchProjectPagesSaga() {
  yield takeLatest("currentProject/requestAllPages", handleGetAllPages);
  yield takeLatest("currentProject/requestAllPublicPages", handleGetAllPublicPages);
  yield takeLatest("currentProject/requestCreatePage", handleCreatePage);
  yield takeLatest("currentProject/requestClonePage", handleClonePageById);
  yield takeLatest("currentProject/requestPageById", handleGetPageById);
  yield takeLatest("currentProject/requestPublicPageById", handleGetPublicPageById);
  yield takeLatest("currentProject/requestRemovePage", handleRemovePage);
  yield takeLatest("currentProject/requestUpdatePage", handleUpdatePage);
  yield takeLatest(
    "currentProject/requestPublicProjectPagesId",
    handlePublicProjectPagesId
  );
  yield takeLatest(
    "currentProject/requestPublicProjectPageById",
    requestPublicProjectPageById
  );
  yield takeLatest("currentProject/requestUpdatePageInBackgound", handleUpdatePageInBackgound);
}
