import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PdfState {
  documentName: string;
  pdfGenerationTrigger: boolean;
  pdfGenerationProgress: number;
  thumbnailForceRegenerate: number;
  isOpenThumbnailsScroll: boolean;
}

const initialState: PdfState = {
  documentName: "Report 1",
  pdfGenerationTrigger: false,
  pdfGenerationProgress: 0,
  thumbnailForceRegenerate: 0,
  isOpenThumbnailsScroll: true,
};

const pdfSlice = createSlice({
  name: "pdfSlice",
  initialState,
  reducers: {
    updateDocumentName: (state, action: PayloadAction<string>) => {
      state.documentName = action.payload;
    },
    triggerPDFGeneration: (state) => {
      state.pdfGenerationTrigger = true;
    },
    resetPDFGenerationTrigger: (state) => {
      state.pdfGenerationTrigger = false;
    },
    setPDFGenerationProgress: (state, action: PayloadAction<number>) => {
      state.pdfGenerationProgress = action.payload;
    },
    setThumbnailForceRegenerate: (state) => {
      state.thumbnailForceRegenerate = state.thumbnailForceRegenerate + 1
    },
    setIsOpenThumbnailsScroll: (state) => {
      state.isOpenThumbnailsScroll = !state.isOpenThumbnailsScroll
    }
  },
});

export const {
  updateDocumentName,
  triggerPDFGeneration,
  resetPDFGenerationTrigger,
  setPDFGenerationProgress,
  setThumbnailForceRegenerate,
  setIsOpenThumbnailsScroll,
} = pdfSlice.actions;
export default pdfSlice.reducer;
