import React from "react";
import * as s from "./styles";
import { SearchItem } from "../../types";
import { DimensionIcons } from "../CategoryIcons/CategoryIcons";

interface SearchOptionProps {
  item: SearchItem;
  isSelected: boolean;
  onOptionClick?: (item: SearchItem) => void;
  onItemSelect: (item: SearchItem) => void;
  hasItemCount?: boolean;
}

export const SearchOption: React.FC<SearchOptionProps> = ({
  item,
  isSelected,
  onOptionClick,
  onItemSelect,
  hasItemCount = true,
}) => {
  const handleItemClick = (e: React.MouseEvent) => {
    if (onOptionClick) return;
    e.stopPropagation();
    onItemSelect(item);
  };

  return (
    <s.OptionItem
      onClick={() => onOptionClick && onOptionClick(item)}
      $isSelected={isSelected}
    >
      <s.ItemContainer onClick={handleItemClick}>
        {!item.items && (
          <s.Checkbox
            type="checkbox"
            checked={isSelected}
            name={item.name}
            id={item.name}
            onChange={() => {}} // Empty onChange to prevent React warning
          />
        )}
        {item.items && <DimensionIcons state={item.name.toLowerCase()} />}
        <s.OptionText>{item?.name}</s.OptionText>
      </s.ItemContainer>
      {hasItemCount && !!item?.count && item.items?.length && (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <s.CountBadge>{item?.count}</s.CountBadge>
        </div>
      )}
    </s.OptionItem>
  );
};
