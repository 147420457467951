import { CSSProperties, useEffect, useMemo, useState, memo, ReactNode } from "react";

interface LayoutColumn {
  size: number;
  scroll: boolean;
  isWidget: boolean;
  children: ReactNode;
  full?: boolean;
  hasPadding?: boolean;
}

export const Column = memo(({ size, scroll, isWidget, children, full = false, hasPadding = false }: LayoutColumn) => {
  const [flexBasis, setFlexBasis] = useState(size);

  useEffect(() => {
    const handleResize = () => {
      setFlexBasis(window.innerWidth < 768 ? 12 : size);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [size]);

  const style: CSSProperties = useMemo(() => {
    const width = (100 * flexBasis) / 12;
    let baseStyle: CSSProperties = {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: `${width}%`,
      gap: "8px",
      position: "relative",
    };

    if (full) {
      baseStyle = {
        ...baseStyle,
        width: "calc(100% - 40px)",
        height: "calc(100% - 72px)",
        position: "absolute",
      };
    }
    if (scroll) {
      baseStyle = {
        ...baseStyle,
        overflowY: "auto",
        zIndex: 2,
      };
    }
    if (hasPadding) {
      baseStyle = {
        ...baseStyle,
        padding: "8px",
      };
    }
    if (!isWidget) {
      baseStyle = {
        ...baseStyle,
        margin: "auto",
      };
    }
    return baseStyle;
  }, [flexBasis, full, scroll, hasPadding, isWidget]);

  return <div style={style}>{children}</div>;
});

interface RowProps {
  children: ReactNode;
  height?: string;
  minHeight?: string;
}

export const Row = memo(({ children, height, minHeight = "200px" }: RowProps) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      height: height || "100%",
      minHeight,
      width: "100%",
      gap: "8px",
    }}
  >
    {children}
  </div>
));