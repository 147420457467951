import { ReactComponent as A13 } from '../../../../assets/layoutsSVG/Basic charts.svg';
import { ReactComponent as A12 } from '../../../../assets/layoutsSVG/Basic charts-1.svg';
import { ReactComponent as A11 } from '../../../../assets/layoutsSVG/Basic charts-2.svg';
import { ReactComponent as A14 } from '../../../../assets/layoutsSVG/Basic charts-3.svg';
import { ReactComponent as A15 } from '../../../../assets/layoutsSVG/Basic charts-4.svg';
import { ReactComponent as C17 } from '../../../../assets/layoutsSVG/Basic charts-5.svg';
import { ReactComponent as A16 } from '../../../../assets/layoutsSVG/Basic charts-6.svg';
import { ReactComponent as A18 } from '../../../../assets/layoutsSVG/Basic charts-7.svg';
import { ReactComponent as B18 } from '../../../../assets/layoutsSVG/Basic charts-8.svg';
import { ReactComponent as B13 } from '../../../../assets/layoutsSVG/Basic charts-9.svg';
import { ReactComponent as B12 } from '../../../../assets/layoutsSVG/Basic charts-10.svg';
import { ReactComponent as B14 } from '../../../../assets/layoutsSVG/Basic charts-11.svg';
import { ReactComponent as B15 } from '../../../../assets/layoutsSVG/Basic charts-12.svg';
import { ReactComponent as B16 } from '../../../../assets/layoutsSVG/Basic charts-13.svg';
import { ReactComponent as C16 } from '../../../../assets/layoutsSVG/Basic charts-14.svg';
import { ReactComponent as A17 } from '../../../../assets/layoutsSVG/Basic charts-15.svg';
import { ReactComponent as B17 } from '../../../../assets/layoutsSVG/Basic charts-16.svg';
import { ReactComponent as C13 } from '../../../../assets/layoutsSVG/Basic charts-17.svg';
import { ReactComponent as C14 } from '../../../../assets/layoutsSVG/Basic charts-18.svg';
import { ReactComponent as C15 } from '../../../../assets/layoutsSVG/Basic charts-19.svg';
import { ReactComponent as C18 } from '../../../../assets/layoutsSVG/Basic charts-20.svg';
import { ReactComponent as A21 } from '../../../../assets/layoutsSVG/Basic charts-21.svg';
import { ReactComponent as B22 } from '../../../../assets/layoutsSVG/Basic charts-22.svg';
import { ReactComponent as C22 } from '../../../../assets/layoutsSVG/Basic charts-23.svg';
import { ReactComponent as A23 } from '../../../../assets/layoutsSVG/Basic charts-23.svg';
import { ReactComponent as B23 } from '../../../../assets/layoutsSVG/Basic charts-24.svg';
import { ReactComponent as C23 } from '../../../../assets/layoutsSVG/Basic charts-25.svg';
import { ReactComponent as A24 } from '../../../../assets/layoutsSVG/Basic charts-26.svg';
import { ReactComponent as B24 } from '../../../../assets/layoutsSVG/Basic charts-27.svg';
import { ReactComponent as C24 } from '../../../../assets/layoutsSVG/Basic charts-28.svg';
import { ReactComponent as A25 } from '../../../../assets/layoutsSVG/Basic charts-29.svg';
import { ReactComponent as B25 } from '../../../../assets/layoutsSVG/Basic charts-30.svg';
import { ReactComponent as C25 } from '../../../../assets/layoutsSVG/Basic charts-31.svg';
import { ReactComponent as A26 } from '../../../../assets/layoutsSVG/Basic charts-32.svg';
import { ReactComponent as B26 } from '../../../../assets/layoutsSVG/Basic charts-33.svg';
import { ReactComponent as C26 } from '../../../../assets/layoutsSVG/Basic charts-34.svg';
import { ReactComponent as A27 } from '../../../../assets/layoutsSVG/Basic charts-35.svg';
import { ReactComponent as B27 } from '../../../../assets/layoutsSVG/Basic charts-36.svg';
import { ReactComponent as C27 } from '../../../../assets/layoutsSVG/Basic charts-37.svg';
import { ReactComponent as A28 } from '../../../../assets/layoutsSVG/Basic charts-38.svg';
import { ReactComponent as B28 } from '../../../../assets/layoutsSVG/Basic charts-39.svg';
import { ReactComponent as C28 } from '../../../../assets/layoutsSVG/Basic charts-40.svg';
import { ReactComponent as A32 } from '../../../../assets/layoutsSVG/Basic charts-41.svg';
import { ReactComponent as C32 } from '../../../../assets/layoutsSVG/Basic charts-42.svg';
import { ReactComponent as A33 } from '../../../../assets/layoutsSVG/Basic charts-43.svg';
import { ReactComponent as C33 } from '../../../../assets/layoutsSVG/Basic charts-45.svg';
import { ReactComponent as A34 } from '../../../../assets/layoutsSVG/Basic charts-46.svg';
import { ReactComponent as B34 } from '../../../../assets/layoutsSVG/Basic charts-47.svg';
import { ReactComponent as C34 } from '../../../../assets/layoutsSVG/Basic charts-48.svg';
import { ReactComponent as A35 } from '../../../../assets/layoutsSVG/Basic charts-49.svg';
import { ReactComponent as B35 } from '../../../../assets/layoutsSVG/Basic charts-50.svg';
import { ReactComponent as C35 } from '../../../../assets/layoutsSVG/Basic charts-51.svg';
import { ReactComponent as A36 } from '../../../../assets/layoutsSVG/Basic charts-52.svg';
import { ReactComponent as B36 } from '../../../../assets/layoutsSVG/Basic charts-53.svg';
import { ReactComponent as C36 } from '../../../../assets/layoutsSVG/Basic charts-54.svg';
import { ReactComponent as A37 } from '../../../../assets/layoutsSVG/Basic charts-55.svg';
import { ReactComponent as B37 } from '../../../../assets/layoutsSVG/Basic charts-56.svg';
import { ReactComponent as C37 } from '../../../../assets/layoutsSVG/Basic charts-57.svg';
import { ReactComponent as A38 } from '../../../../assets/layoutsSVG/Basic charts-58.svg';
import { ReactComponent as B38 } from '../../../../assets/layoutsSVG/Basic charts-59.svg';
import { ReactComponent as C38 } from '../../../../assets/layoutsSVG/Basic charts-60.svg';


export const TemplatesSVG = {
  "1_1_a": A11,
  "1_2_a": A12,
  "1_2_b": B12,
  "1_3_a": A13,
  "1_3_b": B13,
  "1_3_c": C13,
  "1_4_a": A14,
  "1_4_b": B14,
  "1_4_c": C14,
  "1_5_a": A15,
  "1_5_b": C15,
  "1_5_c": B15,
  "1_6_a": A16,
  "1_6_b": B16,//d
  "1_6_c": C18,//d
  "1_7_a": A17,
  "1_7_b": B17,
  "1_7_c": C17,
  "1_8_a": A18,
  "1_8_b": C16,
  "1_8_c": B18,
  "2_1_a": A24,
  "2_2_a": C23,//d
  "2_2_c": A32,//d
  "2_3_a": B23,//d
  "2_3_b": A23,
  "2_3_c": C28,//d
  "2_4_a": B24,//d
  "2_4_b": C26,//d
  "2_4_c": C32,//d
  "2_5_a": A25,
  "2_5_b": B25,
  "2_5_c": C25,
  "2_6_a": A26,
  "2_6_b": B26,
  "2_6_c": A21,
  "2_7_a": A27,
  "2_7_b": B27,
  "2_7_c": C27,
  "2_8_a": A28,
  "2_8_b": B28,
  "2_8_c": B22,
  "3_2_a": A35,//d
  "3_2_c": C24,
  "3_3_a": C34,//d
  "3_3_b": B23,//
  "3_3_c": C33,
  "3_4_a": A34 ,
  "3_4_b": C37,//d
  "3_4_c": A33,
  "3_5_a": C35,
  "3_5_b": B35,
  "3_5_c": C22,
  "3_6_a": A36,
  "3_6_b": B38,//d
  "3_6_c": C36,
  "3_7_a":A37,
  "3_7_b": B37,
  "3_7_c": B34,
  "3_8_a": B36,
  "3_8_b": C38,
  "3_8_c": A38,
};
