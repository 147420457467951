import styled from "styled-components";
import {
  autoWidth,
  buttonActiveColors,
  buttonColors,
  buttonHoverColors,
  buttonLine,
  textColor,
} from "../../helpers/stylesHelpers";

export const VerticalLine = styled.div<{
  $variant?:
  | "primary"
  | "secondary"
  | "neutral"
  | "neutral-color"
  | "disabled"
  | "danger"
  | "dangerInvert";
}>`
  border-left: 1px solid ${({ theme, $variant }) => buttonLine(theme, $variant)};
  height: 16px;
  position: relative;
  opacity: 0.3;
  transition: all 0.15s linear;
`;

export const CustomButton = styled.button<{
  $disabled?: boolean;
  $variant?:
  | "primary"
  | "secondary"
  | "neutral"
  | "neutral-color"
  | "disabled"
  | "danger"
  | "dangerInvert";
  $neutral?: boolean;
  $size?: "xxs" | "xs" | "small" | "medium" | "large" | "full";
  $width?: string;
  $round?: boolean;
}>`
  display: flex;
  align-items: center;
  place-self: center;
  border: none;
  font-size: ${({ $size }) => ($size === "xxs" ? "12px" : "14px")};
  font-weight: 500;
  height: ${({ $size }) =>
    $size === "full" ? "40px" : $size ? autoWidth($size) : "auto"};
  width: ${({ $round, $size }) =>
    $round ? autoWidth($size) : $size === "full" ? "100%" : "auto"};
  padding: ${({ $size }) =>
    $size === "xxs" ? "8px" : $size === "small" ? "10px" : "14px"};
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme, $variant }) => buttonColors(theme, $variant)};
  color: ${({ theme, $variant }) => textColor(theme, $variant)};
  opacity: ${({ $disabled }) => ($disabled ? "0.6" : "1")};
  transition: all 0.15s linear;

  &:hover {
    background-color: ${({ theme, $variant, $disabled }) =>
    $disabled ? "" : buttonHoverColors(theme, $variant)};
    color: ${({ theme, $variant, $disabled }) =>
    $disabled
      ? ""
      : $variant === "secondary" && theme.primaryTextStrongHover};

    ${VerticalLine} {
      ${({ $variant, $size, $disabled }) =>
    $disabled
      ? ""
      : $variant === "disabled"
        ? ``
        : `
        height: ${autoWidth($size)};
      `}
    }

    &:active {
      background-color: ${({ theme, $variant }) =>
    buttonActiveColors(theme, $variant)};
      color: ${({ theme, $variant }) =>
    $variant === "secondary" && theme.primaryTextStrongActive};

      svg path {
        fill: ${({ theme, $variant }) =>
    $variant === "secondary" && theme.primaryTextStrongActive};
      }
    }

    svg path {
      fill: ${({ theme, $variant, $disabled }) =>
    $disabled
      ? ""
      : $variant === "secondary" && theme.primaryTextStrongHover};
    }
  }

  ${({ theme, $variant }) =>
    $variant === "disabled" &&
    `
      svg path {
        fill: ${theme.neutralTextDisabled}
      }
    `};

  svg {
    height: 20px;
    width: 20px;

    ${({ theme, $variant }) =>
    $variant === "neutral" &&
    `
        fill: ${theme.neutralTextWeak};
    `};
  }
`;

export const ButtonContainer = styled.div<{ $full?: boolean }>`
  display: flex;
  gap: 20px;
  width: ${({ $full }) => ($full ? "100%" : "")};
`;

export const IconWrapper = styled.div<{ $small?: boolean }>`
  display: flex;
  gap: ${({ $small }) => ($small ? "6px" : "8px")};
  align-items: center;
`;
