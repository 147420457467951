import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Bar, Line, LinePath } from "@visx/shape";
import { scaleLinear, scaleTime } from "@visx/scale";
import { localPoint } from "@visx/event";
import { bisector } from "d3";
import useMeasure from "react-use-measure";

import {
  HeaderWrapper,
  Title,
} from "./styles";

import { setActiveModal } from "./../../../store/slices/modals";
import {
  getIsEditMode,
  getIsPublicMode,
} from "./../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "./../../../store/selectors/projects";
import { ticksFormatter } from "./../../../helpers/ticksFormatter";
import { calculateNumTicks, getAvailableWidgetTypes } from "./../widgetHelpers";
import { Tooltip, TooltipProps } from "./../Tooltip";
import { Loader } from "./../../Loader";
import { AiSuggestionsDto, WidgetItem } from "./../../../models/Widgets";
import { getAiSuggestions } from "./../../../store/selectors/widgets";
import { setCurrentWidget } from "./../../../store/slices/projectPages";
import { SelectBage } from "./../SelectBage";
import { replaceWords } from "./../../../helpers/replaceName";
import { getCurrentColorV2 } from "./../utils/getCurrentMarker";
import { DatavizRecommendedCount, DatavizSettingsIcon, HeadingNameAndButton, SettingsButtonWrapper } from "./../styles";
import { getActiveModal } from "./../../../store/selectors/modals";
import { getScaleTimeTickValues } from "../widgetHelpers";
import { ChartLegend, ChartLegendValue } from "./../../ChartLegend";
import { getGroupedData as getGroupedChartData } from "./utils/getGroupData";

interface SparkLineChartProps {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  currentWidget: WidgetItem;
  isRight?: boolean;
}
export const SparkLineChart = ({
  storytelling,
  currentWidget,
  recommended,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings,
  isRight,
}: SparkLineChartProps) => {
  const dispatch = useDispatch();

  const [ref, bounds] = useMeasure({ scroll: true });
  const [refWidget, boundsWidget] = useMeasure({ scroll: true });
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [targetX, setTargetX] = useState<number>(0);
  const [targetXDate, setTargetXDate] = useState<Date | null>(null);

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const margin = { top: 15, right: 1, bottom: 20, left: 40 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const chartSuggestion = aiSuggestions?.find(
    (chart: AiSuggestionsDto) => chart.chartType === "sparkLineChart"
  );

  const xAxe = currentWidget?.xAxe?.[0] ?? chartSuggestion?.xAxe?.[0];
  const yAxe = currentWidget?.yAxe?.[0] ?? chartSuggestion?.yAxe?.[0];

  //* ChartData
  const chartData = useMemo(() => {
    if (!currentWidget || !Object.keys(currentWidget).length) {
      return undefined;
    }

    return currentWidget?.data?.slice();
  }, [currentWidget]);

  const groupedChartData = useMemo(
    () => getGroupedChartData(currentWidget, chartSuggestion),
    [currentWidget, chartSuggestion]
  );

  const groupedChartDataKeys = useMemo(
    () => Object.keys(groupedChartData),
    [groupedChartData]
  );

  const groupedChartDataValues = useMemo(
    () => Object.values(groupedChartData),
    [groupedChartData]
  );

  const groupBy = currentWidget?.groupBy?.[0] || "";

  const chartGroupKeys = useMemo(() => {
    return groupBy && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupBy]
      : [];
  }, [groupBy, currentWidget?.uniqueValues]);

  const legendValues: ChartLegendValue[] = useMemo(
    () =>
      chartGroupKeys?.map((dataKey) => ({
        label: dataKey!,
        color: getCurrentColorV2(currentWidget, dataKey, styleId),
      })) || [],
    [chartGroupKeys, currentWidget, styleId]
  );

  const yAxes = useMemo(
    () => chartData?.map((d) => parseInt(d[yAxe])) || [],
    [chartData, yAxe]
  );

  const yAxisTickCount = useMemo(() => calculateNumTicks({ height }), [height]);

  const uniqueXAxeValues = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      Array.from(new Set(chartData?.map((d: any) => d[xAxe]))) ||
      []
    );
  }, [chartData, currentWidget?.uniqueValues, xAxe]);

  const uniqueSortedDates = useMemo(() => {
    return uniqueXAxeValues
      .map((d: string) => new Date(d))
      .sort((a: Date, b: Date) => a.getTime() - b.getTime());
  }, [uniqueXAxeValues]);

  const xAxisCalculatedNumTicks = calculateNumTicks({ width });
  const xAxisTickCount =
    xAxisCalculatedNumTicks <= uniqueSortedDates.length
      ? xAxisCalculatedNumTicks
      : uniqueSortedDates.length;

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  //* Scales
  const xScale = useMemo(() => {
    return scaleTime({
      domain: [
        uniqueSortedDates[0],
        uniqueSortedDates[uniqueSortedDates.length - 1],
      ],
      range: [margin.left, width - margin.right],
    });
  }, [uniqueSortedDates, margin.left, margin.right, width]);

  const yScale = useMemo(() => {
    return scaleLinear<number>({
      domain: [0, Math.max(...yAxes)],
      range: [height - margin.bottom, margin.top],
      nice: true,
    });
  }, [yAxes, height, margin.bottom, margin.top]);

  const xScaleTickValues: Date[] = getScaleTimeTickValues({
    tickCount: xAxisTickCount,
    scale: xScale,
    axisOrientation: "horizontal",
    width,
    margin,
  });

  //* Accessors
  const getX = useMemo(
    () => (value: any) => {
      return xScale(value);
    },
    [xScale]
  );
  const getY = useMemo(() => (value: any) => yScale(value), [yScale]);

  const findNearestDateIndex = useCallback(
    (dateList: Date[], targetDate: Date) => {
      if (!targetDate || !dateList.length) {
        return null;
      }

      const index = bisector((d: Date) => d).left(dateList, targetDate, 1);
      const d0Index = index - 1;
      const d1Index = index;
      const d0 = dateList[d0Index];
      const d1 = dateList[d1Index];

      let dateIndex = d0Index;
      if (d1 && getX(new Date(d1))) {
        dateIndex =
          getX(targetDate) - getX(new Date(d0)).valueOf() >
          getX(new Date(d1)).valueOf() - getX(targetDate)
            ? d1Index
            : d0Index;
      }

      return dateIndex;
    },
    [getX]
  );

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGRectElement>) => {
      if (currentWidget.tooltip && !recommended) {
        const { x } = localPoint(event) || { x: 0 };
        const { pageY, clientX, clientY } = event;

        const invertedXScaleValue = xScale.invert(x);
        const dataIndex = findNearestDateIndex(
          uniqueSortedDates,
          invertedXScaleValue
        );

        if (dataIndex !== null) {
          const newTargetXDate = uniqueSortedDates[dataIndex];
          setTargetXDate(newTargetXDate);
          setTargetX(getX(newTargetXDate));
        }

        let targetY = 0;

        const tooltipData =
          dataIndex !== null
            ? groupedChartDataKeys.reduce(
                (acc: { [key: string]: string }, key: string, i: number) => {
                  const groupData = groupedChartData[key];

                  const d = groupData[dataIndex];

                  if (!d) {
                    return acc;
                  }

                  if (key === "default") {
                    acc[yAxe] = String(d[yAxe]);
                  } else {
                    acc[key] = String(d[yAxe]);
                  }

                  if (i === 0) {
                    acc[xAxe] = d[xAxe];
                    targetY = getY(d[yAxe]);
                  }

                  return acc;
                },
                {}
              )
            : {};

        const rect = event.currentTarget.getBoundingClientRect();

        const coords = {
          pageX: targetX + rect.left - margin.left,
          pageY,
          clientX,
          clientY,
          targetX,
          targetY,
        };

        setTooltip({
          data: tooltipData,
          coords,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      groupedChartData,
      currentWidget?.tooltip,
      recommended,
      targetX,
    ]
  );

  const handleMouseLeave = useCallback(() => {
    setTooltip(null);
  }, []);

  if (!chartData) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  return (
    <>
      {!storytelling && (
        <HeaderWrapper ref={refWidget}>
          <HeadingNameAndButton>
            {!hideName && <Title>{name}</Title>}
            {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        </HeaderWrapper>
      )}

      {legendValues?.length > 1 &&
        groupBy &&
        showLegend &&
        currentWidget?.legend && (
          <ChartLegend
            chartWidth={isRight ? 500 : boundsWidget.width }
            legendType="unit"
            legendValues={legendValues}
            isRight={isRight}
          />
        )}

      <svg width="100%" height={"100%"} ref={ref}>
        {/* x-Grid */}
        {xScaleTickValues.map((value) => {
          return (
            <line
              key={`${value}`}
              x1={getX(value)}
              y1={margin.top}
              x2={getX(value)}
              y2={height - margin.bottom}
              stroke="#e0e0e0"
              strokeDasharray="1,2"
            />
          );
        })}

        {/* y-Grid */}
        {yScale.ticks().map((tick) => (
          <line
            key={`y-${tick}`}
            x1={margin.left}
            x2={width - margin.right}
            y1={getY(tick)}
            y2={getY(tick)}
            stroke="#e0e0e0"
            strokeDasharray="1,2"
          />
        ))}
        {yScale.ticks(yAxisTickCount).map((value) => (
          <line
            key={value}
            x1={margin.left}
            y1={getY(value)}
            x2={width - margin.right}
            y2={getY(value)}
            stroke="#ccc"
            strokeDasharray="1,2"
          />
        ))}
        <line
          x1={width - margin.right}
          y1={margin.top}
          x2={width - margin.right}
          y2={height - margin.bottom}
          stroke="#e0e0e0"
          strokeDasharray="1,2"
        />

        {/* x-Axis */}
        <AxisBottom
          top={height - margin.bottom}
          scale={xScale!}
          hideTicks
          tickValues={xScaleTickValues}
          tickFormat={(tick: any) => tick.toLocaleDateString("en-US") || ""}
          axisLineClassName="x-axis"
          tickLabelProps={(_, index, values) => {
            const isFirstTick = index === 0;
            const isLastTick = index === values.length - 1;
            const textAnchor =
              (isFirstTick && "start") || (isLastTick && "end") || "middle";
            return {
              fontSize: 11,
              fill: "#5F6877",
              textAnchor: textAnchor,
            };
          }}
        />

        {/* y-Axis */}
        <AxisLeft
          scale={yScale}
          left={margin.left}
          stroke="#ccc"
          strokeDasharray="1,2"
          tickLineProps={{
            stroke: "#939BA7",
          }}
          numTicks={yAxisTickCount}
          tickLabelProps={(_, index, values) => ({
            fontSize: 11,
            fill: "#5F6877",
            textAnchor: "end",
            dx: -4,
            dy: index === 0 ? 0 : index < values.length - 1 ? 4 : 7,
          })}
          tickFormat={(value: any) => {
            return ticksFormatter(value);
          }}
        />

        {/* DataViz */}
        {groupedChartDataValues.map((groupData: any, index: number) => {
          const key = groupedChartDataKeys?.[index];
          const color = getCurrentColorV2(currentWidget, key, styleId);

          const transformedData = groupData?.map((d: any) => ({
            x: getX(new Date(String(d[xAxe]))),
            y: getY(d[yAxe])!,
          }));

          return (
            <LinePath
              key={key}
              data={transformedData}
              x={(d: any) => d.x}
              y={(d: any) => d.y}
              opacity={1}
              stroke={color}
              strokeWidth={1.5}
              strokeLinecap="round"
            />
          );
        })}

        {currentWidget.tooltip && (
          <Bar
            x={margin.left}
            y={margin.top}
            width={width - margin.left - margin.right}
            height={height}
            fill="transparent"
            rx={14}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        )}

        {tooltip && (
          <Line
            from={{ x: targetX, y: margin.top }}
            to={{ x: targetX, y: height + margin.top }}
            stroke={getCurrentColorV2(currentWidget, "default", styleId)}
            strokeWidth={1}
            pointerEvents="none"
            strokeDasharray="5,2"
          />
        )}

        {tooltip &&
          groupedChartDataValues.map((groupData: any, index: number) => {
            const key = groupedChartDataKeys?.[index];
            const color = getCurrentColorV2(currentWidget, key, styleId);

            const d = groupData.find(
              (d: any) => new Date(d[xAxe]).valueOf() === targetXDate?.valueOf()
            );

            if (!d) {
              return null;
            }

            const targetY = getY(d[yAxe]);

            return (
              <circle
                key={key}
                cx={targetX}
                cy={targetY}
                r={4}
                fill={color}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            );
          })}
      </svg>

      {tooltip &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={!groupBy ? yAxe : ""}
            data={tooltip.data}
            coords={tooltip.coords}
          />,
          document.body
        )}
    </>
  );
};
