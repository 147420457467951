import React, { FC } from "react";
import * as s from "./styles";
import { WidgetItem } from "../../../../models/Widgets";
import { ReactComponent as Arrow } from "../../../../assets/mini-arrow-right.svg";
import { ReactComponent as Logo } from "../../../../assets/logo-16px.svg";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../../../../themes/light";

interface Props {
  data: WidgetItem[][];
}

const ITEMS_PER_PAGE = 7;

export const TableOfContents: FC<Props> = ({ data }) => {
  const transformToTableOfContents = data.map((page, pageIndex) =>
    page.map((chart, chartIndex) => [
      chart.name,
      `${pageIndex + 1}.${chartIndex + 1}`,
    ])
  );

  const chunkedPages = [];
  for (let i = 0; i < transformToTableOfContents.length; i += ITEMS_PER_PAGE) {
    chunkedPages.push(transformToTableOfContents.slice(i, i + ITEMS_PER_PAGE));
  }

  return (
    <div id="table-of-content">
      <ThemeProvider theme={lightTheme}>
        {chunkedPages.map((chunk, chunkIndex) => (
          <s.Container key={chunkIndex}>
            <div>
              <s.Title>Table of Contents</s.Title>
              {chunk.map((page, pageIndex) => (
                <s.PageSection key={pageIndex}>
                  <s.PageTitle>
                    Page {pageIndex + 1 + chunkIndex * ITEMS_PER_PAGE}
                  </s.PageTitle>
                  <s.ItemList>
                    {page.map((item, itemIndex) => (
                      <s.Item key={itemIndex}>
                        <s.TitleWrapper>
                          <Arrow />
                          <s.ItemTitle>{item[0]}</s.ItemTitle>
                        </s.TitleWrapper>
                        <s.ItemNumber>{item[1]}</s.ItemNumber>
                      </s.Item>
                    ))}
                  </s.ItemList>
                </s.PageSection>
              ))}
            </div>
            <s.Footer>
              <Logo />
            </s.Footer>
          </s.Container>
        ))}
      </ThemeProvider>
    </div>
  );
};
