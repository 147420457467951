import { ComparisonContent } from "./components/ComparisonContent";
import * as s from "./styles";
import { useDispatch } from "react-redux";
import { setSelectedMeasure } from "../../store/slices/comparison";
import { getMeasure, MeasuresType } from "../Modals/CreateComparison/utils";
import { useSelector } from "react-redux";

import {
  getCurrentProject,
  getPageSettings,
} from "../../store/selectors/projects";
import { Select } from "../Inputs/CustomSelect/Select";
import { IOption } from "../Inputs/CustomSelect/types";
import { useEffect, useMemo, useState } from "react";

export const ComparisonMain = () => {
  const projectData = useSelector(getCurrentProject);
  const currentPageSettings = useSelector(getPageSettings);
  const [allMeasures, setAllMeasures] = useState<{
    [key: string]: MeasuresType[];
  }>({});

  const [measure, population] = currentPageSettings.comparisonMeasure?.split(
    "="
  ) ?? ["default", "value"];

  const dispatch = useDispatch();

  const measureList = useMemo(() => {
    const sourceId = projectData.projectData.datasets?.at(0)?.sourceId;
    const measures = allMeasures && sourceId ? allMeasures[sourceId] : [];
    return getMeasure(measures, population);
  }, [allMeasures, population, projectData.projectData.datasets]);

  const handleSelectMeasure = (option: IOption) => {
    dispatch(setSelectedMeasure(option.value));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!process.env.REACT_APP_DATA_SET_URL) {
          return;
        }
        const response = await fetch(process.env.REACT_APP_DATA_SET_URL);
        if (!response.ok) {
          return;
        }
        const data = await response.json();
        setAllMeasures(data);
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <s.Container>
      <s.SelectContainer>
        <div className="select-measure">
          <Select
            label={projectData.projectData.population}
            withOutClose
            options={[
              {
                value: projectData.projectData.measure || "",
                option: projectData.projectData.measure || "",
              },
            ]}
            value={projectData.projectData.measure || ""}
            disabled
          />
        </div>
        <div className="select-measure">
          <Select
            label={population}
            withOutClose
            options={measureList.map((measure) => ({
              value: measure.id,
              option: measure.id,
            }))}
            defaultValue={measure}
            value={measure}
            onSelect={handleSelectMeasure}
          />
        </div>
      </s.SelectContainer>
      <ComparisonContent />
    </s.Container>
  );
};
