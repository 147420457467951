import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ThreeDots } from "react-bootstrap-icons";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
`

export const Tabs = styled.div`
  display: flex;
  gap: 8px;
`

export const Tab = styled(NavLink)<{$selected?: boolean}>`
  cursor: pointer;
  user-select: none;
  border-radius: 6px;
  display: flex;
  padding: 10px;
  gap: 8px;
  color: ${({theme}) => theme.neutralTextWeak};
  fill: ${({theme}) => theme.neutralTextWeak};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.16px;
  border: 1px solid transparent;

  svg {
    width: 16px;
    height: 16px;
  }

  ${({$selected, theme}) => $selected && `
    color: ${theme.neutralText};
    fill: ${theme.neutralText};
    border: 1px solid ${theme.neutralBorder};
  `}
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;  
`

export const SelectedUsers = styled.div`
  color: ${({theme}) => theme.neutralText};
  display: flex;
  align-items: center;
  gap: 12px;
  svg {
    cursor: pointer;
  }
`

export const SelectedText = styled.p`
  color: ${({theme}) => theme.neutralText};
  letter-spacing: -0.16px;
  line-height: 20px;
  font-weight: 600;
  font-size: 16px;
`

export const SelectedTeam = styled.div`
  color: ${({theme}) => theme.neutralText};
  display: flex;
  align-items: center;
  gap: 12px;
  svg {
    cursor: pointer;
  }
`

export const TeamName = styled.p`
  color: ${({theme}) => theme.neutralText};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.4px;
`

export const More = styled(ThreeDots)``

export const Chip = styled.div`
  padding: 3px 4px;
  background: ${({theme}) => theme.neutralBackground};
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: ${({theme}) => theme.neutralTextWeak};
  border: 1px solid ${({theme}) => theme.neutralBorder};
`
