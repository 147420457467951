import { useEffect, useMemo, useRef, useState } from "react";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import useMeasure from "react-use-measure";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import { getPageSettings } from "../../../../store/selectors/projects";
import { getAiSuggestions } from "../../../../store/selectors/widgets";
import { AiSuggestionsDto, WidgetItem } from "../../../../models/Widgets";
import {
  HeaderWrapper,
  HeadingNameAndButton,
  Title,
} from "../../VerticalBarchart/styles";
import { ChartLegend, ChartLegendValue } from "../../../ChartLegend";
import { setActiveModal } from "../../../../store/slices/modals";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleLinearTickValues,
} from "../../widgetHelpers";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import { Tooltip, TooltipProps } from "../../Tooltip";
import { Loader } from "../../../Loader";
import { setCurrentWidget } from "../../../../store/slices/projectPages";
import { SelectBage } from "../../SelectBage";
import { replaceWords } from "../../../../helpers/replaceName";
import { LollipopMarkersHorizontal } from "../../utils/getMarker";
import {
  get_data,
  get_xAxe,
  get_yAxe,
  getGroupedData,
} from "../utils/getLollipopChartMarkers";
import {
  getCurrentColorV2,
  getCurrentMarker,
} from "../../utils/getCurrentMarker";
import { TickLabel } from "../../components/LabelTooltip";
import { createPortal } from "react-dom";
import { getPosition } from "../utils/getLollipopAlign";
import { DatavizRecommendedCount, DatavizSettingsIcon, SettingsButtonWrapper } from "../../styles";

interface LollipopInterface {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  isRight?: boolean;
}

export const HorizontalLollipopChart = ({
  storytelling,
  recommended,
  showLegend = true,
  selected = false,
  currentWidget,
  hideName = false,
  isRight,
}: LollipopInterface) => {
  const isEditMode = useSelector(getIsEditMode);
  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();
  const dispatch = useDispatch();
  const divRef = useRef<HTMLDivElement | null>(null);
  const isPublicRoute = useSelector(getIsPublicMode);
  const { styleId } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);
  const [groupedData, setGroupedData] = useState<{
    [keey: string]: { x: number; y: number }[];
  }>({});
  const [xAxes, setXAxes] = useState<number[]>([]);
  const [yAxes, setYAxes] = useState<string[]>([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [refHeight, setRefHeight] = useState<number>();
  const [legendValues, setLegendValues] = useState<ChartLegendValue[]>([]);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);

  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const barWidth = 10;

  const heightCheck = useMemo(
    () => yAxes.length * ((Object.keys(groupedData).length + 2) * barWidth),
    [yAxes, groupedData]
  );

  const hasLongBarValue = useMemo(
    () => yAxes?.some((key) => key.length > 6),
    [yAxes]
  );

  const margin = {
    top: 0,
    right: 1,
    bottom: 30,
    left: hasLongBarValue ? 80 : 40,
  };

  useEffect(() => {
    setRefHeight(divRef?.current?.parentElement?.clientHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    divRef,
    divRef?.current?.parentElement?.clientHeight,
    window?.innerHeight,
  ]);

  const totalSpacePerGroup =
    heightCheck < refHeight! ? refHeight! : heightCheck;

  const countGroupValues = currentWidget?.formatting?.length ?? 1;

  const yScale = scaleBand({
    domain: yAxes,
    range: [
      margin.top,
      countGroupValues + yAxes.length > 6
        ? totalSpacePerGroup
        : height - margin.bottom,
    ],
  });

  const maxXAxe = Math.max(...xAxes);

  const roundedMaxXAxe = useMemo(
    () =>
      Math.ceil(
        maxXAxe / Math.pow(10, Number(Math.floor(Math.log10(maxXAxe))))
      ) * Math.pow(10, Number(Math.floor(Math.log10(maxXAxe)))),
    [maxXAxe]
  );

  const xScale = scaleLinear({
    domain: [0, roundedMaxXAxe],
    nice: true,
    range: [margin.left, width - margin.right],
  });

  const xTicks = xScale.ticks();

  const xScaleTickValues = useMemo(() => {
    const xScaleNumTicksCalculated = calculateNumTicks({ width });

    const xScaleNumTicks =
      xScaleNumTicksCalculated <= xTicks.length
        ? xScaleNumTicksCalculated
        : xTicks.length;

    return getScaleLinearTickValues({
      scale: xScale,
      tickCount: xScaleNumTicks,
    });
  }, [width, xScale, xTicks.length]);

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: ticksFormatter,
      }),
    [margin.left, margin.right, width, xScaleTickValues]
  );

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  useEffect(() => {
    const lollipopChartSuggestion = aiSuggestions?.find(
      (chart: AiSuggestionsDto) => chart.chartType === "lollipopChart"
    );

    if (currentWidget) {
      const xAxe = get_xAxe(currentWidget, lollipopChartSuggestion);
      setXAxe(xAxe);

      const yAxe = get_yAxe(currentWidget, lollipopChartSuggestion);
      setYAxe(yAxe);

      const data = get_data(currentWidget, lollipopChartSuggestion);

      const xAxes =
        data?.reduce((t: any, l: any) => {
          const y = parseFloat(l.y);
          if (typeof y === "number" && !isNaN(y)) {
            return [...t, y];
          }
          return t;
        }, []) || [];
      setXAxes(xAxes);

      const yAxes: string[] =
        currentWidget?.uniqueValues?.[xAxe] || data?.map((d: any) => String(d.x)) || [];
      setYAxes(yAxes);

      const groupedData: any = getGroupedData(
        currentWidget,
        lollipopChartSuggestion
      );
      setGroupedData(groupedData);

      const newLegendValues = [];

      const groupBy = currentWidget?.groupBy?.at(0);

      if (groupBy && groupBy.length) {
        const uniqueValuesKeys =
          (currentWidget?.uniqueValues &&
            Object.keys(currentWidget?.uniqueValues!)) ||
          [];
        const groupByKey =
          groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

        const chartGroupKeys =
          uniqueValuesKeys?.length && currentWidget?.uniqueValues
            ? currentWidget?.uniqueValues[groupByKey!]
            : Object.keys(groupedData);

        if (groupedData && chartGroupKeys?.length) {
          for (let i = 0; i < chartGroupKeys?.length; i++) {
            const dataKey = chartGroupKeys?.at(i);
            const color = getCurrentColorV2(currentWidget, dataKey, "default");
            newLegendValues.push({ label: dataKey!, color });
          }
        }
        setLegendValues(newLegendValues);
      }
    }
  }, [aiSuggestions, currentWidget, styleId]);

  if (Object.keys(groupedData).length === 0) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  const gKeys = Object.keys(groupedData);
  const gdata = [...gKeys, ...yAxes];

  return (
    <>
      <HeaderWrapper ref={refWidget} style={{ marginBottom: "10px" }}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <div />}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 &&
          currentWidget?.formatting?.length! > 1 &&
          showLegend &&
          currentWidget?.legend && (
            <ChartLegend
              chartWidth={boundsWidget.width}
              legendType="unit"
              legendValues={legendValues}
              isRight={isRight}
            />
          )}
      </HeaderWrapper>
      <div
        ref={divRef}
        style={
          gdata.length > 6
            ? {
                height:
                  (refHeight || 240) - (!!storytelling ? 0 : 40 * 2) || 160,
                minHeight: 50,
                overflowY: "auto",
              }
            : {
                height: `${
                  (refHeight || 180) - (!!storytelling ? 0 : 14 + 14 + 14)
                }px`,
              }
        }
      >
        <svg
          width="100%"
          height={gdata.length > 6 ? totalSpacePerGroup : "100%"}
          ref={ref}
        >
          <Group>
            <Group>
              {yAxes.map((value, index) => (
                <line
                  key={`${value}-${index}`}
                  x1={margin.left}
                  y1={yScale(String(value))!}
                  x2={width - margin.right}
                  y2={yScale(String(value))!}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
            </Group>
            <Group>
              {xScaleTickValues.map((value: number, index: number) => (
                <line
                  key={`${value}-${index}`}
                  x1={xScale(value)}
                  y1={margin.top}
                  x2={xScale(value)}
                  y2={
                    gdata.length > 6
                      ? totalSpacePerGroup
                      : height - margin.bottom
                  }
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              ))}
            </Group>
            <Group style={{ minHeight: 500 }}>
              {groupedData &&
                Object.keys(groupedData)?.map(
                  (key: string, barIndex: number) => {
                    const groupData = groupedData[key] as any;
                    const countGroupValues =
                      key === "default" ? 1 : gKeys?.length;

                    const yVal = getPosition(barIndex, countGroupValues);
                    return groupData?.map((d: any, index: number) => {
                      const color = getCurrentColorV2(
                        currentWidget,
                        key,
                        "default"
                      );

                      const barKey = `${d.x}-${index}-${barIndex}`;

                      return (
                        <g
                          key={barKey}
                          style={{ transition: "0.3s" }}
                          onMouseMove={(event: any) => {
                            if (
                              currentWidget.tooltip &&
                              !recommended
                            ) {
                              const { pageX, pageY, clientX, clientY } = event;
                              const coords = { pageX, pageY, clientX, clientY };

                              setHoveredElement(barKey);
                              setTooltip({
                                name: key !== "default" ? key : undefined,
                                data: {
                                  [xAxe as string]: d.x,
                                  [yAxe as string]: d.y,
                                },
                                coords,
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            setTooltip(null);
                            setHoveredElement(null);
                          }}
                          opacity={
                            hoveredElement
                              ? hoveredElement === barKey
                                ? 1
                                : 0.4
                              : 1
                          }
                        >
                          <line
                            x1={xScale(d.y)}
                            y1={yScale(String(d.x))! + yScale.bandwidth() / 2 + yVal}
                            x2={margin.left}
                            y2={yScale(String(d.x))! + yScale.bandwidth() / 2 + yVal}
                            stroke={hexToRGBA(color!, 0.5)}
                            strokeWidth={3}
                          />

                          {!!color &&
                            LollipopMarkersHorizontal({
                              markerType: getCurrentMarker(
                                currentWidget,
                                key,
                                "rhombus"
                              ),
                              yScale: yScale(String(d.x))! + yScale.bandwidth() / 2,
                              yVal: yVal,
                              yScaleBand: 0,
                              color: color,
                              xScale: xScale(d.y) + 1,
                            })}
                        </g>
                      );
                    });
                  }
                )}
            </Group>
            <AxisLeft
              left={margin.left}
              scale={yScale}
              top={margin.top}
              hideTicks
              hideAxisLine
              numTicks={gdata.length}
              tickFormat={(value: any) => {
                return value;
              }}
              tickLabelProps={(value) => ({
                dx: value.length >= 5 || xAxe !== "year" ? -68 : -40,
                fontSize: 11,
                width: 30,
                fill: "#5F6877",
                textAnchor: "start",
                dominantBaseline: "middle",
              })}
              tickComponent={(props) => (
                <TickLabel {...props} x={0} length={8} offsetX={-10} />
              )}
            />
            {!(gdata.length > 6) && (
              <AxisBottom
                scale={xScale}
                top={height - margin.bottom}
                hideTicks
                hideAxisLine
                tickFormat={(value: any) => {
                  return ticksFormatter(value);
                }}
                tickLabelProps={(_, index, values) => {
                  const isFirstTick = index === 0;
                  const isLastTick = index === values.length - 1;

                  const textAnchor =
                    (isFirstTick && "start") ||
                    (isLastTick && "end") ||
                    "middle";

                  return {
                    fill: "#5F6877",
                    fontSize: 11,
                    textAnchor: textAnchor,
                    dy: 2,
                  };
                }}
                tickValues={xScaleTickValues}
                tickComponent={(props: any) => (
                  <TickLabel
                    {...props}
                    length={xScaleTickLabelMaxLength}
                    offsetX={-10}
                  />
                )}
                axisLineClassName="barchartAxisLine"
              />
            )}
          </Group>
        </svg>
      </div>
      {gdata.length > 6 && (
        <svg height={"25"} width={"100%"}>
          <AxisBottom
            scale={xScale}
            top={1}
            hideTicks
            tickFormat={(value: any) => {
              return ticksFormatter(value);
            }}
            tickLabelProps={(_, index, values) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;
              const textAnchor =
                (isFirstTick && "start") || (isLastTick && "end") || "middle";

              return {
                fontSize: 10,
                fill: "#5F6877",
                textAnchor,
                dx: 0,
              };
            }}
            tickValues={xScaleTickValues}
            tickComponent={(props: any) => (
              <TickLabel
                {...props}
                length={xScaleTickLabelMaxLength}
                offsetX={-10}
              />
            )}
            axisLineClassName="barchartAxisLine"
          />
        </svg>
      )}
      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
            coords={tooltip.coords}
          />,
          document.body
        )}
    </>
  );
};
