export const lightTheme = {
  // Neutral
  neutralBackgroundBase: "#FFFFFF",
  neutralBackgroundMedium: "#EFF0F1",
  neutralBackgroundExtraDark: "#141C2C",
  neutralBackground: "#5F687714",
  neutralBackgroundHover: "#5F68771F",
  neutralBackgroundActive: "#5F687729",

  neutralText: "#000005",
  neutralTextWeak: "#5F6877",
  neutralTextDisabled: "#9FA4AD",

  neutralBorder: "#DFE1E4",
  neutralBorderMedium: "#9FA4AD",

  neutralScrim: "#0000051A",

  // Primary
  primaryAction: "#00143F",
  primaryActionHover: "#000631",

  primaryBackground: "#2D6E8D1F",
  primaryBackgroundMedium: "#2D6E8D33",
  primaryBackgroundActive: "#2D6E8D3D",
  primaryBackgroundStrong: "#2D6E8D",
  primaryBackgroundStrongHover: "#155676",
  primaryBackgroundStrongActive: "#003E5F",

  primaryText: "#155676",
  primaryTextHover: "#003E5F",
  primaryTextStrong: "#FFFFFF",
  primaryTextStrongHover: "#81A8BB",
  primaryTextStrongActive: "#578BA4",
  secondaryTextUser: "#0b101a",

  primaryBorder: "#2D6E8D",

  // Alert
  alertBackground: "#C536601F",
  alertBackgroundMedium: "#C5366033",
  alertBackgroundActive: "#C536603D",
  alertBackgroundStrong: "#C53660",
  alertBackgroundStrongHover: "#A81945",
  alertBackgroundStrongActive: "#7C001B",

  alertText: "#8F1A3D",
  alertTextHover: "#7C001B",

  alertBorder: "#C53660",

  // Warning
  warningBackground: "#B35F001F",
  warningBackgroundMedium: "#B35F0033",
  warningBackgroundActive: "#B35F003D",
  warningBackgroundStrong: "#B35F00",
  warningBackgroundStrongHover: "#913D00",
  warningBackgroundStrongActive: "#802C00",

  warningText: "#913D00",
  warningTextHover: "#802C00",

  warningBorder: "#B35F00",

  // Success
  successBackground: "#1386601F",
  successBackgroundMedium: "#13866033",
  successBackgroundActive: "#1386603D",
  successBackgroundStrong: "#138660",
  successBackgroundStrongHover: "#00623E",
  successBackgroundStrongActive: "#004A27",

  successText: "#00623E",
  successTextHover: "#004A27",
  successBorder: "#138660",
};
