import { IFormatting } from "../../../../models/Widgets";

export const getBarChartKeys = (currentWidget: any) => {
  const groupBy = currentWidget?.groupBy?.at(0);

  const data = currentWidget?.data;

  return [...new Set(data?.map((item: any) => item[groupBy!]))]?.filter(
    (r) => !!r
  ) as string[];
};

export const getBarChartFormatting = (currentWidget: any): IFormatting[] => {
  const groupBy = Boolean(currentWidget?.groupBy?.length);

  if (groupBy) {

    if(currentWidget?.formatting?.length) {
      return currentWidget?.formatting
    }

    const keys = getBarChartKeys(currentWidget);
    return keys.map((item, index) => ({ key: item, color: String(index + 1) }));
    
  } else {

    if(currentWidget?.formatting?.length === 1) {
      return currentWidget?.formatting
    }

    return [{ key: "default", color: "1" }];
  }

};
