import styled, { keyframes } from "styled-components";
import { X, ThreeDots } from "react-bootstrap-icons";

export const UploadProgressWrapper = styled.div`
  display: flex;
  /* height: 48px; */
  margin: 8px 20px;
  padding: 8px 11px 8px 36px;
  /* background-color: ${({ theme }) => theme.neutralBorder}; */
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  position: relative;
`;

export const Actions = styled(ThreeDots)`
  align-self: center;
  position: absolute;
  top: 8px;
  left: 11px;
  cursor: pointer;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  align-self: center;
  gap: 4px;
`;

export const CloseWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

export const CloseButton = styled(X)`
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const ProgressHeading = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const ProgressPercentages = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`;

export const LoadingBar = styled.div`
  height: 6px;
  width: 100%;
  margin-bottom: 4px;
  background-color: ${({ theme }) => theme.neutralBackground};
  border-radius: 8px;
`;

export const Progress = styled.div<{ $progress: number }>`
  height: 100%;
  width: ${({ $progress }) => ($progress ? `${$progress}%` : "0%")};
  background-color: ${({ theme }) => theme.primaryAction};
  border-radius: inherit;
  transition: width 0.3s ease-in-out;
`;

export const Procesing = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const CircleLoading = styled.div`
  border: 3px solid ${({ theme }) => theme.neutralBackground};
  border-left: 3px solid ${({ theme }) => theme.primaryAction};
  animation: ${rotate} 2s linear infinite;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
`;

export const ProcesingText = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
