import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirstPage } from "./components/FirstPage";
import { LastPage } from "./components/LastPage";
import { ReportContent } from "./components/ReportContent";
import { downloadPDF } from "../../helpers/generatePDF";
import {
  resetPDFGenerationTrigger,
  setPDFGenerationProgress,
} from "../../store/slices/pdfSlice";
import {
  documentName,
  pdfGenerationTrigger,
  pdfGenerationProgress,
  getIsOpenThumbnailsScroll,
} from "../../store/selectors/reportPDF";
import * as s from "./styled";
import { getPageBanner, getPageSettings } from "../../store/selectors/projects";
import MiniatureNavigation from "./Miniature";
import { useSyncedState } from "../../hooks/useSyncedState";
import { defaultHeader } from "../AddStorytellingSection";
import { IPageHeader } from "../../store/slices/pageContentGenerator";
import { ReportConfirm } from "../Modals/ReportConfirm";
import { setIsEditMode } from "../../store/slices/appConfig";
import { getIsEditMode } from "../../store/selectors/main";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../../themes/light";

interface Props {
  loading: boolean;
}

export const ReportMain: FC<Props> = ({ loading }) => {
  const [file, setFile] = useState();
  const [isRemoveCover, setRemoveCover] = useState(false);
  const header = useSelector(getPageBanner);
  const wasEditMode = useRef(false);

  const [contentItem, setContentItem] = useState<IPageHeader | undefined>(
    header || defaultHeader
  );
  const name = useSelector(documentName);
  const pdfTrigger = useSelector(pdfGenerationTrigger);
  const progress = useSelector(pdfGenerationProgress);
  const currentPage = useSelector(getPageSettings);
  const isOpenThumbnailsScroll = useSelector(getIsOpenThumbnailsScroll);
  const isEditMode = useSelector(getIsEditMode);
  const containerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isHidden, setIsHidden] = useSyncedState("isHiddenCommentIds", false);

  const dispatch = useDispatch();

  const updateLoader = useCallback(
    (progress: number) => {
      dispatch(setPDFGenerationProgress(progress));
    },
    [dispatch]
  );

  const handleGeneratePDF = useCallback(() => {
    if (containerRef.current) {
      downloadPDF(containerRef, name, updateLoader);
    }
  }, [name, updateLoader]);

  useEffect(() => {
    if (progress > 5) {
      setIsHidden(true);
    }
    if (progress === 100) {
      setIsHidden(false);
    }
  }, [progress, setIsHidden]);

  useEffect(() => {
    const handleEditMode = () => {
      if (isEditMode && progress > 5) {
        wasEditMode.current = true;
        dispatch(setIsEditMode(false));
      } else if (!isEditMode && wasEditMode.current && progress === 100) {
        dispatch(setIsEditMode(true));
        wasEditMode.current = false;
      }
    };
    handleEditMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, dispatch]);

  useEffect(() => {
    if (pdfTrigger) {
      handleGeneratePDF();
      dispatch(resetPDFGenerationTrigger());
    }
  }, [pdfTrigger, dispatch, handleGeneratePDF]);

  return (
    <s.Container $isCenter={!isOpenThumbnailsScroll}>
      <s.Wrapper>
        <s.ProgressBarContainer>
          <s.ProgressBar width={progress} />
        </s.ProgressBarContainer>
        <s.PageContainer
          className={progress > 0 ? "generating-pdf" : ""}
          ref={containerRef}
          id="page-container"
        >
          <ThemeProvider theme={lightTheme}>
            {currentPage.showHeader && (
              <FirstPage
                file={file}
                setFile={setFile}
                contentItem={contentItem}
                setContentItem={setContentItem}
              />
            )}
            <ReportContent />
            {currentPage.showHeader && (
              <LastPage file={file} contentItem={contentItem} />
            )}
          </ThemeProvider>
        </s.PageContainer>
      </s.Wrapper>
      <MiniatureNavigation containerRef={containerRef} loading={loading} />
      {isRemoveCover && <ReportConfirm onClose={() => setRemoveCover(false)} />}
    </s.Container>
  );
};
