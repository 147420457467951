import styled from "styled-components";
import { ReactComponent as AccessSvg } from "../../../assets/access.svg";
import { commonSeparatorStyle } from "../../../styles";
import {
  ChevronCompactDown,
  ChevronCompactRight,
  Link45deg,
  InfoCircleFill,
  SlashLg,
} from "react-bootstrap-icons";

export const DropDownButton = styled(ChevronCompactDown)`
  cursor: pointer;
`;
export const InfoIcon = styled(InfoCircleFill)`
  cursor: pointer;
  fill: #493ddd;
`;

export const ShareModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border-radius: 12px;
  width: 478px;
  position: absolute;
  right: 50%;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const ShareModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 24px 20px 0 20px;
  margin-bottom: 8px;
  /* &:before {
    bottom: 0;
    ${commonSeparatorStyle};
  } */
`;
export const ShareModalProjectName = styled.div`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.neutralText};
  font-weight: 600;
  font-size: 16px;
`;
export const Slash = styled(SlashLg)`
  display: block;
`;
export const Arrow = styled(ChevronCompactRight)`
  display: none;
`;

export const ShareModalCopyLinkButton = styled.div<{ $disabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.primaryText};
  cursor: ${({ $disabled }) => $disabled ? 'default' : 'pointer'};
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    ${({ $disabled }) => $disabled ? '' : `
      ${Arrow} {
        display: block;
      }
      
      ${Slash} {
        display: none;
      }
    `};
    color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.primaryTextHover};
  }
`;

export const CopyLinkImg = styled(Link45deg)``;

export const ShareModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px  20px  16px 20px;
  gap: 12px;
`;


export const ShareModalContentTitle = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
`;

export const ShareModalContentPagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const PublishedButton = styled.span`
  display: none;
  height: 0;
  visibility: hidden;
  align-items: center;
  text-align: center;
  gap: 1px;
  font-weight: 500;
  padding: 0px 6px;
  color: ${({ theme }) => theme.primaryTextStrong};
  font-size: 14px;
  line-height: 14px;
  border-radius: 20px;
  cursor: pointer;
`;
export const PublishedBadge = styled.span`
  display: flex;
  align-items: center;
  height: 28px;
  gap: 1px;
  font-weight: 500;
  padding: 0px 14px;
  color: ${({ theme }) => theme.primaryText};
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  cursor: pointer;
  background: ${({ theme }) => theme.primaryBackground};
`;

export const PublicPageButtonContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  span:nth-child(1){
    font-size: 16px;
    line-height: 16px;
    color: ${({ theme }) => theme.neutralText};
  }
  span:nth-child(2){
    font-size: 14px;
    line-height: 14px;
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const PublicPageButton = styled.div<{ $published: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .15s linear;
  padding: 6px 8px;
  border-radius:4px;
  ${PublishedButton}{
    display: ${($published) => $published ? 'flex' : 'none'};
  }
  
  &:hover{
    background: ${({ theme }) => theme.neutralBackgroundHover};
    ${PublishedBadge}{
      display: none;
    }
    ${PublishedButton}{
      pointer-events: all;
      display: flex;
      height: 24px;
      visibility: visible;
      background: ${({ theme, $published }) => $published ? theme.alertBackgroundStrong : theme.primaryAction};
    }
  }
`;

export const PeopleWithAccessWrapper = styled.div`
  padding-top: 20px;
`;

export const PeopleWithAccessHeading = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 12px;
`;

export const PeopleWithAccessList = styled.div`
  display: flex;
  flex-direction: column;
  height: 134px;
  overflow: auto;
`;
export const PeopleWithAccessListItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 6px 4px;
  cursor: pointer;
`;
export const DefaultUserIcon = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.neutralBackground};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DefaultUserInitials = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 12px;
  font-weight: 400;
`;

export const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const UserDetailsName = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 400;
`;
export const UserDetailsEmail = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  font-weight: 400;
`;
export const UserDetailsRole = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
  position: relative;
`;

export const GeneralAccessWrapper = styled.div`
  padding-top: 16px;
`;
export const GeneralAccessHeading = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 12px;
`;
export const GeneralAccessActionRow = styled.div`
  display: flex;
  gap: 8px;
  height: 44px;
  align-items: center;
`;
export const GeneralAccessIcon = styled(AccessSvg)``;
export const GeneralAccessTitle = styled.div`
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 400;
  flex: auto;
`;
export const GeneralAccessAttribute = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
`;

export const PublishFileWrapper = styled.div`
  display: flex;
  height: 70px;
  background-color: ${({ theme }) => theme.neutralBackground};
  border-radius: 8px;
  margin-top: 16px;
  padding: 12px;
  gap: 16px;
`;

export const PublishFileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PublishFileHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: 500;
`;

export const PublishFileDescription = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  font-weight: 400;
`;

export const UserRoleDropDownWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
`;
