import styled from "styled-components";

interface ContainerProps {
  $isFullProperty: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100dvh - 64px - 72px - 20px);
  min-height: ${({ $isFullProperty }) => ($isFullProperty ? "800px" : "auto")};
`;