import React, { useRef, useState, useEffect } from "react";
import { useOnClickOutside } from "usehooks-ts";

import {
  Buttons,
  CloseIcon,
  Container,
  ContentWrapper,
  DownIcon,
  DropDownMenuItem,
  DropDownMenuList,
  DropDownSelect,
  Label,
  SelectContainer,
  SelectedValue,
  VerticalLine,
} from "./style";

import { IOption } from "./types";

type Props = {
  options: IOption[];
  value: string;
  defaultValue?: string;
  withOutClose?: boolean;
  label?: string;
  onSelect?: (value: IOption) => void;
  disabled?: boolean;
};

export const Select: React.FC<Props> = ({
  options,
  value,
  defaultValue,
  withOutClose,
  label,
  onSelect,
  disabled,
}: Props) => {
  const getInitialValue = () => {
    const valueOption = options?.find((item) => item.value === value);
    const defaultOption = options?.find((item) => item.value === defaultValue);
    return valueOption || defaultOption;
  };

  const [selectedValue, setSelectedValue] = useState<IOption | undefined>(
    getInitialValue()
  );
  const [activeDropDown, setActiveDropDown] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setActiveDropDown(false));

  useEffect(() => {
    const newValue = options?.find((item) => item.value === value);
    if (newValue && newValue.value !== selectedValue?.value) {
      setSelectedValue(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  const handleSelectOption = (item: IOption) => {
    setSelectedValue(item);
    setActiveDropDown(false);
    if (onSelect) {
      onSelect(item);
    }
  };

  const toggleDropDown = (e: React.MouseEvent) => {
    if (disabled) return;
    if ((e.target as HTMLElement).closest("button")) {
      return;
    }
    setActiveDropDown((prev) => !prev);
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    const emptyValue = { option: "", value: "" };
    setSelectedValue(emptyValue);
    onSelect?.(emptyValue);
  };

  return (
    <Container ref={ref}>
      <SelectContainer
        $opened={!!activeDropDown}
        $hasLabel={!!label}
        $disabled={!!disabled}
        onClick={toggleDropDown}
      >
        <ContentWrapper>
          {label && <Label>{label}</Label>}
          <SelectedValue
            $hasLabel={!!label}
            $placeholder={!!selectedValue?.option}
          >
            {selectedValue?.option || "Select value"}
          </SelectedValue>
        </ContentWrapper>
        <Buttons>
          {!selectedValue && !withOutClose && (
            <CloseIcon onClick={handleClear} />
          )}
          <VerticalLine $opened={activeDropDown} />
          <DownIcon $close={activeDropDown} />
        </Buttons>
      </SelectContainer>

      {!!activeDropDown && (
        <DropDownSelect $width={ref?.current?.offsetWidth || undefined}>
          <DropDownMenuList>
            {options.map((item) => {
              const isSelected = selectedValue?.value === item.value;
              const isdisabled = item.disabled;

              return (
                <DropDownMenuItem
                  key={`${item.option}-${item.value}-${item.label}-${item.selected}-${item.disabled}`}
                  onClick={() =>
                    !isdisabled ? handleSelectOption(item) : null
                  }
                  $selected={isSelected}
                  $disabled={isdisabled}
                >
                  <span>{item.option}</span>
                  {item?.label ? <span>{item?.label}</span> : ""}
                </DropDownMenuItem>
              );
            })}
          </DropDownMenuList>
        </DropDownSelect>
      )}
    </Container>
  );
};
