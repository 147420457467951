import { useCallback, useEffect, useState } from "react";

import { WidgetItem } from "../../../models/Widgets";
import RadioButton from "../../Inputs/CustomRadio/RadioButton";
import { Select } from "../../Inputs/CustomSelect/Select";
import { IOption } from "../../Inputs/CustomSelect/types";
import { getAvailableWidgetTypes } from "../../Widgets/widgetHelpers";
import { selectOptions } from "../data/mock";

import {
  MapSettingLayerContent,
  MapSettingsLayerDirection,
  MapSettingsLayerHeader,
  MapSettingsLayersWrapper,
} from "../styles";

interface ChartTypeSelectProps {
  currentWidget: WidgetItem;
  widgetData: WidgetItem;
  setWidgetData: React.Dispatch<React.SetStateAction<WidgetItem>>;
  setSelectedChart: (chart: string) => void;
}

export const ChartTypeSelect = ({
  currentWidget,
  widgetData,
  setWidgetData,
  setSelectedChart,
}: ChartTypeSelectProps) => {
  const [availableWidgetTypes, setAvailableWidgetTypes] = useState<string[]>(
    []
  );
  const [chartTypeOptions, setChartTypeOptions] = useState<IOption[]>([]);
  const [chartOrientationOptions, setChartOrientationOptions] = useState<
    string[]
  >([]);
  const [newChartType, setNewChartType] = useState<string>(
    widgetData.chartType
  );
  const [newChartOrientation, setNewChartOrientation] = useState<
    string | undefined
  >(widgetData.orientation);

  const currentWidgetType = currentWidget?.chartType ?? "";

  const CHART_ORIENTATION_OPTIONS = ["horizontal", "vertical"];

  useEffect(() => {
    const availableWidgetTypes = getAvailableWidgetTypes(currentWidget) || [];
    setAvailableWidgetTypes(availableWidgetTypes);

    const currentWidgetOrientation = getChartOrientation(
      currentWidgetType,
      availableWidgetTypes
    );
    setChartOrientationOptions(currentWidgetOrientation);

    // Deep copy selectOptions array
    const chartTypeSelectOptions = [
      ...selectOptions.map((item) => ({ ...item })),
    ];

    const currentWidgetTypeOption = chartTypeSelectOptions.find(
      ({ value }) => value === currentWidgetType
    );
    if (currentWidgetTypeOption) {
      currentWidgetTypeOption.label = "Current";
    }

    const recommendedOptions = chartTypeSelectOptions.filter(
      ({ value }: IOption) => {
        if (value === currentWidgetType) {
          return false;
        }

        return availableWidgetTypes.some((item: string) => {
          const foundItem = item === "map_matrix" ? "mapChart" : item;

          return foundItem.startsWith(value);
        });
      }
    );

    const validChartTypeOptions = [
      currentWidgetTypeOption ? currentWidgetTypeOption : undefined,
      ...recommendedOptions,
    ].filter(Boolean) as IOption[];

    const disabledOptions = [];

    for (const option of chartTypeSelectOptions) {
      if (validChartTypeOptions.includes(option)) {
        continue;
      }

      disabledOptions.push({ ...option, disabled: true });
    }

    const newChartTypeOptions = [...validChartTypeOptions, ...disabledOptions];
    setChartTypeOptions(newChartTypeOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWidgetType]);

  useEffect(() => {
    if (!newChartType) {
      return;
    }

    const formattedChartTypeValue = formatChartTypeValue(newChartType);

    const newSelectedChart =
      chartOrientationOptions.length > 0 && newChartOrientation
        ? `${formattedChartTypeValue}_${newChartOrientation}`
        : formattedChartTypeValue;

    setSelectedChart(newSelectedChart);

    setWidgetData((prev) => ({
      ...prev,
      chartType: newChartType,
      orientation: newChartOrientation,
      // formatting: isFromPolarAreaChart ? [] : prev.formatting,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChartType, newChartOrientation, chartOrientationOptions.length]);

  const getChartOrientation = (
    chartType: string,
    availableWidgetTypes: string[]
  ): string[] => {
    const chartOrientation = availableWidgetTypes
      .filter((item) => item.startsWith(chartType))
      .map((item) => item.split("_")[1])
      .filter(
        (orientation) =>
          orientation && CHART_ORIENTATION_OPTIONS.includes(orientation)
      );

    return chartOrientation;
  };

  const formatChartTypeValue = (value: string) => {
    if (["matrix", "sankey"].includes(currentWidgetType)) {
      if (value === "mapChart") {
        return "map_matrix";
      }

      if (value === "matrix" && availableWidgetTypes.includes("matrix_map")) {
        return "matrix_map";
      }

      if (value === "sankey" && availableWidgetTypes.includes("sankey_map")) {
        return "sankey_map";
      }
    }

    if (currentWidgetType === "mapChart") {
      switch (value) {
        case "matrix":
          return "matrix_map";

        case "sankey":
          return "sankey_map";

        default:
          return value;
      }
    }

    return value;
  };

  const checkChartTypeAvailability = (value: string): boolean => {
    const formattedValue = formatChartTypeValue(value);

    const matchedChartType = [...availableWidgetTypes, currentWidgetType].find(
      (item) => item.startsWith(formattedValue)
    );

    return !!matchedChartType;
  };

  const handleSelectChartType = useCallback(
    ({ value }: IOption) => {
      if (
        !value ||
        value === widgetData.chartType ||
        !checkChartTypeAvailability(value)
      ) {
        return;
      }

      const newChartOrientationOptions = getChartOrientation(
        value,
        availableWidgetTypes
      );
      setChartOrientationOptions(newChartOrientationOptions);

      setNewChartType(value);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableWidgetTypes, setWidgetData, widgetData.chartType]
  );

  const handleSelectOrientation = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newOrientation = e.target.value;
      if (
        !chartOrientationOptions.includes(newOrientation) ||
        newOrientation === widgetData.orientation
      ) {
        return;
      }

      setNewChartOrientation(newOrientation);
    },
    [chartOrientationOptions, widgetData.orientation]
  );

  return (
    <MapSettingsLayersWrapper>
      <MapSettingsLayerHeader>Chart Type</MapSettingsLayerHeader>

      <MapSettingLayerContent>
        <Select
          withOutClose
          options={chartTypeOptions}
          value={widgetData.chartType}
          defaultValue={currentWidgetType}
          onSelect={handleSelectChartType}
        />

        {chartOrientationOptions.length > 0 && (
          <MapSettingsLayerDirection>
            {chartOrientationOptions.map((item) => (
              <RadioButton
                key={item}
                name="orientation"
                onChange={handleSelectOrientation}
                label={
                  item === "horizontal" ? "Horizontal bars" : "Vertical bars"
                }
                value={item}
                checked={
                  widgetData.orientation === item ||
                  chartOrientationOptions.length === 1
                }
              />
            ))}
          </MapSettingsLayerDirection>
        )}
      </MapSettingLayerContent>
    </MapSettingsLayersWrapper>
  );
};
