import styled from "styled-components";
import { GearWideConnected, XLg } from "react-bootstrap-icons";

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
`;

export const DatasetPreviewLoaderWrapper = styled.div`
  position: absolute;
  bottom: 50%;
  left: 50%;
`;

export const TableWrapper = styled.div<{$disableScroll: boolean}>`
  display: flex;
  flex-direction: column;
  overflow-x: ${({ $disableScroll }) => $disableScroll ? "none" : "auto"};
  overflow-y: ${({ $disableScroll }) => $disableScroll ? "none" : "auto"};
  height: calc(98vh - 80px);
  position: relative;
`;

export const ContainerHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const TableHeader = styled.div`
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.neutralBorder};
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  background-color: ${({ theme }) => theme.neutralBackgroundMedium};
  padding: 0 12px;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
`;

export const ModalHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  width: 100%;
`;

export const DataSetblock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 24px;
  width: calc(98% - 460px);
  overflow: hidden;
`;

export const CloseIcon = styled(XLg)`
  cursor: pointer;
  margin-left: 16px;
`;

export const TableBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  height: calc(98vh - 154px);
`;

export const ContainerBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98vh;
  border-right: 1px solid #d3dbe3;
  box-shadow: 3px 2px 4px 1px #141c2c0a;
  min-width: 450px;
  max-width: 480px;
`;

export const ModalHeading = styled.h2`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
  margin-left: 8px;
  display: flex;
`;

export const ContainerWrapper = styled.div`
  display: flex;
`;

export const TableRow = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  background: ${({ theme }) => theme.neutralBackground};
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HeadingText = styled.div`
  font-size: 14px;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Icon = styled.img``;

export const HeadingIcon = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
`;

export const TableHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralTextWeak};
  padding: 6px 8px 6px 8px;
  overflow: hidden;
  max-width: 170px;
`;

export const Table = styled.div`
  position: relative;
  display: flex;
  gap: 9px;
`;

export const SettingsIcon = styled(GearWideConnected)`
  display: none;
`;

export const TableColumn = styled.div<{
  $errorDetected?: boolean;
  $selected?: boolean;
}>`
  display: flex;
  width: 200px;
  flex-direction: column;
  gap: 4px;
  border: 1px dashed rgba(255, 255, 255, 0);
  border: 1px solid transparent;

  &:hover {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
  ${({ $selected, theme }) =>
    $selected
      ? `
      background: ${theme.primaryBackgroundMedium};
      border-radius: 8px;
      `
      : ``};

  padding: 5px;
  &:hover {
    ${SettingsIcon} {
      display: block;
      cursor: pointer;
    }
  }
  ${({ $errorDetected, theme }) =>
    $errorDetected &&
    `
    border: 1px dashed ${theme.alertBackgroundStrong} !important ;
    border-radius: 10px;
    align-self: flex-start;
    margin: 0 4px;
    
      ${SettingsIcon} {
        display: block;
        cursor: pointer;
      }
      
      &:hover {
        border: 1px solid ${theme.alertBackgroundStrong} !important ;
      }
  
    }
  `}
`;
