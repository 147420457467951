import { ReactComponent as Strongcategorical } from "../../../../assets/dimensionIcons/strong/categorical.svg";
import { ReactComponent as Strongdatetime } from "../../../../assets/dimensionIcons/strong/datetime.svg";
import { ReactComponent as Stronglatitude } from "../../../../assets/dimensionIcons/strong/latitude.svg";
import { ReactComponent as Stronglongitude } from "../../../../assets/dimensionIcons/strong/longitude.svg";
import { ReactComponent as Stronglocation } from "../../../../assets/dimensionIcons/strong/location.svg";
import { ReactComponent as Strongmeta } from "../../../../assets/dimensionIcons/strong/meta.svg";
import { ReactComponent as Strongmetric } from "../../../../assets/dimensionIcons/strong/metric.svg";
import { ReactComponent as Strongtext } from "../../../../assets/dimensionIcons/strong/text.svg";
import { FC, SVGProps } from "react";

export type Dimensions = {
  categorical: string;
  date: string;
  latitude: string;
  longitude: string;
  county: string;
  meta: string;
  metric: string;
  text: string;
};

type SvgComponent = FC<SVGProps<SVGSVGElement>>;

type IconMap = {
  [K in keyof Dimensions]: SvgComponent;
};

interface DimensionIconsProps {
  state: string;
}

const iconMap: IconMap = {
  categorical: Strongcategorical,
  date: Strongdatetime,
  latitude: Stronglatitude,
  longitude: Stronglongitude,
  county: Stronglocation,
  meta: Strongmeta,
  metric: Strongmetric,
  text: Strongtext
};

export const DimensionIcons: FC<DimensionIconsProps> = ({ state, ...props }) => {
  const Icon = iconMap[state as keyof Dimensions] || iconMap.categorical;
  return <Icon {...props} />;
};