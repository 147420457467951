import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  DraggableItemWrapper,
  DroppableGroupWrapper,
  Footer,
  PageNumber,
  PageWrapper,
} from "./styles";

import {
  addWidgets,
  PageContentGeneratorItemType,
} from "../../../../store/slices/pageContentGenerator";
import { getPageContentGeneratorData } from "../../../../store/selectors/contentGenerator";
import {
  getCurrentPageWidgets,
  getCurrentProjectId,
  getPageBanner,
  getPageId,
  getPageSettings,
} from "../../../../store/selectors/projects";
import {
  requestPageBanner,
  requestPageStories,
  requestUpdateWidget,
  requestUpdateWidgets,
} from "../../../../store/slices/widgets";
import { useDebounceCallback } from "usehooks-ts";
import {
  requestPageById,
  requestPageSections,
  requestPageWidgets,
  requestUpdatePageSections,
} from "../../../../store/slices/projectPages";
import {
  UpdateSingleWidgetDTO,
  UpdateWidgetDTO,
  WidgetItem,
} from "../../../../models/Widgets";
import { defaultNewWidget } from "../../../../pages/ProjectPage/constants";
import { IUpdateSection } from "../../../../api/sections";
import SectionTextImage from "../StoryTellingSection/components/SectionTextImage/SectionTextImage";
import SectionItem from "../StoryTellingSection/components/SectionItem/SectionItem";
import { TableOfContents } from "../TableOfContent";
import { ReactComponent as Logo } from "../../../../assets/logo-16px.svg";

import { getIsPublicMode } from "../../../../store/selectors/main";
import Comments from "../../../Comments/Comments";
import {
  setSelectWidgetId,
  toggleCommentsCursor,
} from "../../../../store/slices/commnets";
import { getCustomeCommentsCursor } from "../../../../store/selectors/comments";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useChartWidgetsReorder } from "../../hooks/useChartWigetsReorder";
import { setThumbnailForceRegenerate } from "../../../../store/slices/pdfSlice";
import { lightTheme } from "../../../../themes/light";
import { ThemeProvider } from "styled-components";

export const ReportContent: FC = () => {
  const dispatch = useDispatch();
  const pageWidgets = useSelector(getCurrentPageWidgets);
  const pageContentGenerator = useSelector(getPageContentGeneratorData);
  const currentPageId = useSelector(getPageId);
  const currentProjectId = useSelector(getCurrentProjectId);
  const currentPage = useSelector(getPageSettings);
  const header = useSelector(getPageBanner);
  const isPublic = useSelector(getIsPublicMode);
  const isCustomeCursor = useSelector(getCustomeCommentsCursor);

  const [firstLoad, setFirstLoad] = useState<string | undefined>();
  const [localItems, setLocalItems] = useState<WidgetItem[]>([]);
  const [sections, setSections] = useState<any[]>([]);
  const { items } = pageContentGenerator;

  const { handleDragEnd, groupItems } = useChartWidgetsReorder();

  const groupedItems = groupItems(localItems);

  const getAllSections = useCallback(() => {
    if (isPublic) {
      dispatch(
        requestPageSections({
          pageId: currentPage.id!,
          limit: 20,
          skip: 0,
          callbacks: {
            onSuccess: (data) => {
              setSections(data?.items || []);
            },
          },
        })
      );
    } else {
      dispatch(
        requestPageSections({
          pageId: currentPage.id!,
          limit: 20,
          skip: 0,
          callbacks: {
            onSuccess: (data) => {
              setSections(data?.items || []);
            },
          },
        })
      );
    }
  }, [currentPage.id, dispatch, isPublic]);

  const handleSave = (widget: UpdateSingleWidgetDTO) => {
    dispatch(
      requestUpdateWidget({
        ...widget,
        blockId: String(widget?.blockId),
        callbacks: {
          onSuccess: () => {
            dispatch(
              requestPageWidgets({
                pageId: currentPageId!,
                includeData: true,
              })
            );
          },
        },
      })
    );
  };

  const debouncedUpdateWidget = useDebounceCallback(handleSave, 500);

  useEffect(() => {
    getAllSections();
  }, [getAllSections]);

  const generateBanner = useCallback(() => {
    dispatch(
      requestPageBanner({
        pageId: currentPageId,
        projectId: currentProjectId,
        callbacks: {
          onSuccess: (resp) => {
            setTimeout(() => {
              dispatch(requestPageById({ id: currentPageId! }));
            }, 600);
          },
        },
      })
    );
  }, [currentPageId, currentProjectId, dispatch]);

  const checkUpdatedHeader = useCallback(() => {
    const haveDescriptions = pageWidgets?.items?.filter(
      (w) => !w.description || w.description === "Add here description"
    )?.length;
    const noHavePageDescription = !(header?.description && header?.title);
    if (
      pageWidgets?.count > 0 &&
      firstLoad !== currentPageId &&
      !haveDescriptions &&
      noHavePageDescription
    ) {
      setFirstLoad(currentPageId);
      generateBanner();
    }
  }, [
    header,
    currentPageId,
    firstLoad,
    pageWidgets?.count,
    pageWidgets?.items,
    generateBanner,
  ]);

  useEffect(() => {
    checkUpdatedHeader();
  }, [checkUpdatedHeader]);

  const handleEditPage = useCallback(
    (
      widgetDescriptions?: {
        story: string;
        widget_id: string;
        insights: string[];
      }[]
    ) => {
      let barChartRequestData: UpdateWidgetDTO[] = [];
      let lineChartRequestData: UpdateWidgetDTO[] = [];
      let sparkLineChartRequestData: UpdateWidgetDTO[] = [];
      let sparkAreaChartRequestData: UpdateWidgetDTO[] = [];
      let lollipopChartRequestData: UpdateWidgetDTO[] = [];
      let sankeyChartRequestData: UpdateWidgetDTO[] = [];
      let mapChartRequestData: UpdateWidgetDTO[] = [];
      let areaChartRequestData: UpdateWidgetDTO[] = [];
      let matrixChartRequestData: UpdateWidgetDTO[] = [];
      let scatterplotChartRequestData: UpdateWidgetDTO[] = [];
      let radarChartRequestData: UpdateWidgetDTO[] = [];
      let punchcardChartRequestData: UpdateWidgetDTO[] = [];
      let polarAreaChartRequestData: UpdateWidgetDTO[] = [];
      let bubbleChartRequestData: UpdateWidgetDTO[] = [];
      let radialBarChartRequestData: UpdateWidgetDTO[] = [];

      if (widgetDescriptions?.length) {
        for (let chart of pageWidgets?.items) {
          const descriptionData = widgetDescriptions?.find(
            (w) => w.widget_id === chart.id
          );
          let descriptionChartData = {
            ...defaultNewWidget,
            description: chart?.description || defaultNewWidget.description,
            insights: chart?.insights || defaultNewWidget.insights,
          };
          if (descriptionData) {
            descriptionChartData = {
              ...descriptionChartData,
              description:
                descriptionData.story || descriptionChartData.description,
              insights:
                descriptionData.insights?.join(" ") ||
                descriptionChartData.insights,
            };
          }

          switch (chart?.chartType) {
            case "mapChart":
              mapChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "areaChart":
              areaChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "lineChart":
              lineChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "sparkLineChart":
              sparkLineChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "sparkAreaChart":
              sparkAreaChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "barChart":
              barChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "lollipopChart":
              lollipopChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "sankey":
              sankeyChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "matrix":
              matrixChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "polarAreaChart":
              polarAreaChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "punchcardChart":
              punchcardChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "radar":
              radarChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "scatterplot":
              scatterplotChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "bubbleChart":
              bubbleChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "radialBarChart":
              radialBarChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            default:
          }
        }
      }
      dispatch(setThumbnailForceRegenerate());

      const existCharts =
        barChartRequestData?.length +
        lineChartRequestData?.length +
        sparkLineChartRequestData?.length +
        sparkAreaChartRequestData?.length +
        lollipopChartRequestData?.length +
        sankeyChartRequestData?.length +
        areaChartRequestData?.length +
        matrixChartRequestData?.length +
        radarChartRequestData?.length +
        scatterplotChartRequestData?.length +
        polarAreaChartRequestData?.length +
        punchcardChartRequestData?.length +
        radialBarChartRequestData?.length +
        bubbleChartRequestData?.length;

      if (existCharts > 0) {
        dispatch(
          requestUpdateWidgets({
            barChart: barChartRequestData,
            lineChart: lineChartRequestData,
            sparkLineChart: sparkLineChartRequestData,
            sparkAreaChart: sparkAreaChartRequestData,
            lollipopChart: lollipopChartRequestData,
            sankeyChart: sankeyChartRequestData,
            areaChart: areaChartRequestData,
            matrixChart: matrixChartRequestData,
            mapChart: mapChartRequestData,
            scatterplotChart: scatterplotChartRequestData,
            polarAreaChart: polarAreaChartRequestData,
            punchcardChart: punchcardChartRequestData,
            radarChart: radarChartRequestData,
            bubbleChart: bubbleChartRequestData,
            radialBarChart: radialBarChartRequestData,
            pageId: currentPageId!,
          })
        );
      }
      const noHavePageDescription = !(header?.description && header?.title);
      if (noHavePageDescription) {
        setTimeout(() => {
          generateBanner();
        }, 600);
      }
    },
    [currentPageId, header, pageWidgets?.items, dispatch, generateBanner]
  );

  const checkCreateDescriptions = useCallback(() => {
    const noHaveDescriptions = pageWidgets?.items?.some(
      (w) => !w.description || w.description === "Add here description"
    );

    if (!noHaveDescriptions) {
      return;
    }

    if (pageWidgets?.count > 0 && firstLoad !== currentPageId) {
      setFirstLoad(currentPageId);
    }

    dispatch(
      requestPageStories({
        pageId: currentPageId,
        projectId: currentProjectId,
        callbacks: {
          onSuccess: (resp) => {
            handleEditPage(resp?.widget_descriptions);
            if (
              pageWidgets?.items?.length !== resp?.widget_descriptions?.length
            ) {
              setTimeout(() => {
                checkCreateDescriptions();
              }, 500);
            }
          },
        },
      })
    );
  }, [
    currentPageId,
    currentProjectId,
    firstLoad,
    pageWidgets?.count,
    pageWidgets?.items,
    dispatch,
    handleEditPage,
  ]);

  useEffect(() => {
    checkCreateDescriptions();
  }, [checkCreateDescriptions]);

  useEffect(() => {
    if (pageWidgets.items) {
      const widgets = pageWidgets?.items
        ?.map((el, i): PageContentGeneratorItemType => {
          return {
            ...el,
            blockId: (Number(el.blockId) || 1) as any,
            sectionType: el.chartType === "mapChart" ? "map" : "widget",
          };
        })
        .sort(
          (a, b) =>
            //@ts-ignore
            a?.blockId - b?.blockId
        );

      dispatch(addWidgets(widgets));
    }
  }, [pageWidgets, dispatch]);

  const updateSection = (section: IUpdateSection) => {
    dispatch(
      requestUpdatePageSections({
        ...section,
        callbacks: {
          onSuccess: () => {
            getAllSections();
          },
        },
      })
    );
  };

  const combineItems = useCallback(() => {
    const newItems = [...items, ...sections];

    const sortedItems = newItems.sort(
      (a, b) => parseInt(a.blockId) - parseInt(b.blockId)
    );
    setLocalItems(sortedItems);
  }, [items, sections, setLocalItems]);

  useEffect(() => {
    combineItems();
  }, [items, sections, combineItems]);

  const handleSlelectWidget = (id: string) => {
    if (isCustomeCursor) {
      dispatch(setSelectWidgetId(id));
      dispatch(toggleCommentsCursor(false));
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      {currentPage.tableOfContents && <TableOfContents data={groupedItems} />}
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          if (!destination) return;
          const sourceIndex =
            source.droppableId === String(Math.floor(source.index / 2))
              ? source.index
              : parseInt(source.droppableId) * 2 + source.index;

          const destinationIndex =
            destination.droppableId ===
              String(Math.floor(destination.index / 2))
              ? destination.index
              : parseInt(destination.droppableId) * 2 + destination.index;

          handleDragEnd(localItems, sourceIndex, destinationIndex);
        }}
      >
        <PageWrapper id="content">
          {groupedItems.map((group, groupIndex) => (
            <Droppable
              droppableId={String(groupIndex)}
              key={`group_${groupIndex}`}
              direction="vertical"
            >
              {(provided, snapshot) => (
                <DroppableGroupWrapper
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  $isDraggingOver={snapshot.isDraggingOver}
                >
                  {group.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <DraggableItemWrapper
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          $isDragging={snapshot.isDragging}
                          onClick={() => handleSlelectWidget(item.id)}
                        >
                          <Comments isReports isStorytelling id={item?.id} />
                          {item?.chartType ? (
                            <SectionItem
                              key={`section_${item.id}_${index}`}
                              setContentItem={debouncedUpdateWidget}
                              item={item}
                              draggable={snapshot.isDragging}
                            />
                          ) : (
                            <SectionTextImage
                              contentItem={item}
                              key={`text_image_${item.id}_${index}`}
                              setContentItem={updateSection}
                            />
                          )}
                        </DraggableItemWrapper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <Footer>
                    <Logo />
                    <PageNumber>{groupIndex + 1}.</PageNumber>
                  </Footer>
                </DroppableGroupWrapper>
              )}
            </Droppable>
          ))}
        </PageWrapper>
      </DragDropContext>
    </ThemeProvider>
  );
};
