import { useEffect, useState, RefObject, useCallback } from 'react';

interface ViewportPosition {
  maxHeight: number;
  horizontalPosition: 'left' | 'right';
  horizontalOffset: number;
}

export const useViewportAdjustment = (
  wrapperRef: RefObject<HTMLElement>,
) => {
  const [position, setPosition] = useState<ViewportPosition>({
    maxHeight: 312,
    horizontalPosition: 'right',
    horizontalOffset: -200
  });

  const calculatePosition = useCallback(() => {
    if (!wrapperRef.current) return;

    const WINDOW_WIDTH = 360;
    const PADDING = 20;
    const MIN_HEIGHT = 200;
    const MAX_HEIGHT = 600;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const elementRect = wrapperRef.current.getBoundingClientRect();

    const spaceToRight = viewportWidth - elementRect.right;
    const spaceToLeft = elementRect.left;
    
    let horizontalPosition: 'left' | 'right';
    let horizontalOffset: number;

    if (spaceToRight >= WINDOW_WIDTH + PADDING) {
      horizontalPosition = 'right';
      horizontalOffset = -(WINDOW_WIDTH + PADDING);
    } else if (spaceToLeft >= WINDOW_WIDTH + PADDING) {
      horizontalPosition = 'left';
      horizontalOffset = -(WINDOW_WIDTH + PADDING);
    } else {
      if (spaceToRight > spaceToLeft) {
        horizontalPosition = 'right';
        const maxRightOffset = -(viewportWidth - elementRect.right - PADDING);
        horizontalOffset = Math.max(maxRightOffset, -(WINDOW_WIDTH + PADDING));
      } else {
        horizontalPosition = 'left';
        const maxLeftOffset = PADDING - elementRect.left;
        horizontalOffset = Math.min(maxLeftOffset, -(WINDOW_WIDTH + PADDING));
      }
    }

    if (horizontalPosition === 'right') {
      const projectedRightEdge = elementRect.right + horizontalOffset + WINDOW_WIDTH;
      if (projectedRightEdge > viewportWidth - PADDING) {
        horizontalOffset = -(WINDOW_WIDTH + PADDING);
      }
      
      const projectedLeftEdge = elementRect.right + horizontalOffset;
      if (projectedLeftEdge < elementRect.left + PADDING) {
        horizontalOffset = elementRect.left - elementRect.right + PADDING;
      }
    }

    const topSpace = elementRect.top;
    const availableHeight = viewportHeight - topSpace - PADDING * 2;
    const maxHeight = Math.min(
      Math.max(MIN_HEIGHT, availableHeight),
      MAX_HEIGHT
    );

    setPosition({
      maxHeight,
      horizontalPosition,
      horizontalOffset
    });
  }, [wrapperRef]);

  useEffect(() => {
    calculatePosition();

    const handleResize = () => {
      requestAnimationFrame(calculatePosition);
    };

    const handleScroll = (event: Event) => {
      const target = event.target as Node;
      if (wrapperRef.current?.contains(target) || target.contains(wrapperRef.current!)) {
        requestAnimationFrame(calculatePosition);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('scroll', handleScroll, true);

    if (wrapperRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        requestAnimationFrame(calculatePosition);
      });
      resizeObserver.observe(wrapperRef.current);

      let scrollParent = wrapperRef.current.parentElement;
      while (scrollParent) {
        const { overflow } = window.getComputedStyle(scrollParent);
        if (overflow === 'auto' || overflow === 'scroll') {
          resizeObserver.observe(scrollParent);
          break;
        }
        scrollParent = scrollParent.parentElement;
      }

      return () => {
        resizeObserver.disconnect();
        window.removeEventListener('resize', handleResize);
        document.removeEventListener('scroll', handleScroll, true);
      };
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [calculatePosition, wrapperRef]);

  const recalculate = useCallback(() => {
    requestAnimationFrame(calculatePosition);
  }, [calculatePosition]);

  return {
    ...position,
    recalculate
  };
};