import React from "react";
import * as s from "../../styles";
import { SearchItem } from "../../types";
import { SearchOption } from "../SearchOption/SearchOption";

interface DefaultViewProps {
  currentItems: SearchItem[];
  navigationStack: SearchItem[];
  selections: any;
  handleSelectAll: (items: SearchItem[]) => void;
  handleOptionClick: (item: SearchItem) => void;
  handleItemSelect: (item: SearchItem) => void;
}

export const DefaultView: React.FC<DefaultViewProps> = ({
  currentItems,
  navigationStack,
  selections,
  handleSelectAll,
  handleOptionClick,
  handleItemSelect,
}) => {
  const currentCategory =
    navigationStack[navigationStack.length - 1]?.name || "";
  const allSelected =
    currentItems.length > 0 &&
    currentItems.every(
      (item) => selections[currentCategory]?.[item.id]?.selected
    );

  return (
    <s.OptionsList $hasSubmit={!!navigationStack.length}>
      <s.NavigationHeader>
        <span>
          {navigationStack.length
            ? `Search for specific ${
                navigationStack[navigationStack.length - 1].name
              }`
            : "Suggested"}
        </span>
      </s.NavigationHeader>
      {!!navigationStack.length && (
        <>
          <SearchOption
            key="select-all"
            item={{
              id: "select-all",
              name: "All",
              count: currentItems.length,
            }}
            isSelected={allSelected}
            hasItemCount={false}
            onItemSelect={() => handleSelectAll(currentItems)}
          />
          <s.Divider />
        </>
      )}
      {currentItems.map((item) => (
        <SearchOption
          key={item.id}
          item={item}
          isSelected={Boolean(
            selections[navigationStack[0]?.name]?.[item.id]?.selected
          )}
          onOptionClick={handleOptionClick}
          onItemSelect={handleItemSelect}
          hasItemCount
        />
      ))}
    </s.OptionsList>
  );
};
