import styled from "styled-components";
import { X, ChevronDown } from "react-bootstrap-icons";

export const Container = styled.div<{ $width?: string }>`
  width: ${({ $width }) => $width || "100%"};
  position: relative;
`;

export const DownIcon = styled(ChevronDown)<{ $close?: boolean }>`
  transition: 0.2s;
  transform: rotateX(${({ $close }) => ($close ? "180deg" : "0deg")});
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
`;

export const SelectContainer = styled.div<{
  $opened?: boolean;
  $hasLabel?: boolean;
  $disabled: boolean;
}>`
  transition: 0.15s all linear;
  width: 100%;
  height: 40px;
  border: 1px solid
    ${({ $opened, theme }) =>
      $opened ? theme.primaryBorder : theme.neutralBorder};
  border-radius: 6px;
  user-select: none;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ $disabled, theme }) =>
    $disabled ? theme.neutralBackgroundMedium : "inherit"};
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.neutralTextDisabled : theme.neutralText};

  span {
    color: ${({ $disabled, theme }) =>
      $disabled ? theme.neutralTextDisabled : theme.neutralTextWeak};
  }

  ${DownIcon} {
    color: ${({ $disabled, theme }) =>
      $disabled ? theme.neutralTextDisabled : theme.neutralTextWeak};
  }

  &:hover {
    ${DownIcon} {
      color: ${({ theme, $opened, $disabled }) =>
        $disabled
          ? theme.neutralTextDisabled
          : $opened
          ? theme.neutralTextWeak
          : theme.neutralText};
    }
  }
`;

export const SelectedValue = styled.p<{
  $placeholder?: boolean;
  $hasLabel?: boolean;
}>`
  color: ${({ $placeholder, theme }) =>
    !$placeholder ? theme.neutralTextWeak : theme.neutralText};
  font-size: 14px;
  width: 100%;
  padding: ${({ $hasLabel }) => ($hasLabel ? "0px 7px 7px 7px" : "7px")};
  gap: 4px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseIcon = styled(X)`
  padding-right: 8px;
  width: 27px;
  height: 20px;
`;

export const VerticalLine = styled.div<{ $opened?: boolean }>`
  display: flex;
  width: 1px;
  background: ${({ theme }) => theme.neutralBorder};
  transition: 0.15s all linear;
  height: ${({ $opened }) => ($opened ? "38px" : "16px")};
`;

export const DropDownSelect = styled.div<{ $width?: number }>`
  position: fixed;
  margin-top: 4px;
  padding: 8px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  width: ${({ $width }) => `${$width}px` || "100%"};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  z-index: 1003;
  max-height: 400px;
  overflow: auto;
`;

export const DropDownMenuList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const DropDownMenuItem = styled.li<{
  $disabled?: boolean;
  $selected?: boolean;
}>`
  padding: 8px;
  transition: 0.2s;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  column-gap: 4px;
  row-gap: 2px;
  background: ${({ $selected, theme }) => $selected && theme.neutralBackground};
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};

  &:hover {
    background: ${({ $disabled, $selected, theme }) =>
      $selected
        ? theme.neutralBackgroundHover
        : !$disabled && theme.neutralBackground};
  }

  &:active {
    background: ${({ theme }) => theme.neutralBackgroundActive};
  }

  span:nth-child(1) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: ${({ $disabled, theme }) =>
      $disabled ? theme.neutralTextDisabled : theme.neutralText};
  }

  span:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-top: 5px;
  margin-left: 7px;
`;
