import { styled } from "styled-components";

const svgCursor = ({theme}: any) => `data:image/svg+xml;base64,${btoa(`
<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dd_1397_226608)">
<mask id="path-1-inside-1_1397_226608" fill="${theme.neutralBackgroundBase}">
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.9398 32H19.8096V19.8096L19.8097 19.8096C19.8097 13.075 25.2676 7.61719 32.0022 7.61719C38.7368 7.61719 44.1946 13.075 44.1946 19.8096C44.1946 26.5442 38.7368 32.002 32.0022 32.002C31.9814 32.002 31.9606 32.002 31.9398 32Z"/>
</mask>
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.9398 32H19.8096V19.8096L19.8097 19.8096C19.8097 13.075 25.2676 7.61719 32.0022 7.61719C38.7368 7.61719 44.1946 13.075 44.1946 19.8096C44.1946 26.5442 38.7368 32.002 32.0022 32.002C31.9814 32.002 31.9606 32.002 31.9398 32Z" fill="${theme.neutralBackgroundBase}"/>
<path d="M31.9398 32L31.9436 31.2382H31.9398V32ZM19.8096 32H19.0478V32.762H19.8096V32ZM19.8096 19.8096L20.3826 19.2365L19.0478 17.9016V19.8096H19.8096ZM19.8097 19.8096L19.2366 20.3826L20.5716 21.7176L20.5716 19.8096L19.8097 19.8096ZM31.9398 31.2382H19.8096V32.762H31.9398V31.2382ZM20.5714 32V19.8096H19.0478V32H20.5714ZM19.2366 20.3826L19.2366 20.3826L20.3826 19.2365L20.3826 19.2365L19.2366 20.3826ZM20.5716 19.8096C20.5716 13.4995 25.6922 8.37905 32.0022 8.37905V6.85533C24.843 6.85533 19.0479 13.4504 19.0478 19.8096L20.5716 19.8096ZM32.0022 8.37905C38.3124 8.37905 43.4328 13.4995 43.4328 19.8096H44.9564C44.9564 12.6504 39.1613 6.85533 32.0022 6.85533V8.37905ZM43.4328 19.8096C43.4328 26.1196 38.3124 31.2401 32.0022 31.2401V32.7638C39.1613 32.7638 44.9564 26.9688 44.9564 19.8096H43.4328ZM32.0022 31.2401C31.9828 31.2401 31.9634 31.2401 31.9436 31.2382L31.936 32.7618C31.9578 32.762 31.9798 32.7638 32.0022 32.7638V31.2401Z" fill="#DFE1E4" mask="url(#path-1-inside-1_1397_226608)"/>
<g filter="url(#filter1_dd_1397_226608)">
<circle cx="32" cy="19.8096" r="9.14282" fill="#155676"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32 15.2383C32.2104 15.2383 32.381 15.4089 32.381 15.6191V19.4286H36.1904C36.4008 19.4286 36.5714 19.5992 36.5714 19.8094C36.5714 20.0196 36.4008 20.1902 36.1904 20.1902H32.381V23.9998C32.381 24.21 32.2104 24.3806 32 24.3806C31.7898 24.3806 31.6192 24.21 31.6192 23.9998V20.1902H27.8096C27.5994 20.1902 27.4288 20.0196 27.4288 19.8094C27.4288 19.5992 27.5994 19.4286 27.8096 19.4286H31.6192V15.6191C31.6192 15.4089 31.7898 15.2383 32 15.2383Z" fill="${theme.neutralBackgroundBase}"/>
</g>
</g>
<defs>
<filter id="filter0_dd_1397_226608" x="0" y="0" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3.04762"/>
<feGaussianBlur stdDeviation="3.04762"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.109804 0 0 0 0 0.172549 0 0 0 0.12 0"/>
<feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow_1397_226608"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="4.57142" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_1397_226608"/>
<feOffset dy="12.1905"/>
<feGaussianBlur stdDeviation="7.61904"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.109804 0 0 0 0 0.172549 0 0 0 0.12 0"/>
<feBlend mode="multiply" in2="effect1_dropShadow_1397_226608" result="effect2_dropShadow_1397_226608"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1397_226608" result="shape"/>
</filter>
<filter id="filter1_dd_1397_226608" x="15.2383" y="6.09522" width="33.5238" height="33.5238" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1.52381"/>
<feGaussianBlur stdDeviation="1.52381"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.109804 0 0 0 0 0.172549 0 0 0 0.06 0"/>
<feBlend mode="darken" in2="BackgroundImageFix" result="effect1_dropShadow_1397_226608"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="1.52381" operator="dilate" in="SourceAlpha" result="effect2_dropShadow_1397_226608"/>
<feOffset dy="3.04762"/>
<feGaussianBlur stdDeviation="3.04762"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.109804 0 0 0 0 0.172549 0 0 0 0.06 0"/>
<feBlend mode="multiply" in2="effect1_dropShadow_1397_226608" result="effect2_dropShadow_1397_226608"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1397_226608" result="shape"/>
</filter>
</defs>
</svg>
  `)}`;

export const Container = styled.div<{ $isCustomCursor: boolean }>`
  cursor: ${(props) =>
    props.$isCustomCursor
      ? `url(${svgCursor({ theme: props.theme })}) 12 12, pointer !important`
      : "default"};
  background: ${({ theme }) => theme.neutralBackgroundBase}
`;
