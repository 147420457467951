import React from "react";

import { Button } from "../../components/Button";
import { ReactComponent as Error} from "../../assets/404.svg";
import {
  DetailsWrapper,
  ErrorImage,
  ErrorMessage,
  ErrorPageWrapper,
  ErrorTitle,
  RefreshImage,
} from "./styles";

export const ErrorPage = () => {
  return (
    <ErrorPageWrapper>
      <Error />
      <DetailsWrapper>
        <ErrorTitle>We've lost this page</ErrorTitle>
        <ErrorMessage>
          Sorry, the page you are looking for doesn't exist or has been moved.
        </ErrorMessage>
      </DetailsWrapper>
      <Button
        name="Refresh"
        onClick={() => window.location.reload()}
        icon={<RefreshImage />}
        variant="neutral"
        size="medium"
      />
    </ErrorPageWrapper>
  );
};
