import styled from "styled-components";

type TooltipPosition = "top" | "bottom";
interface TooltipContainerProps {
  $triangleLeft: string;
  $triangleTop: string;
  $triangleSide: TooltipPosition;
}

const getTooltipTriangle = (position: TooltipPosition, color: string) => {
  switch (position) {
    case "top":
      return `
        border-right: 5px solid transparent;
        border-bottom: 8px solid ${color};
        border-left: 5px solid transparent;
      `;
    case "bottom":
    default:
      return `
        border-right: 5px solid transparent;
        border-top: 8px solid ${color};
        border-left: 5px solid transparent;
      `;
  }
};

const getTooltipTriangleShadow = (position: TooltipPosition, color: string) => {
  switch (position) {
    case "top":
      return `
        border-right: 7px solid transparent;
        border-bottom: 8px solid ${color};
        border-left: 7px solid transparent;
      `;
    case "bottom":
    default:
      return `
        border-right: 7px solid transparent;
        border-top: 8px solid ${color};
        border-left: 7px solid transparent;
      `;
  }
};
export const TooltipContainer = styled.div<TooltipContainerProps>`
  position: absolute;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  color: ${({ theme }) => theme.neutralText};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 8px;

  pointer-events: none;
  z-index: 1001;

  &:after {
    content: "";
    position: absolute;
    top: ${({ $triangleTop }) => $triangleTop ?? "100%"};
    left: ${({ $triangleLeft }) => $triangleLeft};
    width: 0;
    height: 0;
    ${({ $triangleSide, theme }) => getTooltipTriangle($triangleSide, theme.neutralBackgroundBase)};
    translate: -50%;
  }

  &::before {
    content: "";
    position: absolute;
    top: ${({ $triangleTop }) => $triangleTop ?? "100%"};
    left: ${({ $triangleLeft }) => $triangleLeft};
    opacity: 0.1;
    width: 0;
    height: 0;
    ${({ $triangleSide, theme }) => getTooltipTriangleShadow($triangleSide, theme.neutralBackgroundBase)};
    translate: -50%;
  }
`;

export const TooltipBlock = styled.div`
  flex-direction: column;
  justify-content: start;
  align-items: start;
  min-width: 110px;
  min-height: 16px;
  display: flex;
`;

export const TooltipColumn = styled.div<{
  $border?: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  gap: 2px;
  border-bottom: 1px dashed
    ${({ $border, theme }) => ($border ? theme.neutralBorder : "transparent")};
  &:last-child {
    border: none;
  }
`;

export const TooltipHeading = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`;
