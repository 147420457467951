import { IActiveFilters } from "../../UserManagementComponents/Users/UsersList";

interface IApplyFilters<T> {
  skip?: keyof T;
  activeFilters: IActiveFilters<T>;
  data: T[];
}

export const applyFilters = <T>({ skip, activeFilters, data }: IApplyFilters<T>): T[] => {
  const filters = Object.keys(activeFilters) as (keyof T)[];

  let result = data;

  filters.forEach((keyName) => {
    const filterData = activeFilters[keyName];
    const search = filterData?.search;
    const filter = filterData?.filter;

    if (search) {
      result = result.filter((item) => {
        const itemValue = item[keyName]?.toString().toLocaleLowerCase() || '';
        const searchValue = search.toLocaleLowerCase();
        return itemValue.match(searchValue);
      });
    }

    if (skip === keyName) return;

    if (filter) {
      result = result.filter((item) => {
        const itemValue = item[keyName];

        if (Array.isArray(itemValue)) {
          return itemValue.some((val) => filter.includes(val));
        }
        return filter.includes(String(itemValue));
      });
    }
  });

  return result;
};
