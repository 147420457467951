import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";
import { DELETE_PROFILES, GET_PROFILES, PATCH_PROFILES } from "./constants";
import { IUpdateProfile } from './../types/profile';

export const getProfile = async () => {
  return axios
    .get(`${GET_PROFILES}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};


export const deleteProfile = async () => {
  return axios
    .get(`${DELETE_PROFILES}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const updateProfile = async (data: IUpdateProfile) => {
  return axios
    .patch(`${PATCH_PROFILES}`, { ...data })
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};
