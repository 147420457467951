  import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile, IUpdateProfile } from "../../types/profile";

const PROFILE_STORAGE_KEY = 'dhcs_user_profile';

interface IProfilesState {
  profile: IProfile 
}

export const initialProfileState: IProfilesState = {
  profile: {
    id: "",
    uid: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    organization: "",
    location: "",
    officePhone: "",
    avatar: "",
    isAdmin: false,
    canAddProjects: false,
    autoAccessToFutureProjects: false,
    desktopNotifications: {
      importantAlerts: false,
      dashboardPublished: false,
      viewerEditor: false,
      playSoundOnReminder: false,
      statusUpdate: true
    },
    emailNotifications: {
      emailViewerEditor: false,
      emailDashboardCompleted: false,
      emailStatusUpdate: false
    },
  }
}

const profilesSlices = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    setProfiles: (state: IProfilesState, action: PayloadAction<IProfile>) => {
      state.profile = {...state.profile, ...action.payload};
      try {
        localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(state.profile));
      } catch (error) {
        console.error('Error saving profile to localStorage:', error);
      }
    },
    requestProfile: (state: IProfilesState, action: PayloadAction) => {
      try {
        const savedProfile = localStorage.getItem(PROFILE_STORAGE_KEY);
        if (savedProfile) {
          state.profile = {...state.profile, ...JSON.parse(savedProfile)};
        }
      } catch (error) {
        console.error('Error loading profile from localStorage:', error);
      }
    },
    requestDeleteProfile: (state: IProfilesState, action: PayloadAction) => {},
    requestUpdateProfile: (state: IProfilesState, action: PayloadAction<IUpdateProfile>) => {},
  },  
},
);

export const {
  setProfiles,
  requestProfile,
  requestDeleteProfile,
  requestUpdateProfile
} = profilesSlices.actions;

export default profilesSlices.reducer;