import React from 'react';
import * as s from '../../styles';
import { SearchItem, ItemData } from '../../types';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import { SearchOption } from '../SearchOption/SearchOption';
import { DimensionIcons } from '../CategoryIcons/CategoryIcons';

interface GlobalSearchProps {
  searchResults: Array<{ category: SearchItem; matchingItems: ItemData[] }>;
  expandedCategories: Set<string>;
  selections: any;
  handleCategoryClick: (category: SearchItem, matchingItems?: ItemData[]) => void;
  handleItemSelect: (item: ItemData, categoryName: string) => void;
}

export const GlobalSearch: React.FC<GlobalSearchProps> = ({
  searchResults,
  expandedCategories,
  selections,
  handleCategoryClick,
  handleItemSelect
}) => (
  <s.SearchResults>
    <s.MainColumn>
      {searchResults.map(({ category, matchingItems }) => (
        <s.CategoryGroup key={category.id}>
          <s.CategoryHeader onClick={() => handleCategoryClick(category)}>
            <s.CategoryTitle>{category.name}</s.CategoryTitle>
            <s.ChevronIcon>
              {expandedCategories.has(category.name) ? (
                <ChevronDown size={16} />
              ) : (
                <ChevronRight size={16} />
              )}
            </s.ChevronIcon>
          </s.CategoryHeader>
          {expandedCategories.has(category.name) && (
            <s.CategoryItems>
              {matchingItems.map((item) => (
                <SearchOption
                  key={item.id}
                  item={item}
                  isSelected={Boolean(
                    selections[category.name]?.[item.id]?.selected
                  )}
                  onItemSelect={(item) =>
                    handleItemSelect(item, category.name)
                  }
                  hasItemCount={false}
                />
              ))}
            </s.CategoryItems>
          )}
        </s.CategoryGroup>
      ))}
    </s.MainColumn>
    <s.SideColumn>
      <s.SideColumnTitle>Narrow your search</s.SideColumnTitle>
      {searchResults.map(({ category, matchingItems }) => (
        <s.FilterOption
          key={category.id}
          onClick={() => handleCategoryClick(category, matchingItems)}
        >
          <s.FilterIcon>
            <DimensionIcons state={category.name.toLowerCase()} />
          </s.FilterIcon>
          {category.name}
          <s.FilterCount>{matchingItems.length}</s.FilterCount>
        </s.FilterOption>
      ))}
    </s.SideColumn>
  </s.SearchResults>
);