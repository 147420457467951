import { styled } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (width: 1440px) {
    justify-content: "start";
  }

`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 918px;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  position: relative;
  box-sizing: border-box;
  gap: 10px;
  margin-bottom: 10px;
  page-break-after: always;

  &.generating-pdf {
    * {
      scrollbar-width: none !important;
      -ms-overflow-style: none !important;
      
      &::-webkit-scrollbar {
        display: none !important;
      }
    }
  }
`;

export const ProgressBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.neutralBackgroundActive};
  z-index: 1000;
`;

export const ProgressBar = styled.div<{ width: number }>`
  height: 100%;
  background-color: ${({ theme }) => theme.primaryBackgroundStrong};
  width: ${(props) => props.width}%;
  transition: width 0.3s ease-in-out;
`;

export const ThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`;

export const Container = styled.div<{ $isCenter: boolean }>`
  display: flex;
  width: 100%;
  
  > * {
    width: 100%;
    max-width: ${({ $isCenter }) => $isCenter ? '918px' : '100%'};
    margin-left: ${({ $isCenter }) => $isCenter ? 'auto' : '0'};
    margin-right: ${({ $isCenter }) => $isCenter ? 'auto' : '0'};
    transition: all 0.3s ease-in-out;
  }

  @media (min-width: 1340px) {
    > * {
      max-width: 918px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;