import styled from "styled-components";
import { XLg } from "react-bootstrap-icons";

export const ContentWrapper = styled.div`
  flex: 1;
  margin: -55px 20px 0;
  height: calc(100% + 115px);
  overflow: auto;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  padding-bottom: 16px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const HeaderItemsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
export const Heading = styled.div`
  font-size: 16px;
  font-weight: 600;
  flex: 1;
  color: ${({ theme }) => theme.neutralText};
`;
export const Close = styled(XLg)`
  cursor: pointer;
  margin: 0 10px 0 19px;
`;
export const FileOverview = styled.div`
  display: flex;
  padding-bottom: 20px;
`;
