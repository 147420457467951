import { PayloadAction } from "@reduxjs/toolkit";
import { IProfile, IUpdateProfile } from "../../types/profile";
import { ServerResponse } from "../../models";
import { put, call, takeLatest } from "redux-saga/effects";
import { setProfiles } from "../../store/slices/profiles";
import { toast } from "react-toastify";
import { getProfile, updateProfile, deleteProfile } from "../../api/profiles";

export function* handleGetProfile({ payload }: PayloadAction<IProfile>) {
  const { response, error }: ServerResponse = yield call(getProfile);

  if (error) {
    console.error("Get profile failed", error);
    // If API call fails, we'll keep using the localStorage data loaded in the reducer
    return;
  }

  if (response?.status === 200) {
    yield put(setProfiles(response.data));
    payload?.callbacks?.onSuccess?.(response.data);
  }
}

export function* handleDeleteProfile({ payload }: PayloadAction<IProfile>) {
  const { response }: ServerResponse = yield call(deleteProfile);

  if (response?.status === 200) {
    payload?.callbacks?.onSuccess();
    toast.success("Profile deleted");
  }
}

export function* handleUpdateProfile({ payload }: PayloadAction<IUpdateProfile>) {
  const { response, error }: ServerResponse = yield call(updateProfile, payload);
  if (error) {
    console.error("Update profile failed", error);
  }

  if (response?.status === 200) {
    yield put(setProfiles(response.data));
    payload?.callbacks?.onSuccess();
    toast.success("Profile saved");

  }
}

export function* watchProfileActions() {
  yield takeLatest("profile/requestProfile", handleGetProfile);
  yield takeLatest("profile/requestDeleteProfile", handleDeleteProfile);
  yield takeLatest("profile/requestUpdateProfile", handleUpdateProfile);
}
