import styled, { createGlobalStyle, css, keyframes } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
      /* Light Theme Variables */
      --neutral-background-base: #FFFFFF;
      --neutral-background-medium: #EFF0F1;
      --neutral-background-extra-dark: #141C2C;
      --neutral-background: #5F687714;
      --neutral-background-hover: #5F68771F;
      --neutral-background-active: #5F687729;

      --neutral-text: #000005;
      --neutral-text-weak: #5F6877;
      --neutral-text-disabled: #9FA4AD;

      --neutral-border: #DFE1E4;
      --neutral-border-medium: #9FA4AD;

      --neutral-scrim: #0000051A;

      /* Primary */
      --primary-action: #00143F;
      --primary-action-hover: #000631;

      --primary-background: #2D6E8D1F;
      --primary-background-medium: #2D6E8D33;
      --primary-background-active: #2D6E8D3D;
      --primary-background-strong: #2D6E8D;
      --primary-background-strong-hover: #155676;
      --primary-background-strong-active: #003E5F;

      --primary-text: #155676;
      --primary-text-hover: #003E5F;
      --primary-text-strong: #FFFFFF;
      --primary-text-strong-hover: #81A8BB;
      --primary-text-strong-active: #578BA4;
      --secondary-text-user: #0b101a;

      --primary-border: #2D6E8D;

      /* Alert */
      --alert-background: #C536601F;
      --alert-background-medium: #C5366033;
      --alert-background-active: #C536603D;
      --alert-background-strong: #C53660;
      --alert-background-strong-hover: #A81945;
      --alert-background-strong-active: #7C001B;

      --alert-text: #8F1A3D;
      --alert-text-hover: #7C001B;

      --alert-border: #C53660;

      /* Warning */
      --warning-background: #B35F001F;
      --warning-background-medium: #B35F0033;
      --warning-background-active: #B35F003D;
      --warning-background-strong: #B35F00;
      --warning-background-strong-hover: #913D00;
      --warning-background-strong-active: #802C00;

      --warning-text: #913D00;
      --warning-text-hover: #802C00;

      --warning-border: #B35F00;

      /* Success */
      --success-background: #1386601F;
      --success-background-medium: #13866033;
      --success-background-active: #1386603D;
      --success-background-strong: #138660;
      --success-background-strong-hover: #00623E;
      --success-background-strong-active: #004A27;

      --success-text: #00623E;
      --success-text-hover: #004A27;
      --success-border: #138660;
  }


  .dark-theme {
      /* Dark Theme Variables */
      --neutral-background-base: #141C2C;
      --neutral-background-medium: #1F2A3A;
      --neutral-background-extra-dark: #0A0F1A;
      --neutral-background: #2A3B4C;
      --neutral-background-hover: #3A4B5C;
      --neutral-background-active: #4A5B6C;

      --neutral-text: #FFFFFF;
      --neutral-text-weak: #B0B8C0;
      --neutral-text-disabled: #7A828A;

      --neutral-border: #3A4B5C;
      --neutral-border-medium: #4A5B6C;

      --neutral-scrim: #FFFFFF1A;

      /* Primary */
      --primary-action: #81A8BB;
      --primary-action-hover: #578BA4;

      --primary-background: #1F2A3A;
      --primary-background-medium: #2A3B4C;
      --primary-background-active: #3A4B5C;
      --primary-background-strong: #4A5B6C;
      --primary-background-strong-hover: #5A6B7C;
      --primary-background-strong-active: #6A7B8C;

      --primary-text: #81A8BB;
      --primary-text-hover: #578BA4;
      --primary-text-strong: #FFFFFF;
      --primary-text-strong-hover: #B0B8C0;
      --primary-text-strong-active: #7A828A;
      --secondary-text-user: #FFFFFF;

      --primary-border: #4A5B6C;

      /* Alert */
      --alert-background: #7C001B1F;
      --alert-background-medium: #7C001B33;
      --alert-background-active: #7C001B3D;
      --alert-background-strong: #7C001B;
      --alert-background-strong-hover: #A81945;
      --alert-background-strong-active: #C53660;

      --alert-text: #C53660;
      --alert-text-hover: #A81945;

      --alert-border: #7C001B;

      /* Warning */
      --warning-background: #802C001F;
      --warning-background-medium: #802C0033;
      --warning-background-active: #802C003D;
      --warning-background-strong: #802C00;
      --warning-background-strong-hover: #913D00;
      --warning-background-strong-active: #B35F00;

      --warning-text: #B35F00;
      --warning-text-hover: #913D00;

      --warning-border: #802C00;

      /* Success */
      --success-background: #004A271F;
      --success-background-medium: #004A2733;
      --success-background-active: #004A273D;
      --success-background-strong: #004A27;
      --success-background-strong-hover: #00623E;
      --success-background-strong-active: #138660;

      --success-text: #138660;
      --success-text-hover: #00623E;
      --success-border: #004A27;
  }

  ::-moz-scrollbar,::-webkit-scrollbar {
    width: 7px;
  }

  ::-moz-scrollbar-thumb,::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.neutralBorderMedium};
    border-radius: 7px;
  }

  ::-moz-scrollbar-thumb:hover,::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover || theme.neutralBackground};
  }

  ::-moz-scrollbar-track,::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
  }

  ::-moz-scrollbar-button,::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.neutralBorderMedium} ${({ theme }) => theme.neutralBackgroundBase};
  }

  body {
    -ms-overflow-style: none;
  }

  body::-webkit-scrollbar {
    display: block; /* Force scrollbar styling */
  }
`;
// Common styles
export const Container = styled.div`
  padding: 0 20px;
  position: relative;
  min-height: calc(100dvh - 64px);
  overflow-y: auto;
  background: ${({ theme }) => theme.neutralBackgroundBase};
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: center;
  gap: 4px;
  align-items:flex-start;
  height: 92px;
`

export const Heading = styled.h2`
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.neutralText};
`;

export const TeamFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

export const commonSeparatorStyle = css`
  content: "";
  position: absolute;
  left: 0;
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.neutralBorder || "#DFE1E4"};
`;

// Animation section
export const fadeInDown = keyframes`
 0% {
   opacity: 0;
   transform: translate3d(0, -10%, 0);
 }
 100% {
   opacity: 1;
   transform: translate3d(0, 0, 0);
 }
`;

export const fadeInRight = keyframes`
 0% {
   opacity: 0.2;
   transform: translate3d(30%, 0, 0);
 }
 100% {
   opacity: 1;
   transform: translate3d(0, 0, 0);
 }
`;

export const fadeOutRight = keyframes`
 0% {
   opacity: 1;
   transform: translate3d(0, 0, 0);
 }
 100% {
   opacity: 0;
   transform: translate3d(50%, 0, 0);
 }
`;

export const backDropFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const backDropFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const textFadeInUp = keyframes`
  from {
    opacity: 0.9;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const animateMenuOpen = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
