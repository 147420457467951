import styled from "styled-components";

import { OverviewContent } from "../styles";
import { CloseIcon } from "../ProjectSettings/styles";

export const ModalSubmenu = styled.div`
  display: flex;
  gap: 16px;
  height: 36px;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
  padding: 0 20px 0 20px;
`;

export const CloseModal = styled(CloseIcon)``;

export const ModalHeadingWrapperExt = styled.div`
  padding-bottom: 0;
  display: block;
  height: auto;
  position: relative;
`;

export const SubmenuItem = styled.div<{ $selected: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  border-bottom-color: ${({ theme, $selected }) =>
    $selected ? theme.primaryBorder : "transparent"};
  color: ${({ theme, $selected }) =>
    $selected ? theme.neutralText : theme.neutralTextWeak};

  z-index: 1;
`;

export const SubmenuTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const OverviewContentExt = styled(OverviewContent)`
  height: calc(100% - 140px);
  display: flex;
  /* flex-direction: column; */
  overflow: auto;
  scrollbar-width: thin;
`;
