import React, { useRef } from "react";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import { useOnClickOutside } from "usehooks-ts";
import { DimensionSettingsWrapper, ActionsPage, ActionButton } from "./styles";
import { sortTable } from "../../helpers/sortTable";
import { createPortal } from "react-dom";

type SortOrder = "asc" | "desc";

interface ColumnConfig {
  key: string;
  label: string;
  getValue?: (item: any) => any;
}

interface Props<T> {
  top: number;
  left: number;
  data: T[];
  setData: React.Dispatch<React.SetStateAction<T[]>>;
  columns: ColumnConfig[];
  activeSort: { key: string; order: SortOrder };
  setActiveSort: React.Dispatch<
    React.SetStateAction<{ key: string; order: SortOrder }>
  >;
  closeMenu: () => void;
  columnKey: string;
}

const SortingMenu = <T,>({
  top,
  left,
  data,
  setData,
  columns,
  activeSort,
  setActiveSort,
  closeMenu,
  columnKey,
}: Props<T>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, closeMenu);

  const handleSort = (key: string, order: SortOrder) => {
    setActiveSort({ key, order });
    const column = columns.find((col) => col.key === key);
    const sortedData = sortTable(data, key, order, column?.getValue);

    setData(sortedData);
    closeMenu();
  };

  const menu = (
    <DimensionSettingsWrapper $top={top} $left={left} ref={ref}>
      <ActionsPage>
        {columns
          .filter(({ key }) => key === columnKey)
          .map(({ key, label }) => (
            <React.Fragment key={key}>
              <ActionButton
                $active={activeSort.key === key && activeSort.order === "asc"}
                onClick={() => handleSort(key, "asc")}
              >
                <ArrowUp />
                Sort Ascending
              </ActionButton>
              <ActionButton
                $active={activeSort.key === key && activeSort.order === "desc"}
                onClick={() => handleSort(key, "desc")}
              >
                <ArrowDown />
                Sort Descending
              </ActionButton>
            </React.Fragment>
          ))}
      </ActionsPage>
    </DimensionSettingsWrapper>
  );

  return createPortal(menu, document.body);
};

export default SortingMenu;
