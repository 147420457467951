import { IOption } from "../../Inputs/CustomSelect/types";
import { IMarkers, IPath } from "../types/types";

export const markers: IMarkers[] = [
  "donut",
  "circle",
  "square",
  "rhombus",
  "triangle",
  "disabled",
];

export const paths: IPath[] = [
  {
    name: "Male",
    color: "#938CFF",
    marker: "donut",
  },
  {
    name: "Female",
    color: "#FF8C47",
    marker: "donut",
  },
];

export const selectOptions: IOption[] = [
  {
    option: "Area",
    value: "areaChart",
  },
  {
    option: "Bar",
    value: "barChart",
  },
  {
    option: "Bubble",
    value: "bubbleChart",
  },
  {
    option: "Line",
    value: "lineChart",
  },
  {
    option: "Lollipop",
    value: "lollipopChart",
  },
  {
    option: "Map",
    value: "mapChart",
  },
  {
    option: "Matrix",
    value: "matrix",
  },
  {
    option: "Polar Area",
    value: "polarAreaChart",
  },
  {
    option: "Punchcard",
    value: "punchcardChart",
  },
  {
    option: "Radar",
    value: "radar",
  },
  {
    option: "Radial Bar",
    value: "radialBarChart",
  },
  {
    option: "Sankey",
    value: "sankey",
  },
  {
    option: "Scatterplot",
    value: "scatterplot",
  },
  {
    option: "Spark Area",
    value: "sparkAreaChart",
  },
  {
    option: "Spark Line",
    value: "sparkLineChart",
  },
  {
    option: "Stacked Area",
    value: "stackedAreaChart",
  },
  {
    option: "Stacked Bar",
    value: "stackedBarChart",
  },
  {
    option: "Stacked Lollipop",
    value: "stackedLollipopChart",
  },
];
