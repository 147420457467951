import { styled } from "styled-components";

export const DimensionSettingsWrapper = styled.div<{
  $top: number;
  $left: number;
}>`
  position: absolute;
  top: ${({ $top }) => `${$top - 50}px `};
  left: ${({ $left }) => `${$left - 180}px`};
  width: 207px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0 10px 0;
  padding: 8px 0;
  z-index: 1;
`;

export const ActionsPage = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionButton = styled.div<{
  $active?: boolean;
}>`
  padding: 4px 12px;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s;
  cursor: pointer;
  svg {
    width: 16px;
    height:16px;
  }

  ${({ $active, theme }) => $active && `
    background: ${theme.neutralBackground};
    color: ${theme.neutralText};
    font-weight: 500;
  `}

  &:active {
    background: #6F6FE929;
    color: ${({ theme }) => theme.neutralText};
  }

  &:hover {
    color: ${({ theme }) => theme.neutralText};
  }
`;