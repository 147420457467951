import React from 'react';
import { Search as SearchIcon } from "react-bootstrap-icons";
import * as s from '../../styles';
import { RecentSearch } from '../../types';

interface RecentSearchesProps {
  recentSearches: RecentSearch[];
  onRecentSearchClick: (search: RecentSearch) => void;
  renderDefaultView: () => React.ReactNode;
}

export const RecentSearches: React.FC<RecentSearchesProps> = ({
  recentSearches,
  onRecentSearchClick,
  renderDefaultView
}) => {
  if (!recentSearches.length) {
    return null;
  }

  return (
    <s.SearchResults>
      <s.MainColumn>
        <s.RecentSearchesTitle>Recently viewed</s.RecentSearchesTitle>
        {recentSearches.map((search) => (
          <s.RecentSearchItem 
            key={search.id}
            onClick={() => onRecentSearchClick(search)}
          >
            <s.SearchIcon>
              <SearchIcon size={16} />
            </s.SearchIcon>
            {search.searchString}
          </s.RecentSearchItem>
        ))}
      </s.MainColumn>
      <s.SideColumn>{renderDefaultView()}</s.SideColumn>
    </s.SearchResults>
  );
};