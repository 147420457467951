import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useViewportAdjustment } from '../../hooks/useViewportAdjustment';

interface MessagePortalProps {
  children: React.ReactNode;
  triggerRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
  onClose: () => void;
  isStorytelling?: boolean;
}

export const MessagePortal: React.FC<MessagePortalProps> = ({
  children,
  triggerRef,
  isOpen,
  onClose,
  isStorytelling
}) => {
  const portalRef = useRef<HTMLDivElement | null>(null);
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const portalContainer = document.createElement('div');
    portalContainer.id = 'message-portal';
    document.body.appendChild(portalContainer);
    portalRef.current = portalContainer;

    return () => {
      if (portalContainer && document.body.contains(portalContainer)) {
        document.body.removeChild(portalContainer);
      }
    };
  }, []);

  const { horizontalPosition, horizontalOffset } = useViewportAdjustment(
    triggerRef,
  );

  if (!isOpen || !portalRef.current) return null;

  const getTriggerPosition = () => {
    if (!triggerRef.current) return { top: 0, left: 0 };
    
    const triggerRect = triggerRef.current.getBoundingClientRect();
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    return {
      top: triggerRect.top + scrollY + (isStorytelling ? 10 : 45),
      left: triggerRect.left + scrollX
    };
  };

  const { top, left } = getTriggerPosition();

  const portalContent = (
    <div
      ref={messageRef}
      style={{
        position: 'absolute',
        top,
        [horizontalPosition]: horizontalPosition === 'right' ? 
          `calc(100% - ${left + Math.abs(horizontalOffset)}px)` : 
          `${left + horizontalOffset}px`,
        zIndex: 1000,
      }}
    >
      {children}
    </div>
  );

  return createPortal(portalContent, portalRef.current);
};