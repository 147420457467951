import styled from "styled-components";

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DatasetWrapper = styled.div<{ $full?: boolean }>`
  display: block;
  margin: 0 auto;
  max-width: 724px;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px 0 20px;
`

export const Title = styled.textarea`
  border: none;
  outline: none;
  font-size: 32px;
  font-weight: 600;
  resize: none;
  overflow: hidden;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  color: ${({ theme }) => theme.neutralText};

  &::placeholder {
    color: ${({ theme }) => theme.neutralTextDisabled};
  }

  &:hover::placeholder {
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const UploadedItemsHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  padding: 8px 20px;
  display: flex;
  gap: 10px;
`

export const FileName = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.neutralTextWeak};
`

export const Description = styled.span`
  font-size: 14px;
  font-weight: 500;
  padding: 8px 0 8px 0;
  color: ${({ theme }) => theme.neutralText};
`;

export const DescriptionInput = styled.textarea`
  border: none;
  outline: none;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  resize: none;
  overflow: hidden;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  color: ${({ theme }) => theme.neutralText};
  
  &::placeholder {
    color: ${({ theme }) => theme.neutralTextDisabled};
  }

  &:hover::placeholder {
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;
export const ContentSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.neutralBorder};
  margin-top: 16px;
`;

export const ImportedFilesContainer = styled.div<{ $noGap?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: ${({ $noGap }) => ($noGap ? "" : "8px")};
`;
