import { FC, useEffect, useState } from "react";
import * as s from "./styles";
import { ReactComponent as Logo } from "../../../../assets/logo-32px.svg";
import { IPageHeader } from "../../../../store/slices/pageContentGenerator";
import { getBase64Image } from "../StoryTellingSection/utils/getBase64Image";

interface Props {
  file: undefined;
  contentItem: IPageHeader | undefined;
}

export const LastPage: FC<Props> = ({ file, contentItem }) => {
  const [base64Image, setBase64Image] = useState<string>("");

  useEffect(() => {
    const loadImage = async () => {
      if (file) {
        const base64 = await getBase64Image(file);
        setBase64Image(base64);
      }
    };
    loadImage();
  }, [file]);

  return (
    <s.PageContainer id="last-page">
      <s.DiagonalImageSection>
        <s.SVGLayer
          xmlns="http://www.w3.org/2000/svg"
          width="918"
          height="577"
          viewBox="0 0 918 577"
          $zIndex={0}
        >
          <path
            d="M720.664 575L-246 -260H1019V308.967L720.664 575Z"
            stroke="#E3E6E9"
            strokeWidth="2"
          />
        </s.SVGLayer>
        <s.SVGLayer
          xmlns="http://www.w3.org/2000/svg"
          width="918"
          height="657"
          viewBox="0 0 918 657"
          $zIndex={1}
        >
          <path
            d="M722.975 657L-265 -188H930.839L1005 405.622L722.975 657Z"
            fill="#2D6E8D"
          />
        </s.SVGLayer>
        <s.SVGLayer
          xmlns="http://www.w3.org/2000/svg"
          width="918"
          height="641"
          viewBox="0 0 918 641"
          $zIndex={2}
        >
          <path
            opacity="0.8"
            d="M786.975 641L-201 -204H994.839L1069 389.622L786.975 641Z"
            fill="#2D6E8D"
          />
        </s.SVGLayer>
        <s.SVGLayer
          xmlns="http://www.w3.org/2000/svg"
          width="918"
          height="577"
          viewBox="0 0 918 577"
          fill="none"
          $zIndex={6}
        >
          <path
            d="M720.664 575L-246 -260H1019V308.967L720.664 575Z"
            stroke="#E3E6E9"
            strokeWidth="2"
          />
        </s.SVGLayer>

        <s.ImageContainer
          $image={base64Image || file!}
          $backgroundColor={contentItem?.backgroundColor || ""}
        >
          {!contentItem?.backgroundColor && (base64Image || file) && (
            <img
              src={base64Image || file}
              alt="Main"
              data-testid="main-image"
              loading="lazy"
            />
          )}
        </s.ImageContainer>
      </s.DiagonalImageSection>
      <s.TextSection>
        <s.MainTitle>Contact Us</s.MainTitle>
        <s.Subtitle>
          Please submit questions about any new initiative on this email
          DHCS.ca.gov
        </s.Subtitle>
      </s.TextSection>
      <s.FooterSection>
        <s.FooterLogo>
          <Logo />
        </s.FooterLogo>
        <s.FooterText>Health Information Management Division</s.FooterText>
      </s.FooterSection>
    </s.PageContainer>
  );
};
