import styled from "styled-components";
import { AlphaPicker } from "react-color";

export const StyledSketchPickerWrapper = styled.div<{
  $backgroundColor?: string;
}>`
  overflow-y: unset !important;
  width: 256px !important;
  height: 209px !important;
  margin: 16px 15px 30px 15px !important;

  .swatches-picker {
    height: unset !important;
    width: unset !important;
    overflow: unset !important;
  }
  .swatches-picker > div > div:nth-child(2) > div {
    height: unset !important;
    width: unset !important;
    overflow: unset !important;
  }

  .swatches-picker > div > div {
    inset: 0 !important;
    box-shadow: none !important;
    background: none !important;
  }

  .swatches-picker > div > div > div > div {
    padding: 0 !important;
  }
  .swatches-picker > div > div:nth-child(2) > div > div > div {
    width: unset !important;
    margin-right: 1px !important;
  }

  & .swatches-picker span > div {
    height: 20px !important;
    width: 20px !important;
    border: 1px solid #dfe1e4;
    border-radius: 0px !important;
  }

  & .swatches-picker span > div > div {
    margin-left: 3px !important;
  }

  & .swatches-picker span svg {
    height: 14px !important;
    width: 14px !important;
  }

  .alpha-picker {
    height: 4px !important;
  }

  .alpha-picker > div > div:nth-child(3) > div > div {
    transform: translate(-9px, -7px) !important;
    background-color: ${({ $backgroundColor }) => $backgroundColor} !important;
    outline: 1px solid white !important;
  }

  .swatches-picker
    > div
    > div:nth-child(2)
    > div
    > div
    > div:nth-child(1)
    > span:nth-child(1)
    > div {
    border-radius: 6px 0px 0px 0px !important;
  }
  .swatches-picker
    > div
    > div:nth-child(2)
    > div
    > div
    > div:nth-child(1)
    > span:nth-child(10)
    > div {
    border-radius: 0px 0px 0px 6px !important;
  }
  .swatches-picker
    > div
    > div:nth-child(2)
    > div
    > div
    > div:nth-child(12)
    > span:nth-child(1)
    > div {
    border-radius: 0px 6px 0px 0px !important;
  }
  .swatches-picker
    > div
    > div:nth-child(2)
    > div
    > div
    > div:nth-child(12)
    > span:nth-child(10)
    > div {
    border-radius: 0px 0px 6px 0px !important;
  }
`;

export const CustomAlphaPicker = styled(AlphaPicker)`
  margin-top: 18px;
`;

export const GradientsDelimtator = styled.div`
  margin-top: 16px;
  border-top: ${({ theme }) => `1px solid ${theme.neutralBorder}`};
  width: 286px !important;
  margin-left: -15px;
`;

export const GradientsWrapperText = styled.div`
  padding: 16px 0px;
`;

export const GradientsWrapper = styled.div`
  margin-top: 16px;
`;

export const GradientsTitle = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

export const GradientsItems = styled.div`
  display: flex;
  gap: 8px;
  flex-flow: wrap;
  margin-top: 8px;
`;
export const GradientsItemBlock = styled.div`
  display: flex;
`;

export const GradientsItem = styled.div<{
  $selectedItem?: boolean | undefined;
  $gradient: string;
}>`
  cursor: pointer;
  height: 28px;
  width: 28px;
  background: ${({ $gradient }) => $gradient};
  border-radius: ${({ $selectedItem }) => ($selectedItem ? "24px" : "4px")};
  border: ${({ $selectedItem, theme }) =>
    $selectedItem ? `2px solid ${theme.primaryAction}` : ""};
`;

export const ColorItem = styled.div<{
  $selectedItem?: boolean | undefined;
  $color: string;
}>`
  cursor: pointer;
  height: 28px;
  width: 28px;
  background: ${({ $color }) => $color};
  border-radius: ${({ $selectedItem }) => ($selectedItem ? "24px" : "4px")};
  border: ${({ $selectedItem, theme }) =>
    $selectedItem
      ? `1px solid ${theme.primaryAction}`
      : `1px solid ${theme.neutralBorder}`};
`;

export const HexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: visible;
  position: relative;
`;

export const HexTitle = styled.div`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0.8px;
`;

export const HexInputColor = styled.div<{
  $color: string;
}>`
  background: ${({ $color }) => $color};
  width: 16px;
  height: 16px;
  border-radius: 4px;
  position: absolute;
  bottom: 8px;
  right: 10px;
`;

export const HexInput = styled.input`
  height: 32px;
  width: 251px;
  outline: none;
  padding-left: 10px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  color: ${({ theme }) => theme.neutralText};
  background: ${({ theme }) => theme.neutralBackgroundBase};
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
`;
