import { styled, keyframes } from "styled-components";

const pulse = keyframes`
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
`;

export const NavigationContainer = styled.div<{ $isHidden?: boolean }>`
  width: 160px;
  display: block;
  position: fixed;
  top: 130px;
  right: 20px;
  height: 86vh;
  overflow-y: auto;
  z-index: 100;
  padding: 8px;
  overscroll-behavior: none;
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 1)};
  transform: translateX(${({ $isHidden }) => ($isHidden ? '20px' : '0')});
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  pointer-events: ${({ $isHidden }) => ($isHidden ? 'none' : 'auto')};

  @media (min-width: 1150px) {
    width: 216px;
  }

  @media (min-width: 1900px) {
    right: 200px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
`;

export const DraggableArea = styled.div`
  min-height: 100%;
`;

export const ThumbnailContainer = styled.div<{ isDragging?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  ${({ isDragging, theme }) =>
    isDragging &&
    `
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    transform: scale(1.02);
    background: white;
    border-radius: 6px;
    border: 2px dashed ${theme.primaryBackgroundMedium}
  `}

`;

export const DragIconWrapper = styled.div<{ $isDragging?: boolean }>`
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  svg {
    opacity: ${({ $isDragging }) => ($isDragging ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;
  }
`;

export const HoverBar = styled.div`
  position: absolute;
  top: 1px;
  left: 4px;
  width: 4px;
  height: 20px;
  background-color: ${({ theme }) => theme.primaryBackgroundMedium};
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  border-radius: 0 3px 3px 0;
`;


export const RemoveButton = styled.button`
  all: unset;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  opacity: 0;
`

export const Thumbnail = styled.div<{
  $isSelected: boolean;
}>`
  position: relative;
  cursor: "pointer";
  margin-bottom: 8px;
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.neutralBorder}`};
  transition: all 0.3s ease-in-out;
  overflow: hidden;

  &:active {
    cursor: "pointer";
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px solid
      ${({ $isSelected, theme }) =>
        $isSelected ? theme.primaryBackgroundMedium : "transparent"};
    border-radius: 6px;
    z-index: 1;
    pointer-events: none;
    transition: border-color 0.3s ease-in-out;
  }

  &:hover {
    border-color: ${({ theme }) => theme.primaryBorder};
    ${HoverBar}, ${RemoveButton} {
      opacity: 1;
    }

    &::before {
      border-color: ${({ theme }) => theme.primaryBackgroundMedium};
    }
  }
`;

export const ThumbnailImage = styled.img`
  width: 150px;
  height: 188px;
  object-fit: cover;
  border-radius: 6px;

  @media (min-width: 1150px) {
    width: 200px;
    height: 258px;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Skeleton = styled.div`
  width: 200px;
  height: 258px;
  margin-bottom: 8px;
  border-radius: 6px;
  background-color: #e0e0e0;
  animation: ${pulse} 1s ease-in-out infinite;
`;

export const PageNumber = styled.span<{ $isDragDisabled?: boolean }>`
  align-self: center;
  font-size: 12px;
  color: ${({ theme, $isDragDisabled }) =>
    $isDragDisabled ? theme.neutralTextWeak : theme.neutralText};
  font-weight: ${({ $isDragDisabled }) => ($isDragDisabled ? "600" : "400")};
`;