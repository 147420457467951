import styled, { css } from "styled-components";

const textStyle = css`
  color: ${({theme}) => theme.neutralText};
  font-style: normal;
  padding: 9px 8px;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
`

export const TableRow = styled.div<{$selected?: boolean}>`
  display: flex;
  cursor: pointer;
  overflow: visible;
  align-items: flex-start;
  transition: 0.3s;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};

  &:last-child{
    border: none;
  }

  &:hover {
    ${({$selected, theme}) => !$selected && `
      background: ${theme.neutralBackgroundHover}
    `}
  }

  ${({$selected, theme}) => $selected && `
    background: ${theme.primaryBackground}
  `}
`

export const TableCell = styled.div<{$width?: number; $noPadding?: boolean}>`
  ${textStyle};
  white-space: inherit;
  gap: 5px;

  ${({$width}) => $width ? `
    max-width: ${$width}px;
    min-width: ${$width}px;
    height: 100%;
  ` : `
    height: 100%;
    width: 100%;
  `}

  ${({$noPadding}) => $noPadding && `padding: 0;`}

  &:nth-child(1) {
    height: 100% !important;
    width: 100% !important;
  }
`

export const DateSpan = styled.span`
  color: ${({theme}) => theme.neutralText};
`

export const TimeSpan = styled.span`
  color: ${({theme}) => theme.neutralTextWeak};
`

export const Chip = styled.div<{$disabled?: boolean, $role?: string}>`
  text-transform: capitalize;
  cursor: pointer;
  padding: 3px 6px;
  background: ${({theme}) => theme.neutralBackground};
  border-radius: 2px;
  ${({$disabled, theme}) => $disabled && `
    color: ${theme.neutralTextWeak};
  `}

  ${({$role, theme}) => $role === "viewer" && `
    color: ${theme.neutralTextWeak};
    border: 1px solid ${theme.neutralBorder};
  `}

  ${({$role, theme}) => $role === "manager" && `
    color: ${theme.primaryText};
    background: ${theme.primaryBackground};
    border: 1px solid ${theme.primaryBackgroundActive};
  `}

  ${({$role, theme}) => ($role === "editor" || $role === "owner") && `
    color: ${theme.successText} !important;
    background: ${theme.successBackground};
    border: 1px solid ${theme.successBackgroundActive};
  `}

  ${({$role, theme}) => $role && !["viewer", "manager", "viewer"].includes($role) && `
    color: ${theme.neutralTextWeak};
    border: 1px solid ${theme.neutralBorder};
  `}
`