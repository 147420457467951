import React, { ChangeEvent } from "react";
import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlockWrapper,
  SwitchWrapper,
  SwitchText,
  GapFlexRowBlock,
  GapColumnBlock,
  SubTitleText,
  FlexBlock,
  SubTitle,
} from "../styles";
import { FormikErrors, FormikTouched } from "formik";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";
import { getNotificationSettings, getEmailNotificationSettings, getInvitesSettings } from "./getSettings";
import RadioButton from "../../../Inputs/CustomRadio/RadioButton";
import { IProfile } from "../../../../types/profile";

type NotificationsProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  setValues: (values: any, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>;
  handleBlur: React.ChangeEventHandler;
  values: IProfile;
  errors: {
    [key: string]:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined;
  };
  touched: {
    [key: string]:
    | boolean
    | FormikTouched<any>
    | FormikTouched<any>[]
    | undefined;
  };
};

export const Notifications: React.FC<NotificationsProps> = ({
  handleChange,
  setValues,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  
  const handleSwitchChange = (objectName: keyof IProfile, name: string, checked: boolean) => {
    setValues({ ...values, [objectName]: { ...(values?.[objectName || 'desktopNotifications'] as any), [name]: checked } });
  };

  const handleRadioButtonChange = (objectName: keyof IProfile, name: string, label: string) => {
    setValues({ ...values, [objectName]: { ...(values?.[objectName || 'desktopNotifications'] as any), [name]: label } });
  };

  const notificationSettings = getNotificationSettings(values['desktopNotifications'], errors, touched, setValues, handleBlur)
  const emailNotificationSettings = getEmailNotificationSettings(values['emailNotifications'], errors, touched, setValues, handleBlur)
  const invitesSettings = getInvitesSettings(values['emailNotifications'], errors, touched, setValues, handleBlur)

  const { desktopNotifications, emailNotifications } = values;



  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <GapFlexRowBlock>
            <GapColumnBlock>
              <SwitchWrapper>
                <SwitchText>Desktop</SwitchText>
                <SwitchComponent
                  name={'dashboardPublished'}
                  checked={!!desktopNotifications.dashboardPublished}
                  onChange={(e) => handleSwitchChange('desktopNotifications', 'dashboardPublished', e.target.checked)}
                  onBlur={handleBlur}
                  errors={errors?.desktopNotifications as string}
                  touched={!!touched.desktopNotifications}
                  label={!!desktopNotifications.dashboardPublished ? 'Enabled' : 'Disabled'}
                  disableMargin
                />
              </SwitchWrapper>
              {
                !!desktopNotifications.dashboardPublished &&
                <>
                  <SubTitle>File Comments</SubTitle>
                  {notificationSettings.map((setting) => (
                    <FlexBlock key={setting.name}>
                      <RadioButton
                        name={setting.name}
                        onChange={() => handleRadioButtonChange('desktopNotifications', setting.name, setting.label)}
                        checked={setting?.checked!}
                        value={setting.name}
                      />
                      <SubTitleText $checked={setting.checked}>
                        {setting.label}
                      </SubTitleText>
                    </FlexBlock>
                  ))}
                </>
              }
            </GapColumnBlock>
            <GapColumnBlock>
              <SwitchWrapper>
                <SwitchText>Emails</SwitchText>
                <SwitchComponent
                  name={'emailDashboardCompleted'}
                  checked={!!emailNotifications.emailDashboardCompleted}
                  onChange={(e) => handleSwitchChange('emailNotifications', 'emailDashboardCompleted', e.target.checked)}
                  onBlur={handleBlur}
                  errors={errors?.emailNotifications as string}
                  touched={!!touched.emailNotifications}
                  label={!!values?.emailNotifications?.emailDashboardCompleted ? 'Enabled' : 'Disabled'}
                  disableMargin
                />
              </SwitchWrapper>
              {
                !!values?.emailNotifications?.emailDashboardCompleted &&
                <>
                  <SubTitle>File Comments</SubTitle>
                  {emailNotificationSettings.map((setting) => (
                    <FlexBlock key={setting.name}>
                      <RadioButton
                        name={setting.name}
                        onChange={() => handleRadioButtonChange('emailNotifications', setting.name, setting.label)}
                        checked={setting?.checked!}
                        value={setting.name}
                      />
                      <SubTitleText $checked={setting.checked}>
                        {setting.label}
                      </SubTitleText>
                    </FlexBlock>
                  ))}
                  <SubTitle>Invites to Teams and Projects</SubTitle>
                  {invitesSettings.map((setting) => (
                    <FlexBlock key={setting.name}>
                      <RadioButton
                        name={setting.name}
                        onChange={() => handleRadioButtonChange('emailNotifications', setting.name, setting.label)}
                        checked={setting?.checked!}
                        value={setting.name}
                      />
                      <SubTitleText $checked={setting.checked}>
                        {setting.label}
                      </SubTitleText>
                    </FlexBlock>
                  ))}
                </>
              }
            </GapColumnBlock>
          </GapFlexRowBlock>
        </FullNameBlockWrapper>
      </EssentialsWrapper>
    </EssentialsBlock>
  );
};
