import styled, { css } from "styled-components";
import { ThreeDots, BarChartSteps, Globe } from "react-bootstrap-icons";

export const OptionsIcon = styled(ThreeDots)`
  width: 24px;
  height: 20px;
  color: ${({ theme }) => theme.neutralTextWeak};
  cursor: pointer;
`;

export const Dot = styled.div`
  display: flex;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-left: 5px;
  background: ${({ theme }) => theme.primaryBackgroundStrong || '#2D6E8D'};
`;

const activePage = css`
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  box-shadow: 1px 1px 1px rgba(99, 99, 99, 0.1);
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
  ${OptionsIcon} {
    display: block;
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid ${({ theme }) => theme.neutralBorder};
  }
  ${Dot}{
    margin-right: 8px;
  }
`;

const hoverStyle = css`
  background-color: ${({ theme }) => theme.neutralBackgroundHover};
  box-shadow: 1px 1px 1px rgba(99, 99, 99, 0.1);
  border-radius: 6px;
  border: 1px solid transparent;
`;

export const PageItem = styled.button<{
  $active?: boolean;
  $menuOpen?: boolean;
}>`
  all: unset;
  display: flex;
  position: relative;
  gap: 4px;
  height: 36px;
  width: 100%;
  align-items: center;
  padding: 0 10px;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-weight: 400;
  border: 1px solid transparent;
  ${OptionsIcon} {
    display: none;
  }

  ${({ $active }) => $active && activePage}
  ${({ $menuOpen }) => $menuOpen && hoverStyle}
  
  &:hover {
    ${hoverStyle}
  }
`;

export const PageItemIcon = styled(BarChartSteps)``;

export const PageName = styled.div`
  all: unset;
  gap: 4px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PageOptions = styled.div`
  display: flex;
`;

export const GlobeIcon = styled(Globe)`
  color: ${({ theme }) => theme.neutralTextDisabled};
`;