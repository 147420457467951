import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMeasure from "react-use-measure";
import { createPortal } from "react-dom";
import {
  NumberValue,
  axisBottom,
  axisLeft,
  scaleBand,
  scaleLinear,
  select,
  symbol,
  symbolCircle,
  scaleOrdinal,
  symbolSquare,
  symbolCross,
  symbolTriangle,
} from "d3";

import {
  HeaderWrapper,
  SVGStyled,
  Title,
} from "./styles";
import { DatavizRecommendedCount, DatavizSettingsIcon, HeadingNameAndButton, SettingsButtonWrapper } from "../styles";

import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { getPageSettings } from "../../../store/selectors/projects";
import { setCurrentWidget } from "../../../store/slices/projectPages";

import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleBandTickValues,
} from "../widgetHelpers";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend } from "../../ChartLegend";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColorV2 } from "../utils/getCurrentMarker";

export interface ScatterPlotProps {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
  preview?: boolean;
  isRight?: boolean;
}

const yAxisTickFormatter = (value: NumberValue): string =>
  Intl.NumberFormat("en-US", {
    notation: "compact",
  }).format(value as number);

const xAxisTickFormatter = (
  value: string,
  length: number = Infinity
): string => {
  const splitedValue = String(value).split("");

  return `${splitedValue?.slice(0, length).join("")}${splitedValue?.length < length ? "" : "..."
    }`;
};

export const ScatterPlot = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
  preview = false,
  isRight,
}: ScatterPlotProps) => {
  const dispatch = useDispatch();

  const svgRef = useRef<any>(null);
  const [refWidget, boundsWidget] = useMeasure({ scroll: true });
  const [measureRef, bounds] = useMeasure({ scroll: true });

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId } = useSelector(getPageSettings);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);

  const markerSize = 40; // is not not in px
  const margin = { top: 10, right: 5, bottom: 21, left: 40 };
  const width = bounds.width - margin.left - margin.right;
  const height = bounds.height - margin.top - margin.bottom;

  const chartSuggestion = useMemo(
    () =>
      aiSuggestions?.find(
        (chart: AiSuggestionsDto) => chart.chartType === "scatterplotChart"
      ),
    [aiSuggestions]
  );

  const chartData = useMemo(
    () => currentWidget?.data || [],
    [currentWidget?.data]
  );

  const groupBy = useMemo(() => {
    return currentWidget?.groupBy?.[0] || chartSuggestion?.groupBy;
  }, [currentWidget?.groupBy, chartSuggestion?.groupBy]);

  const xAxe = useMemo(() => {
    return currentWidget?.xAxe?.[0] || chartSuggestion?.xAxe?.[0];
  }, [currentWidget?.xAxe, chartSuggestion?.xAxe]);

  const yAxe = useMemo(() => {
    return currentWidget?.yAxe?.[0] || chartSuggestion?.yAxe?.[0];
  }, [currentWidget?.yAxe, chartSuggestion?.yAxe]);

  const xAxes: string[] = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      Array.from(new Set(chartData.map((d: any) => String(d[xAxe])))) ||
      []
    );
  }, [chartData, currentWidget?.uniqueValues, xAxe]);

  const groupByKey =
    groupBy ?? Object.keys(currentWidget?.uniqueValues || [])?.[0];

  const uniqueValues = useMemo(() => {
    return currentWidget?.uniqueValues?.[groupByKey!];
  }, [currentWidget?.uniqueValues, groupByKey]);

  const legendValues = useMemo(() => {
    return (uniqueValues || []).map((key) => ({
      label: key!,
      key: key!,
      color: getCurrentColorV2(currentWidget, key, styleId),
    }));
  }, [uniqueValues, currentWidget, styleId]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  //* Scales
  const xScale = useMemo(() => {
    return scaleBand<string>()
      .domain(xAxes)
      .range([0, width])
      .paddingOuter(-0.5);
  }, [xAxes, width]);

  const yScale = useMemo(() => {
    return scaleLinear<number, number>()
      .domain([0, Math.max(...chartData.map((d: any) => d[yAxe])) || 0])
      .range([height, margin.top])
      .nice();
  }, [chartData, height, margin.top, yAxe]);
  const colorScale = useMemo(() => {
    const defaultColor = getCurrentColorV2(currentWidget, "default", styleId);
    const colors = groupBy.length
      ? legendValues.map((item) => item.color)
      : [defaultColor];

    return scaleOrdinal<string, string>()
      .domain(legendValues.map((item) => item.key))
      .range(colors)
      .unknown(defaultColor);
  }, [legendValues, groupBy, currentWidget, styleId]);

  //* Marker
  const markersTypes = useMemo(
    () => currentWidget?.markers || [],
    [currentWidget?.markers]
  );

  const getMarkerType = useCallback(
    (key: string) =>
      String(markersTypes.find((item) => item.key === key)?.shape ?? ""),
    [markersTypes]
  );

  const getMarkerPath = (markerType: string) => {
    const markerTypesMap: { [key: string]: any } = {
      cross: symbolCross,
      circle: symbolCircle,
      donut: symbolCircle,
      square: symbolSquare,
      rhombus: symbolSquare,
      triangle: symbolTriangle,
    };

    return symbol().type(markerTypesMap[markerType] || symbolCircle);
  };

  const getMarkerRotation = (markerType: string): string => {
    const rotationMap: { [key: string]: number } = {
      rhombus: 45,
      cross: 45,
    };

    return `rotate(${rotationMap[markerType] || 0})`;
  };

  const getMarkerStrokeWidth = (markerType: string): number => {
    return markerType === "donut" ? 1.5 : 0;
  };

  const xScaleTickValues = useMemo(() => {
    const xScaleNumTicksCalculated = calculateNumTicks({ width });

    const isReasonableAmountOfTicks =
      xScaleNumTicksCalculated <= xAxes.length &&
      xScaleNumTicksCalculated > 0 &&
      xAxes.length / xScaleNumTicksCalculated >= 1.5;

    const xScaleNumTicks = isReasonableAmountOfTicks
      ? xScaleNumTicksCalculated
      : xAxes.length;

    return getScaleBandTickValues({
      tickCount: xScaleNumTicks,
      ticks: xAxes,
    });
  }, [width, xAxes]);

  const xScaleTickLabelMaxLength = useMemo(
    () =>
      calculateLabelLength({
        width: width - margin.left - margin.right,
        tickValues: xScaleTickValues,
        tickFormatter: xAxisTickFormatter,
      }),
    [margin.left, margin.right, width, xScaleTickValues]
  );

  //* Events Handlers
  const handleMouseMove = useCallback(
    (event: any, datum: any) => {
      if (currentWidget.tooltip && !recommended) {
        const { pageX, pageY, clientX, clientY } = event;
        const coords = { pageX, pageY, clientX, clientY };

        setTooltip({
          name: datum[groupByKey],
          data: {
            [xAxe]: groupBy ? String(datum[xAxe]) : "",
            [yAxe]: String(datum[yAxe]),
          },
          coords,
        });
      }
    },
    [
      currentWidget.tooltip,
      groupBy,
      groupByKey,
      recommended,
      xAxe,
      yAxe,
    ]
  );

  const handleMouseLeave = useCallback(() => {
    if (currentWidget.tooltip) {
      setTooltip(null);
    }
  }, [currentWidget.tooltip]);

  const handleMouseOver = useCallback(
    function (self: any, svg: any) {
      if (!currentWidget.tooltip) {
        return;
      }

      svg
        .selectAll(".scatterplot-marker-container")
        .transition()
        .duration(200)
        .attr("opacity", ".2");

      select(self).transition().duration(200).attr("opacity", "1");
    },
    [currentWidget.tooltip]
  );

  const handleMouseOut = useCallback(
    function (svg: any) {
      if (!currentWidget.tooltip) {
        return;
      }

      svg
        .selectAll(".scatterplot-marker-container")
        .transition()
        .duration(200)
        .attr("opacity", "1");
    },
    [currentWidget.tooltip]
  );

  //* Chart
  const svgContainer = select(svgRef.current);

  useEffect(() => {
    if (svgRef.current) {
      svgRef.current.innerHTML = "";
    }

    if (!bounds.width || !bounds.height || !chartData.length) {
      return;
    }

    const svg = svgContainer
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    //* yGrid
    svg
      .append("g")
      .attr("class", "y-grid")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(-width)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* y-axis
    svg
      .append("g")
      .attr("class", "y-axis")
      .call(
        axisLeft(yScale)
          .ticks(numTicks)
          .tickSize(0)
          .tickPadding(8)
          .tickFormat(yAxisTickFormatter)
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("text")
      .attr("dx", `-${margin.left - 10}px`)
      .attr("fill", "#5f6877")
      .attr("font-size", "11px")
      .attr("text-anchor", "start");

    // y-axis tick lines
    svg
      .selectAll(".y-axis .tick")
      .append("line")
      .attr("class", "tick-line")
      .call((g) => g.select(".domain").remove())
      .attr("x1", -8)
      .attr("x2", 0)
      .attr("y1", 0)
      .attr("y2", 0)
      .attr("stroke", "#939ba7")
      .attr("stroke-width", "1px");

    //* xGrid
    svg
      .append("g")
      .attr("class", "x-grid")
      .call(
        axisBottom(xScale)
          .tickSize(height)
          .tickFormat(() => "")
      )
      .call((g) => g.select(".domain").remove())
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "1,2")
      .attr("stroke-width", "1px");

    //* x-axis
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(
        axisBottom(xScale)
          .tickSizeOuter(0)
          .tickSize(0)
          .tickPadding(12)
          .tickValues(xScaleTickValues)
          .tickFormat((s: string) =>
            xAxisTickFormatter(s, xScaleTickLabelMaxLength)
          )
      )
      .call((g) => g.select(".domain").attr("stroke", "#939ba7"))
      .selectAll("text")
      .attr("fill", "#5f6877")
      .attr("font-size", "11px");

    // * DataViz
    // Scatter Markers
    svg
      .append("g")
      .selectAll("path")
      .attr("class", "scatterplot-marker-group")
      .data(chartData)
      .join("g")
      .attr("class", "scatterplot-marker-container")
      .each(function (d: any) {
        const markerContainer = select(this);
        const markerType = getMarkerType(groupBy ? String(d[groupByKey]) : "default");
        markerContainer
          .append("path")
          .attr("class", "scatterplot-marker")
          .attr("d", getMarkerPath(markerType).size(markerSize))
          .attr(
            "transform",
            (d: any) =>
              `translate(${(xScale(String(d[xAxe])) || 0) + xScale.bandwidth() / 2
              },${yScale(d[yAxe])}) ${getMarkerRotation(markerType)}`
          )
          .attr("fill", (d: any) => {
            return markerType === "donut" ? "none" : colorScale(String(d[groupByKey]));
          })
          .attr("stroke", (d: any) => colorScale(String(d[groupByKey])))
          .attr("stroke-width", getMarkerStrokeWidth(markerType));
      })
      .on("mouseover", function () {
        handleMouseOver(this, svg);
      })
      .on("mouseout", () => {
        handleMouseOut(svg);
      })
      .on("mousemove", handleMouseMove)
      .on("mouseleave", handleMouseLeave);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, width, height]);

  if (!Object.keys(chartData).length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <></>}
            {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {legendValues?.length > 1 &&
          groupBy &&
          showLegend &&
          currentWidget?.legend && (
            <ChartLegend
              chartWidth={boundsWidget.width}
              legendType="unit"
              legendValues={legendValues}
              isRight={isRight}
            />
          )}
      </HeaderWrapper>

      <SVGStyled
        ref={(node) => {
          svgRef.current = node;
          measureRef(node);
        }}
        width="100%"
        height="100%"
      ></SVGStyled>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
            coords={tooltip.coords}
          />,
          document.body
        )}
    </>
  );
};
