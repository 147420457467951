import styled from "styled-components";

import { fadeInDown } from "../../../styles";
import {
  GearWideConnected,
  ExclamationCircleFill,
} from "react-bootstrap-icons";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.neutralBackgroundActive};
  display: flex;
  align-items: center;
  z-index: 1100;
  opacity: 0;
  animation: 0.4s ${fadeInDown} ease forwards;
`;

export const ModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  border-radius: 12px;
  padding: 20px 20px 12px 20px;
  height: 97%;
  position: absolute;
  right: 16px;
  left: 16px;
  display: flex;
  flex-direction: column;
`;

export const ModalHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ModalHeadingNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 0 20px;
`;
export const ModalHeading = styled.h2`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`;

export const Separator = styled.div<{
  $top?: boolean;
  $bottom?: boolean;
}>`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.neutralBorder};
  position: absolute;
  top: ${({ $top }) => $top && "68px"};
  bottom: ${({ $bottom }) => $bottom && "60px"};
  left: 0;
`;
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  gap: 20px;
  margin-top: 20px;
  overflow: auto;
  position: relative;
`;

export const Table = styled.div`
  position: relative;
  display: flex;
  gap: 9px;
`;

export const SettingsIcon = styled(GearWideConnected)`
  display: none;
`;

export const TableColumn = styled.div<{
  $errorDetected?: boolean;
  $selected?: boolean;
}>`
  display: flex;
  width: 200px;
  flex-direction: column;
  gap: 4px;
  border: 1px dashed rgba(255, 255, 255, 0);
  border: 1px solid transparent;

  &:hover {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
  ${({ $selected, theme }) =>
    $selected
      ? `
    background: ${theme.primaryBackgroundMedium};
    border-radius: 8px;
  `
      : ``}
  padding: 5px;
  &:hover {
    ${SettingsIcon} {
      display: block;
      cursor: pointer;
    }
  }
  ${({ $errorDetected, theme }) =>
    $errorDetected &&
    `
  border: 1px dashed ${theme.alertBackgroundStrong} !important ;
  border-radius: 10px;
  align-self: flex-start;
  margin: 0 4px;
  
    ${SettingsIcon} {
      display: block;
      cursor: pointer;
    }
    
    &:hover {
      border: 1px solid ${theme.alertBackgroundStrong} !important ;
    }

    }
  `}
`;

export const TableHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const TableHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralTextWeak};
  padding: 6px 8px 6px 8px;
  overflow: hidden;
  max-width: 170px;
`;

export const Header = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const TableRow = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  background: ${({ theme }) => theme.neutralBackground};
  border-radius: 6px;
  padding: 6px 8px 6px 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const HeadingIcon = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
`;

export const HeadingText = styled.div`
  font-size: 14px;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Icon = styled.img``;

export const ErrorIcon = styled(ExclamationCircleFill)`
  position: absolute;
  top: -4px;
  right: -4px;
  fill: #c53660;
`;

export const TotalFields = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 5px;
  line-height: 14px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primaryBackground};
  color: ${({ theme }) => theme.primaryText};
  font-size: 12px;
  font-weight: 500;
`;

export const DatasetOverviewLoaderWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
`;
