import styled from "styled-components";
import { X, ChevronDown } from "react-bootstrap-icons";

//SelectContainer

export const Container = styled.div<{
  $width?: string;
}>`
  width: ${({ $width }) => $width || "100%"};
  position: relative;
`;

export const SelectContainer = styled.div<{ $opened?: boolean; $hasLabel?: boolean; $disabled: boolean }>`
  transition: .15s all linear;
  width: 100%;
  user-select: none;
  cursor: ${({ $disabled }) => $disabled ? "not-allowed" : "pointer"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ $disabled, theme }) => $disabled ? theme.neutralBackgroundMedium : 'inherit'};
  color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.neutralText};
  span {
    color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.neutralTextWeak};
  }
`;

export const SelectedValue = styled.p<{ $placeholder?: boolean; $hasLabel?: boolean; $activeDropDown?:boolean; }>`
  color: ${({ $placeholder, theme }) => !$placeholder ? theme.neutralTextWeak : theme.neutralText};
  font-size: 14px;
  font-weight: ${({ $activeDropDown }) => $activeDropDown ? '500' : '400'};
  width: 100%;
  padding: 0 4px;
  gap: 4px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const DownIcon = styled(ChevronDown) <{ $close?: boolean; }>`
  transition: 0.2s;
  transform: rotateX(${({ $close }) => ($close ? "180deg" : "0deg")});
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
`;

export const CloseIcon = styled(X)`
  padding-right: 8px;
  width: 27px;
  height: 20px;
`;

export const VerticalLine = styled.div<{ $opened?: boolean; }>`
  display: flex;
  width: 1px;
  background: ${({ theme }) => theme.neutralBorder};
  transition: .15s all linear;
  height: ${({ $opened }) => $opened ? '38px' : '16px'};
`;

export const DropDownSelect = styled.div<{ $width?: number; }>`
  position: fixed;
  margin-top: 4px;
  padding: 8px;
  width: ${({ $width }) => `${$width}px` || "100%"};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  z-index: 1003;
  max-height: 400px;
  overflow: auto;
`;

export const DropDownMenuList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1px;
  min-width: 200px;
`;
export const DropDownMenuItem = styled.li<{ $selected?: boolean; }>`
  flex-direction: column;
  white-space: nowrap;
  border-radius: 4px;
  line-height: 16px;
  transition: 0.2s;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  column-gap:4px;
  display: flex;
  padding: 8px;
  row-gap: 2px;
  background: ${({ $selected, theme }) => $selected && theme.neutralBackground};

  &:hover {
    background: ${({ $selected, theme }) => $selected ? theme.neutralBackgroundHover : theme.neutralBackground};
  }

  &:active {
    background: ${({ theme }) => theme.neutralBackgroundActive};
  }

  span:nth-child(1) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    ${({ $selected}) => $selected && `
      font-weight: 500;
    `}
    color: ${({ theme }) => theme.neutralText};
  }
  span:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 4px;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-top: 5px;
  margin-left: 7px;
`;