import { useEffect, useMemo } from "react";

import { PageMenu } from "../../components/PageMenu";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import UserBg from "../../assets/userBg.jpg";
import {
  AccountAvatarText,
  AccountAvatarWithBg,
  ProfilePageWrapper,
  UserAvatar,
  UserProfileHeaderUserDescription,
  UserProfileHeaderUserDetails,
  UserProfileHeaderUserName,
  UserProfileHeaderUserRoleBadge,
  UserProfileHeaderWrapper,
} from "./styles";
import { ProfileSideBar } from "../../components/ProfileSideBar";
import { UserProfileProjects } from "../../components/UserProfileProjects";
import { useSelector } from "react-redux";
import { getProfiles } from "../../store/selectors/profiles";
import { requestProfile } from "../../store/slices/profiles";
import { useDispatch } from "react-redux";

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfiles);

  const { firstName = '', lastName = '', jobTitle = '', isAdmin = false } = profile || {};

  useEffect(() => {
    dispatch(requestProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initials = useMemo(() => {
    if (lastName || firstName) {
      return `${firstName?.charAt(0) || ''} ${lastName?.charAt(0) || ''}`;
    }
    return "";
  }, [firstName, lastName]);

  const fullName = useMemo(() => {
    if (lastName || firstName) {
      return `${firstName || ''} ${lastName || ''}`;
    }
    return "";
  }, [firstName, lastName]);

  return (
    <>
      <HeadingMenuContainer>
        <UserProfileHeaderWrapper>
          <AccountAvatarWithBg>
            <UserAvatar src={UserBg} />
            <AccountAvatarText>{initials}</AccountAvatarText>
          </AccountAvatarWithBg>
          <UserProfileHeaderUserDetails>
            <UserProfileHeaderUserName>{fullName}</UserProfileHeaderUserName>
            <UserProfileHeaderUserDescription>
              {jobTitle || ''}
              {isAdmin && (
                <UserProfileHeaderUserRoleBadge>
                  Admin
                </UserProfileHeaderUserRoleBadge>
              )}
            </UserProfileHeaderUserDescription>
          </UserProfileHeaderUserDetails>
        </UserProfileHeaderWrapper>
        <PageMenu />
      </HeadingMenuContainer>
      <ProfilePageWrapper>
        <ProfileSideBar />
        <UserProfileProjects />
      </ProfilePageWrapper>
    </>
  );
};
