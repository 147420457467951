import React from "react";

import {
  ActiveRadioButton,
  HoveredRadioButton,
  InactiveRadioButton,
  ProjectTypeDescription,
  ProjectTypeDescriptionWrapper,
  ProjectTypeIconWrapper,
  ProjectTypeItem,
  ProjectTypeTitle,
  ProjectTypeWrapper,
  RadioButtonWrapper,
  StorytellingIcon,
} from "../styles";
import { ComponentSettings } from "../../../../models/Pages";
import { DashboardImg } from "../../../../assets/Icons/DashboardImg";
import { StoryTellingImg } from "../../../../assets/Icons/StorytellingImg";

export const ProjectType = ({
  settings,
  onUpdateSettings,
}: ComponentSettings) => {
  const handleItemClick = (item: "dashboard" | "storytelling") => {
    onUpdateSettings({
      ...settings,
      dashType: item,
      templateId:
        item === "storytelling"
          ? "default"
          : settings.templateId === "default"
          ? ""
          : settings.templateId,
    });
  };
  return (
    <ProjectTypeWrapper>
      <ProjectTypeItem
        onClick={() => handleItemClick("dashboard")}
        $selectedItem={settings.dashType === "dashboard"}
      >
        <ProjectTypeIconWrapper>
          <DashboardImg />
          <RadioButtonWrapper>
            {settings.dashType === "dashboard" ? (
              <ActiveRadioButton />
            ) : (
              <>
                <HoveredRadioButton />
                <InactiveRadioButton />
              </>
            )}
          </RadioButtonWrapper>
        </ProjectTypeIconWrapper>
        <ProjectTypeDescriptionWrapper>
          <ProjectTypeTitle>Default Dashboard</ProjectTypeTitle>
          <ProjectTypeDescription>
            Analyse data at a high-level view in a intuitive and digestible
            format to quickly grasp complex information and take appropriate
            actions as needed.
          </ProjectTypeDescription>
        </ProjectTypeDescriptionWrapper>
      </ProjectTypeItem>
      <ProjectTypeItem
        onClick={() => {
          handleItemClick("storytelling");
        }}
        $selectedItem={settings.dashType === "storytelling"}
      >
        <ProjectTypeIconWrapper>
          <StoryTellingImg />
          <RadioButtonWrapper>
            {settings.dashType === "storytelling" ? (
              <ActiveRadioButton />
            ) : (
              <>
                <HoveredRadioButton />
                <InactiveRadioButton />
              </>
            )}
          </RadioButtonWrapper>
        </ProjectTypeIconWrapper>
        <ProjectTypeDescriptionWrapper>
          <ProjectTypeTitle>Storytelling Dashboard</ProjectTypeTitle>
          <ProjectTypeDescription>
            A storytelling dashboard is a specialized type of dashboard designed
            to present data and information in a narrative or storytelling
            format.
          </ProjectTypeDescription>
        </ProjectTypeDescriptionWrapper>
      </ProjectTypeItem>
    </ProjectTypeWrapper>
  );
};
