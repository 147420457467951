import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { ReactComponent as CoversIcon } from "../../../assets/covers.svg";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";

import { useDispatch, useSelector } from "react-redux";
import { getPageSettings } from "../../../store/selectors/projects";
import { Content, ContentText } from "./style";
import { requestUpdatePage } from "../../../store/slices/projectPages";

type ModalProps = {
  onClose: () => void;
  pageIdSelected?: string | null;
};

export const ReportConfirm = ({ onClose }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const currentPage = useSelector(getPageSettings);

  const dispatch = useDispatch();

  const handleOnClose = () => {
    setIsClosing(true);
    onClose();
  };

  const handleSubmit = () => {
    dispatch(
      requestUpdatePage({
        ...currentPage,
        showHeader: false,
        callbacks: {
          onSuccess: () => {
            onClose();
          },
        },
      })
    );
  };

  return (
    <ModalOverlay
      $centred={true}
      $isClosing={isClosing}
      onClick={handleOnClose}
    >
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $centred={true}
      >
        <ModalHeadingWrapper $disableSeparator={true}>
          <ModalContainer>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <ModalContent>
              <Content>
                <CoversIcon />
                <ContentText>
                  <h4>Are you sure you want to remove the cover?</h4>
                  <p>
                    By removing one, <b>you'll remove both</b>
                    <br />
                    the front and back covers.
                  </p>
                </ContentText>
              </Content>
            </ModalContent>
            <ModalFooterWrapper>
              <Button
                name="Cancel"
                onClick={handleOnClose}
                variant="neutral"
                size="medium"
              />
              <Actions>
                <Button
                  name="Remove Covers"
                  onClick={handleSubmit}
                  variant="dangerInvert"
                  size="medium"
                  icon={<Trash />}
                />
              </Actions>
            </ModalFooterWrapper>
          </ModalContent>
        </OverviewContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};
