import styled from "styled-components";
import { fadeInDown } from "../../styles";

export const DropDownWrapper = styled.div`
  position: absolute;
  right:0;
  top: 30px;
  width: 184px;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  padding: 8px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: 0.3s ${fadeInDown} ease forwards;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0 5px 0;
`;

export const SectionWrapper = styled.div``;

export const SectionName = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralTextWeak};
  padding: 0 12px 4px 4px;
`;
export const SectionItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SectionItem = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 4px 8px 8px;
  height: 32px;
  width: 168px;
  border-radius: 4px;
  background-color: ${({ theme, $active }) =>
    $active && theme.neutralBackground};
  color: ${({ theme }) => theme.neutralText};
  font-size: 14px;
  font-weight: ${({ $active }) => $active && 500};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }
  &::after {
    display: ${({$active}) => $active?'block':'none'};
    content: ".";
    margin-left: auto;
    position: relative;
    top: -7px;
    margin-right:5px;
    color: ${({ theme }) => theme.primaryText};
    font-size: 25px;
  }
`;
