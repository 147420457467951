import styled from "styled-components";
import {
  PlusLg,
  GearWideConnected,
  Dot,
  Stickies,
  Download,
} from "react-bootstrap-icons";

export const StaticWrapper = styled.div`
  height: 72px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 72px;
  width: 100%;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  justify-content: space-between;
  width: calc(100% - 40px);
  position: fixed;
  z-index: 999;
`;

export const PageItemsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const PagesSection = styled.div`
  display: flex;
  align-items: center;
`;

export const FeaturesSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AddNewPageWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  width: 36px;
  height: 36px;
  justify-content: center;
  place-items: center;
  align-self: center;
  cursor: pointer;
  color: ${({ theme }) => theme.neutralTextWeak};
  transition: 0.15s;

  &:hover {
    border: none;
    border-radius: 6px;
    width: 36px;
    height: 36px;
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
    justify-content: center;
    place-items: center;
    align-self: center;
    color: ${({ theme }) => theme.neutralText};
  }
`;

export const AddNewPage = styled(PlusLg)``;

export const SettingsBlock = styled.button`
  all: unset;
  background-color: rgba(95, 104, 119, 0.08);
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
`;

export const FileBlock = styled.button`
  all: unset;
  background-color: ${({ theme }) => theme.primaryBackground};
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  transition: 0.3s ease-in-out;
  svg {
    fill: ${({ theme }) => theme.primaryText};
    transition: 0.3s ease-in-out;
  }

  &:hover {
    background-color: ${({ theme }) => theme.primaryBackgroundMedium};
    svg {
      fill: ${({ theme }) => theme.primaryTextHover};
    }
  }
  &:active {
    background-color: ${({ theme }) => theme.primaryBackgroundActive};
    svg {
      fill: ${({ theme }) => theme.primaryTextHover};
    }
  }
  &:disabled {
    background-color: ${({ theme }) => theme.neutralBackgroundMedium};
    cursor: not-allowed;
    svg {
      fill: ${({ theme }) => theme.neutralTextDisabled};
    }
  }
`;

export const NeutralButtonBlock = styled.button`
  all: unset;
  background-color: ${({ theme }) => theme.neutralBackground};
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  height: 40px;
  width: 40px;
  transition: 0.3s ease-in-out;

  svg {
    transition: 0.3s ease-in-out;
  }

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
    svg {
      fill: ${({ theme }) => theme.neutralText};
    }
  }
  &:active {
    background-color: ${({ theme }) => theme.neutralBackgroundActive};
    svg {
      fill: ${({ theme }) => theme.neutralText};
    }
  }
  &:disabled {
    background-color: ${({ theme }) => theme.neutralBackgroundMedium};
    cursor: not-allowed;
  }
`;

export const DotSeparator = styled(Dot)<{ $ml?: string }>`
  margin-left: ${({ $ml }) => $ml || "0"};
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.neutralTextWeak};
`;

export const Settings = styled(GearWideConnected)`
  fill: #9fa4ad;
`;

export const FileIcon = styled(Stickies)`
  fill: #9fa4ad;
`;

export const DownloadIcon = styled(Download)`
  fill: #9fa4ad;
`;

export const RecommendedWidgetsWrapper = styled.div`
  background: ${({ theme }) => theme.neutralBackground};
  display: flex;
  align-items: center;
  height: 40px;
  gap: 8px;
  /* border: 1px solid ${({ theme }) => theme.neutralBorder}; */
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
`;

export const WidgetsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.primaryTextStrong};
  background-color: ${({ theme }) => theme.primaryAction};
  color: white;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

export const WidgetsTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralTextWeak};
`;
