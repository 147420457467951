import React from "react";
import { Chip, DateSpan, TableCell, TableRow, TimeSpan } from "./styles";
import { ThreeDots } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

type Props = {
  id: string;
  name: string;
  description: string;
  pages: number;
  role: string;
  team: string[];
  updatedOn: string;
};

const ProjectRow: React.FC<Props> = ({
  id,
  name,
  description,
  pages,
  role,
  team,
  updatedOn,
}) => {
  const [date, time, meridian] = updatedOn.split(/[\s]+/);
  const navigate = useNavigate();
  return (
    <TableRow onClick={() => navigate("/projects/" + id)}>
      <TableCell $width={236}>{name}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell $width={96}>{pages}</TableCell>
      <TableCell $width={120}>
        <Chip $role={role}>{role}</Chip>
      </TableCell>
      <TableCell $width={200}>
        <Chip>{team[0]}</Chip>
      </TableCell>
      <TableCell $width={160}>
        <DateSpan>{date}</DateSpan>
        <TimeSpan>{`${time} ${meridian}`}</TimeSpan>
      </TableCell>
      <TableCell $width={32}>
        <ThreeDots />
      </TableCell>
    </TableRow>
  );
};

export default ProjectRow;
