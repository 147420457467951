import { styled } from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  width: 100%;
`;

export const TextInput = styled.textarea<{ $isReply: boolean }>`
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  outline: none;
  width: 100%;
  ${({ $isReply }) => !$isReply && "margin-bottom: 8px;"}
  resize: none;
  color: ${({ theme }) => theme.neutralText};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  
  &:hover:not(:focus) {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`;

export const Control = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const IconWrapper = styled.div`
  margin-right: 12px;
  font-size: 18px;
  color: #9ca3af;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
