import React, { useEffect, useMemo, useState } from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarSectionWrapper,
  ProfileSideBarHeading,
  Chip,
  ChipsWrapper,
  InvitedBy,
} from "./styles";
import { People, Compass, Send, Calendar3 } from "react-bootstrap-icons";
import { AccountDto } from "../../models/Authentication";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { getUser } from "../../store/selectors/main";
import { IProfile } from "../../types/profile";
import { getProfiles } from "../../store/selectors/profiles";

const aboutSection = (profile: IProfile) => [
  {
    icon: <People />,
    type: "Departament",
    data: profile?.organization
  },
  {
    icon: <Compass />,
    type: "Location",
    data: profile?.location
  },
  {
    icon: <People />,
    type: "Teams",
    data: <ChipsWrapper>
      <Chip $selected={false}>Administratives</Chip>
      <Chip $selected={false}>IT and Technical</Chip>
    </ChipsWrapper>
  },
  {
    icon: <Calendar3 />,
    type: "Added on",
    data: "12.12.2024"
  },
  {
    icon: <Send />,
    type: "Invited by",
    data: <InvitedBy>Jenny Wilson</InvitedBy>
  },
];

export const AboutSection = () => {
  const profile = useSelector(getProfiles);

  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>About</ProfileSideBarHeading>
      <ProfileSideBarSectionWrapper>
        {aboutSection(profile).map((section, idx) => (
          <ProfileSideBarAboutCardWrapper key={idx}>
            <ProfileSideBarAboutCardIcon>
              {section.icon}
            </ProfileSideBarAboutCardIcon>
            <ProfileSideBarAboutCardDetailsWrapper>
              <ProfileSideBarAboutCardTitle>
                {section.type}
              </ProfileSideBarAboutCardTitle>
              <ProfileSideBarAboutCardDescription $noData={!section.data}>
                {section.data ? section.data : "No data"}
              </ProfileSideBarAboutCardDescription>
            </ProfileSideBarAboutCardDetailsWrapper>
          </ProfileSideBarAboutCardWrapper>
        ))}
      </ProfileSideBarSectionWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
