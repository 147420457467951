import { useEffect, useMemo, useState } from "react";
import { AreaClosed, LinePath } from "@visx/shape";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { Group } from "@visx/group";
import useMeasure from "react-use-measure";
import { useDispatch, useSelector } from "react-redux";
import { curveLinear } from "d3";

import {
  HeaderWrapper,
  HeadingNameAndButton,
  Title,
} from "../VerticalBarchart/styles";
import { setActiveModal } from "../../../store/slices/modals";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleBandTickValues,
} from "../widgetHelpers";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { ChartLegend, ChartLegendValue } from "../../ChartLegend";
import { getPageSettings } from "../../../store/selectors/projects";
import { hexToRGBA } from "../../../helpers/hexToRgba";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { ticksFormatter } from "../../../helpers/ticksFormatter";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { LineChartMarker } from "../utils/getMarker";
import { AreaChartGroupedData } from "./utils/getGroupData";
import { getCurrentColorV2, getCurrentMarker } from "../utils/getCurrentMarker";
import { createPortal } from "react-dom";
import { TickLabel } from "../components/LabelTooltip";
import { SettingsButtonWrapper, DatavizRecommendedCount, DatavizSettingsIcon } from "../styles";

export const AreaChart = ({
  currentWidget,
  recommended,
  storytelling,
  showLegend = true,
  selected = false,
  hideName = false,
  preview = false,
  isRight,
}: {
  currentWidget: WidgetItem;
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  preview?: boolean;
  isRight?: boolean;
}) => {
  const isEditMode = useSelector(getIsEditMode);
  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const dispatch = useDispatch();
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { styleId } = useSelector(getPageSettings);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);
  const [xAxes, setXAxes] = useState<string[]>([]);
  const [yAxes, setYAxes] = useState<number[]>([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [legendValues, setLegendValues] = useState<ChartLegendValue[]>([]);
  const [groupedData, setGroupedData] = useState<{
    [key: string]: { x: string; y: number }[];
  }>({});
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [groupByKey, setGroupByKey] = useState<string | undefined>();

  const margin = { top: 10, right: 5, bottom: 21, left: 40 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const xScale = scaleBand({
    domain: xAxes,
    range: [margin.left, width - margin.right],
    padding: -1,
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...yAxes)],
    nice: true,
    range: [height - margin.bottom, margin.top],
  });

  useEffect(() => {
    const areaChartSuggestion = aiSuggestions?.find(
      (chart: AiSuggestionsDto) => chart.chartType === "areaChart"
    );

    if (currentWidget) {
      const data = currentWidget.data || [];

      const xAxe = currentWidget?.xAxe?.length
        ? currentWidget?.xAxe?.at(0)
        : areaChartSuggestion?.xAxe?.at(0);
      setXAxe(xAxe);

      const yAxe = currentWidget?.yAxe?.length
        ? currentWidget?.yAxe?.at(0)
        : areaChartSuggestion?.yAxe?.at(0);
      setYAxe(yAxe);

      const xAxes =
        currentWidget?.uniqueValues?.[xAxe] ||
        Array.from(new Set(data.map((d: any) => String(d[xAxe])))) ||
        [];
      setXAxes(xAxes);

      const yAxes =
        (currentWidget.data?.map((l: any) => l[yAxe]) || [])?.reduce(
          (t: any, l: string) => {
            const y = parseInt(l);
            if (typeof y === "number" && !isNaN(y)) {
              return [...t, y];
            }
            return t;
          },
          []
        ) || [];
      setYAxes(yAxes);

      const groupedData = AreaChartGroupedData(currentWidget);
      setGroupedData(groupedData);

      const groupBy = currentWidget?.groupBy?.at(0);
      if (groupBy) {
        const newLegendValues = [];

        const uniqueValuesKeys =
          (currentWidget?.uniqueValues &&
            Object.keys(currentWidget?.uniqueValues!)) ||
          [];
        const groupByKey =
          groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

        const uniqueValues =
          uniqueValuesKeys?.length && currentWidget?.uniqueValues
            ? currentWidget?.uniqueValues[groupByKey!]
            : Object.keys(groupedData);

        if (groupedData && uniqueValues?.length) {
          for (let i = 0; i < uniqueValues?.length; i++) {
            const dataKey = uniqueValues[i];
            const color = getCurrentColorV2(currentWidget, dataKey, styleId);
            newLegendValues.push({ label: dataKey!, key: dataKey!, color });
          }
        }

        setGroupByKey(groupByKey);
        setLegendValues(newLegendValues);
      }
    }
  }, [aiSuggestions, currentWidget, styleId]);

  const yScaleNumTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  const xScaleTickValues = useMemo(() => {
    const xScaleNumTicksCalculated = calculateNumTicks({ width });

    const isReasonableAmountOfTicks =
      xScaleNumTicksCalculated <= xAxes.length &&
      xScaleNumTicksCalculated > 0 &&
      xAxes.length / xScaleNumTicksCalculated >= 1.5;

    const xScaleNumTicks = isReasonableAmountOfTicks
      ? xScaleNumTicksCalculated
      : xAxes.length;

    return getScaleBandTickValues({
      tickCount: xScaleNumTicks,
      ticks: xAxes,
    });
  }, [width, xAxes]);

  const xScaleTickLabelMaxLength = calculateLabelLength({
    width: width - margin.left - margin.right,
    tickValues: xScaleTickValues,
  });

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  if (Object.keys(groupedData).length === 0) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <></>}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}

        {legendValues?.length > 1 &&
          groupByKey &&
          showLegend &&
          currentWidget?.legend && (
            <ChartLegend
              chartWidth={boundsWidget.width}
              legendType="unit"
              legendValues={legendValues}
              isRight={isRight}
            />
          )}
      </HeaderWrapper>
      <svg width="100%" height={"100%"} ref={ref}>
        <Group>
          {xScale.domain().map((value) => {
            return (
              <line
                key={`${value}`}
                x1={xScale(value)! + xScale.bandwidth() / 2}
                y1={margin.top}
                x2={xScale(value)! + xScale.bandwidth() / 2}
                y2={height - margin.bottom}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
            );
          })}
        </Group>
        <Group>
          {yScale.ticks(yScaleNumTicks).map((value) => (
            <line
              key={value}
              x1={margin.left}
              y1={yScale(value)}
              x2={width - margin.right}
              y2={yScale(value)}
              stroke="#ccc"
              strokeDasharray="1 2"
            />
          ))}
          <line
            x1={width - margin.right}
            y1={margin.top}
            x2={width - margin.right}
            y2={height - margin.bottom}
            stroke="#ccc"
            strokeDasharray="1, 2"
          />
        </Group>
        <AxisBottom
          top={height - margin.bottom}
          scale={xScale}
          hideTicks
          tickFormat={(tick) => tick.toString()}
          axisLineClassName="barchartAxisLine"
          tickLabelProps={(_, index, values) => {
            const isFirstTick = index === 0;
            const isLastTick = index === values.length - 1;
            const textAnchor =
              (isFirstTick && "start") || (isLastTick && "end") || "middle";

            return {
              fontSize: 11,
              fill: "#5F6877",
              textAnchor: textAnchor,
            };
          }}
          tickValues={xScaleTickValues}
          tickComponent={(props: any) => (
            <TickLabel
              {...props}
              length={xScaleTickLabelMaxLength}
              offsetX={-10}
            />
          )}
        />
        <AxisLeft
          left={margin.left}
          scale={yScale}
          stroke="#ccc"
          strokeDasharray="1,2"
          tickLineProps={{
            stroke: "#939BA7",
          }}
          numTicks={yScaleNumTicks}
          tickLabelProps={() => ({
            fontSize: 11,
            fill: "#5F6877",
            textAnchor: "start",
            dy: 4,
            dx: -30,
          })}
          tickFormat={(value: any) => {
            return ticksFormatter(value);
          }}
        />
        <Group>
          {groupedData &&
            Object.keys(groupedData)?.map((key) => {
              const area = groupedData[key];

              return (
                <AreaClosed
                  style={{ transition: "0.3s" }}
                  key={key}
                  data={area?.map((d: { x: string; y: number }) => {
                    return {
                      x: xScale(d.x)! + xScale.bandwidth() / 2,
                      y0: yScale(0)!,
                      y1: yScale(d.y)!,
                    };
                  })}
                  x={(d: any) => d.x}
                  y0={(d: any) => d.y0}
                  y1={(d: any) => d.y1}
                  yScale={yScale}
                  fill={hexToRGBA(
                    getCurrentColorV2(currentWidget, key),
                    hoveredElement ? 0.1 : 0.3
                  )}
                />
              );
            })}
        </Group>
        <Group>
          {groupedData &&
            Object.keys(groupedData)?.map((key) => {
              const area = groupedData[key];

              return (
                <LinePath
                  key={key}
                  style={{ transition: "0.3s" }}
                  data={area?.map((d: { x: string; y: number }) => ({
                    x: xScale(d.x)! + xScale.bandwidth() / 2,
                    y: yScale(d.y)!,
                  }))}
                  x={(d: any) => d.x}
                  y={(d: any) => d.y}
                  stroke={getCurrentColorV2(currentWidget, key)}
                  strokeWidth={2}
                  strokeDasharray="0"
                  curve={curveLinear}
                  opacity={hoveredElement ? 0.2 : 1}
                />
              );
            })}
        </Group>

        <Group>
          {groupedData &&
            Object.keys(groupedData)?.map((key, index: number) => {
              const area = groupedData[key];

              return (
                <Group key={`${key}-${index}`}>
                  {area?.map((d: { x: string; y: number }, i: number) => {
                    const elementKey = key + "-" + index + "-" + i;

                    const onMouseEnter = (event: any) => {
                      if (currentWidget.tooltip && !recommended) {
                        const { pageX, pageY, clientX, clientY } = event;
                        const coords = { pageX, pageY, clientX, clientY };

                        const tooltipData: Record<string, string> = {
                          [xAxe as string]: String(d.x),
                          [yAxe as string]: String(d.y),
                        };

                        setTooltip({
                          name: key !== "default" ? key : undefined,
                          data: tooltipData,
                          coords,
                        });

                        setHoveredElement(recommended ? null : elementKey);
                      }
                    };
                    const onMouseLeave = () => {
                      setTooltip(null);
                      setHoveredElement(null);
                    };

                    return LineChartMarker({
                      i: i,
                      hovered: hoveredElement === elementKey,
                      markerType: getCurrentMarker(currentWidget, key),
                      color: getCurrentColorV2(currentWidget, key),
                      opacity: hoveredElement
                        ? hoveredElement === elementKey
                          ? 1
                          : 0
                        : 1,
                      xScale: xScale(d.x)!,
                      xScaleBand: xScale.bandwidth() / 2,
                      yScale: yScale(d.y),
                      r: hoveredElement === elementKey ? 5 : 3,
                      onMouseEnterHandler: onMouseEnter,
                      onMouseLeaveHandler: onMouseLeave,
                    });
                  })}
                </Group>
              );
            })}
        </Group>
      </svg>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
            coords={tooltip.coords}
          />,
          document.body
        )}
    </>
  );
};
