export const searchOptions = [
  {
    id: "1",
    name: "County",
    count: 58,
    items: [
      { id: "1-1", name: "Alameda", count: 12 },
      { id: "1-2", name: "Alpine", count: 8 },
      { id: "1-3", name: "Amador", count: 15 },
      { id: "1-4", name: "Butte", count: 10 },
      { id: "1-5", name: "Calaveras", count: 9 },
      { id: "1-6", name: "Colusa", count: 11 },
      { id: "1-7", name: "Contra Costa", count: 14 }
    ]
  },
  {
    id: "2",
    name: "Date",
    count: 7,
    items: [
      { id: "2-1", name: "2024", count: 1 },
      { id: "2-2", name: "2023", count: 1 },
      { id: "2-3", name: "2022", count: 1 },
      { id: "2-4", name: "2021", count: 1 },
      { id: "2-5", name: "2020", count: 1 },
      { id: "2-6", name: "2019", count: 1 },
      { id: "2-7", name: "2018", count: 1 }
    ]
  },
  {
    id: "3",
    name: "Health Plan",
    count: 58,
    items: [
      { id: "3-1", name: "Health Net Community Solutions, Inc.", count: 15 },
      { id: "3-2", name: "Contra Costa Health Plan", count: 12 },
      { id: "3-3", name: "Community Health Plan of Imperial Valley", count: 8 }
    ]
  },
  {
    id: "4",
    name: "Gender",
    count: 58,
    items: [
      { id: "4-1", name: "Male", count: 25 },
      { id: "4-2", name: "Female", count: 28 },
      { id: "4-3", name: "Non-Binary", count: 5 }
    ]
  },
  {
    id: "5",
    name: "Age group",
    count: 58,
    items: [
      { id: "5-1", name: "0-17", count: 15 },
      { id: "5-2", name: "18-24", count: 10 },
      { id: "5-3", name: "25-34", count: 12 },
      { id: "5-4", name: "35-44", count: 8 },
      { id: "5-5", name: "45-54", count: 7 },
      { id: "5-6", name: "55-64", count: 6 }
    ]
  },
  {
    id: "6",
    name: "Race / Ethnicity",
    count: 58,
    items: [
      { id: "6-1", name: "White", count: 20 },
      { id: "6-2", name: "Black or African American", count: 15 },
      { id: "6-3", name: "Hispanic or Latino", count: 18 },
      { id: "6-4", name: "Asian", count: 12 },
      { id: "6-5", name: "Native American", count: 5 },
      { id: "6-6", name: "Pacific Islander", count: 4 }
    ]
  },
  {
    id: "7",
    name: "Primary Language",
    count: 58,
    items: [
      { id: "7-1", name: "English", count: 35 },
      { id: "7-2", name: "Spanish", count: 25 },
      { id: "7-3", name: "Chinese (Mandarin)", count: 12 },
      { id: "7-4", name: "Chinese (Cantonese)", count: 10 },
      { id: "7-5", name: "Vietnamese", count: 8 },
      { id: "7-6", name: "Tagalog", count: 6 }
    ]
  }
];

export interface ItemData {
  id: string;
  name: string;
  count: number;
}

export interface SearchItem {
  id: string;
  name: string;
  count: number;
  items: SearchItem[];
}