import styled from "styled-components";

export const SVGStyled = styled.svg`
  .x-axis .tick:first-of-type text {
    text-anchor: start;
  }

  .x-axis .tick:last-of-type text {
    text-anchor: end;
  }

  .y-axis .tick:first-of-type text {
    dominant-baseline: ideographic;
  }

  .y-axis .tick:last-of-type text {
    dominant-baseline: central;
  }
`;


export const HeaderWrapper = styled.div<{
  $isRow?: boolean;
}>`
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  flex-direction: ${({ $isRow }) => ($isRow ? "row" : "column")};
  align-items: flex-start;
  gap: 8px;
`;

export const HeadingNameAndButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
  line-height: 22px;
`;
