import { useDispatch, useSelector } from "react-redux";
import {
  KPISettings,
  KPIWrapperBorder,
  KPIWrapperCol,
  KPIWrapperMainContainer,
  KPIWrapperRow,
} from "./styles";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";
import { setActiveModal } from "../../store/slices/modals";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { AiKpiDto } from "../../models/Widgets";
import { getAiKpis } from "../../store/selectors/widgets";
import { KPI_TYPES, KPIComponentWrapper } from "../KPIComponentWrapper";
import { SettingsButtonWrapper, DatavizRecommendedCount, DatavizSettingsIcon } from "../Widgets/styles";

export interface KPISettingsWrapperProps {
  recommended: boolean;
  storytelling: boolean;
  kpis: AiKpiDto[];
}

export const KPISettingsWrapper = (props: KPISettingsWrapperProps) => {
  const dispatch = useDispatch();
  const { kpis, recommended, storytelling } = props;
  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiKpis = useSelector(getAiKpis);
  const [localKpis, setLocalKpis] = useState<AiKpiDto[][]>([]);
  const [componentWidth, setComponentWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const isRowView = componentWidth > window.innerWidth * 0.33


  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setComponentWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth();

    const resizeObserver = new ResizeObserver(updateWidth);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);


  useEffect(() => {
    if (kpis?.length) {
      let result: SetStateAction<AiKpiDto[][]> = [];
      let firstRow = kpis.filter((k) =>
        [KPI_TYPES.TOP?.toString(), KPI_TYPES.TREND?.toString()].includes(
          k.type
        )
      );
      let secondRow = kpis.filter((k) =>
        [
          KPI_TYPES.DISTINCTION?.toString(),
          KPI_TYPES.HIGH_LOW?.toString(),
        ].includes(k.type)
      );
      if (firstRow?.length > 2) {
        const firstKpi = firstRow?.at(0);
        firstRow = firstRow?.filter((k) => k.id !== firstKpi?.id);
        result = [firstRow, [firstKpi!]];
      } else {
        result = [firstRow, secondRow];
      }

      setLocalKpis(result);
    }
  }, [kpis]);


  return (
    <KPIWrapperMainContainer ref={containerRef}>
      <KPIWrapperRow $isRow={isRowView}>
        {(isRowView ? [localKpis?.flat()] : localKpis)?.map((kpiRow, index) => {
          return (
            <KPIWrapperCol key={index}>
              {kpiRow?.map((kpi, key) => {
                return (
                  <KPIWrapperBorder
                    $borderRight={!isRowView && key === 0 && kpiRow?.length > 1}
                    $borderTop={
                      !isRowView && index === localKpis?.length - 1 && localKpis?.length > 1
                    }
                    $borderLeft={key !== 0 && isRowView}
                    key={kpi.id}
                  >
                    <KPIComponentWrapper
                      isRowView={isRowView}
                      key={kpi.id}
                      kpi={kpi}
                      recommended={false}
                      selected={false}
                      size={!isRowView && key === 0 ? "sm" : "md"}
                      adaptiveWidth
                    />
                  </KPIWrapperBorder>
                );
              })}
            </KPIWrapperCol>
          );
        })}
      </KPIWrapperRow>
      {!isPublicRoute && !recommended && isEditMode && !storytelling ? (
        <KPISettings>
          <SettingsButtonWrapper
            $modalOpen={false}
            onClick={() => {
              dispatch(setActiveModal({ id: "recommendedKpisModal" }));
            }}
          >
            <DatavizRecommendedCount>{aiKpis?.length}</DatavizRecommendedCount>
            <DatavizSettingsIcon />
          </SettingsButtonWrapper>
        </KPISettings>
      ) : null}
    </KPIWrapperMainContainer>
  );
};
