import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 6px;
`

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  overflow: hidden;
`;

export const SelectBageBlock = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 5;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  border-radius: 6px;
  z-index: 1;
`;
