import { useState, useCallback } from 'react';
import { SearchItem } from '../types';

export const useSearchNavigation = (searchOptions: SearchItem[]) => {
  const [navigationStack, setNavigationStack] = useState<SearchItem[]>([]);
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());

  const getCurrentItems = useCallback((searchValue: string): SearchItem[] => {
    if (navigationStack.length === 0) {
      return searchOptions;
    }

    const currentLevel = navigationStack[navigationStack.length - 1];
    const originalCategory = searchOptions.find(
      (cat) => cat.id === currentLevel.id
    );

    if (!originalCategory?.items) return [];

    if (searchValue) {
      return originalCategory.items.filter((item) =>
        item.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    return originalCategory.items;
  }, [navigationStack, searchOptions]);

  return {
    navigationStack,
    setNavigationStack,
    expandedCategories,
    setExpandedCategories,
    getCurrentItems
  };
};
