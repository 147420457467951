import React from "react";
import {
  ProjectCardUsersWrapper,
  UserAvatar,
  UserIcon,
  UserIconWrapper,
} from "./styles";
import { getInitials } from "../../helpers/getInitials";

interface ProjectCardUsersInterface {
  users: { name: string; id: string; avatar?: string }[];
}

export const ProjectCardUsers = ({ users }: ProjectCardUsersInterface) => {
  return (
    <ProjectCardUsersWrapper>
      {users.slice(0, 4).map((user) => {
        return user.avatar ? (
          <UserAvatar src={user.avatar} key={user.id} />
        ) : (
          <UserIconWrapper
            key={user.id}
            $user={user.id}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <UserIcon>{getInitials(user.name)}</UserIcon>
          </UserIconWrapper>
        );
      })}
      {users.length > 4 && (
        <UserIconWrapper
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <UserIcon>+{users.length - 4}</UserIcon>
        </UserIconWrapper>
      )}
    </ProjectCardUsersWrapper>
  );
};
