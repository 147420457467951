import { FormikErrors, FormikTouched } from "formik";
import { ChangeEvent } from "react";
import { IProfile } from "../../../../types/profile";

export function getNotificationSettings(values: IProfile['desktopNotifications'], errors: { [key: string]: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined; }, touched: { [key: string]: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined; }, handleChange: (event: ChangeEvent<HTMLInputElement> | any) => void, handleBlur: React.ChangeEventHandler<Element>) {
  return [
    {
      name: 'importantAlerts',
      checked: String(values?.importantAlerts) === 'All',
      errors: errors.desktopNotifications as string,
      touched: touched.desktopFileComments as boolean,
      label: 'All',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'importantAlerts',
      checked: String(values?.importantAlerts) === 'Only mentions and replies',
      errors: errors.desktopNotifications as string,
      touched: touched.desktopNotifications as boolean,
      label: 'Only mentions and replies',
      onChange: handleChange,
      onBlur: handleBlur,
    },
  ];
}

export function getEmailNotificationSettings(values: IProfile['emailNotifications'], errors: { [key: string]: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined; }, touched: { [key: string]: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined; }, handleChange: (event: ChangeEvent<HTMLInputElement> | any) => void, handleBlur: React.ChangeEventHandler<Element>) {
  return [
    {
      name: 'emailViewerEditor',
      checked: String(values?.emailViewerEditor) === 'All',
      errors: errors.emailViewerEditor as string,
      touched: touched.emailViewerEditor as boolean,
      label: 'All',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'emailViewerEditor',
      checked: String(values?.emailViewerEditor) === 'Only mentions and replies',
      errors: errors.emailViewerEditor as string,
      touched: touched.emailViewerEditor as boolean,
      label: 'Only mentions and replies',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'emailViewerEditor',
      checked: String(values?.emailViewerEditor) === 'None',
      errors: errors.emailViewerEditor as string,
      touched: touched.emailViewerEditor as boolean,
      label: 'None',
      onChange: handleChange,
      onBlur: handleBlur,
    },
  ];
}

export function getInvitesSettings(values: IProfile['emailNotifications'], errors: { [key: string]: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined; }, touched: { [key: string]: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined; }, handleChange: (event: ChangeEvent<HTMLInputElement> | any) => void, handleBlur: React.ChangeEventHandler<Element>) {
  return [
    {
      name: 'emailStatusUpdate',
      checked: String(values?.emailStatusUpdate)  === 'All',
      errors: errors.emailStatusUpdate as string,
      touched: touched.emailStatusUpdate as boolean,
      label: 'All',
      onChange: handleChange,
      onBlur: handleBlur,
    },
    {
      name: 'emailStatusUpdate',
      checked: String(values?.emailStatusUpdate) === 'None',
      errors: errors.emailStatusUpdate as string,
      touched: touched.emailStatusUpdate as boolean,
      label: 'None',
      onChange: handleChange,
      onBlur: handleBlur,
    },
  ];
}
