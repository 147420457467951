import { TemplatesSVG } from "../components/Modals/ProjectSettings/TemplatesSVG/templatesSVG";
import { LAYOUTS } from "../components/ProjectPageLayouts/config";

export const Templates = () => {
  const oldTemplates = LAYOUTS
  const newTemplates = TemplatesSVG

  return (
    <div style={{ display: "flex", gap: "5px", width: "100%" }}>
      <div>
        <div>OLD TEMPLATES</div>
        <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", width: "100%", marginTop: "10px" }}>
          {oldTemplates.map((template) => (
            <div key={template.id}>
              <div>{template.id}</div>
              <img src={template.image} alt={template.id} />
            </div>
          ))}
        </div>
      </div>
      <div style={{ borderLeft: "1px solid black", paddingLeft: "10px" }}>
        <div>NEW TEMPLATES</div>
        <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", width: "100%", marginTop: "10px" }}>
          {Object.entries(newTemplates).map((template) => {
            const Image = template[1]
            return (
              <div style={{ minWidth: "102px" }} key={template[0]}>
                <div>{template[0]}</div>
                {Image && <Image />}
              </div>
            )
          })}
        </div>
      </div>
    </div>

  );
};
