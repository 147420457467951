import { styled } from "styled-components";
import { CommentItem, MessageContent } from "./components/Message/styles";

export const CommentWrapper = styled.div<{ $isStorytelling: boolean }>`
  position: absolute;
  top: ${({ $isStorytelling }) => ($isStorytelling ? "10px" : "15px")};
  ${({ $isStorytelling }) => ($isStorytelling ? "left: 55px" : "right: -150px")};
  width: 360px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
  padding: 12px;
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  z-index: 10;
`;

export const ReplyBox = styled.div`
`;

export const ReplyButton = styled.button`
  flex: 1;
  padding: 8px;
  font-size: 14px;
  outline: none;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  background: ${({ theme }) => theme.neutralBackground};
  &:hover {
    background: ${({ theme }) => theme.neutralBackgroundHover};
  }
`;

export const Comments = styled.div`
  max-height: 312px;
  overflow: auto;
  background: ${({ theme }) => theme.neutralBackgroundBase};
  scroll-behavior: smooth;
`;

export const MessagesContainer = styled.div`
  > ${CommentItem}:not(:last-child) ${MessageContent} {
    border-bottom: 1px solid ${({ theme }) => theme.neutralBorder};
  }
`;
