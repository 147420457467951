import React, { Fragment } from "react";
import * as s from "./styles";
import { Search, X } from "react-bootstrap-icons";
import { ReactComponent as Sliders } from "../../../../assets/sliders.svg"

interface SearchInputProps {
  isOpen: boolean;
  inputValue: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearAll: () => void;
  onResetNavigation: () => void;
  setIsOpen: (value: boolean) => void;
  selections: string[];
}

export const SearchInput: React.FC<SearchInputProps> = ({
  isOpen,
  selections,
  inputValue,
  onSearchChange,
  onClearAll,
  onResetNavigation,
  setIsOpen,
}) => {
  const formatSelections = () => {
    const selection = selections?.[0]?.split(":");
    if (!selection?.length) return "<p>Search<p>";

    const count = selections.reduce((acc: number, selection: string) => {
      const [_, items] = selection.split(":");
      if (!items) return 0;
      acc += items.split(", ").length;
      return acc;
    }, 0);
    return `<span>${selection[0]}: ${selection[1]?.split(", ")[0]} ${count - 1 ? `+${count - 1}`: ""}</span>`;
  };

  return (
    <s.SearchInputWrapper
      onClick={() => setIsOpen(true)}
      $width={isOpen ? 515 : 220}
    >
      <s.IconWrapper>
        <Search size={16} />
      </s.IconWrapper>

      <s.InputContainer>
        {isOpen ? (
          <>
            {selections.map((selection, index) => (
              <Fragment key={index}>
                {/* {index > 0 && <s.Divider>|</s.Divider>} */}
                <s.SelectionText>{selection}</s.SelectionText>
              </Fragment>
            ))}
            {/* {selections.length > 0 && <s.Divider>|</s.Divider>} */}
            <s.StyledInput
              placeholder={selections.length === 0 ? "Search" : ""}
              value={inputValue}
              onChange={onSearchChange}
            />
          </>
        ) : (
          <s.MiniSelectionText>
            <div dangerouslySetInnerHTML={{__html: formatSelections()}} />
          </s.MiniSelectionText>
        )}
      </s.InputContainer>

      <s.RightSection>
        {(selections.length || inputValue) && (
          <s.IconWrapper onClick={onClearAll}>
            <X size={16} />
          </s.IconWrapper>
        )}
        {isOpen && (
          <>
            <s.VerticalDivider />
            <s.IconWrapper onClick={onResetNavigation}>
              <Sliders />
            </s.IconWrapper>
          </>
        )}
      </s.RightSection>
    </s.SearchInputWrapper>
  );
};

export default SearchInput;
