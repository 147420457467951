import { styled } from "styled-components";

export const Container = styled.div<{$top?: number, $left?: number}>`
  width: 184px;
  position: absolute;
  top: ${({$top}) => $top + 'px'};
  left: ${({$left}) => $left + 'px'};
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  border: 1px solid ${({theme}) => theme.neutralBorder};
  background-color: ${({theme}) => theme.neutralBackgroundBase};
  border-radius: 6px;
`

export const MenuItem = styled.div<{$delete?: boolean}>`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({theme}) => theme.neutralTextWeak};
  transition: 0.3s;

  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;
  ${({$delete, theme}) => $delete && `
    color: ${theme.alertText};
  `}

  &:hover {
    ${({$delete, theme}) => $delete ? `
    color: ${theme.alertTextHover};
    ` : `
    color: ${theme.neutralText}
    `}
  }
`

export const Separator = styled.div`
  height: 1px;
  background-color: ${({theme}) => theme.neutralBorder};
  width: 94%;
  margin: 6px auto;
`