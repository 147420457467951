import { useMemo } from "react";

import { getSequentialColorsHex } from "../../../../constants/utils/getSequentialsColors";
import { ProjectDTO } from "../../../../models/Projects";
import {
  IFormatting,
  IMarkersType,
  WidgetItem,
} from "../../../../models/Widgets";
import { DatasetsItem } from "../../../DatasetsItem";
import SwitchComponent from "../../../Inputs/CustomSwitch/Switch";
import { AreaChartDefaultMarkers } from "../../../Widgets/AreaChart/utils/getGroupData";
import { getBarChartFormatting } from "../../../Widgets/GroupedBarChart/utils/getKeys";
import { getLineChartMarkers } from "../../../Widgets/LineChart/utils/getLineChartMarkers";
import { getLollipopChartMarkers } from "../../../Widgets/Lollipop/utils/getLollipopChartMarkers";
import { getPolarAreaChartDefaultColors } from "../../../Widgets/PolarAreaChart/utils/getGroupData";
import {
  getRadarChartDefaultColors,
  getRadarChartDefaultMarkers,
} from "../../../Widgets/RadarChart/utils/getGroupData";
import { ScatterPlotDefaultMarkers } from "../../../Widgets/ScatterPlot/utils/getGroupData";
import { getCurrentColorV2 } from "../../../Widgets/utils/getCurrentMarker";
import { markers } from "../../data/mock";
import { IMarkers } from "../../types/types";
import ChartColorsSelect from "../ChartColorsSelect";
import { ChartTypeSelect } from "../ChartTypeSelect";
import DataRangeFilter from "../DataRangeFilter/DataRangeFilter";

//* Temporary deprecated Metrics
// import { getMetrics, IMetrics } from "../../utils/getMetrics";
// import { SelectDimensions } from "../../../Inputs/CustomSelectDimensions/SelectDimensions";
// import { DnDBadge } from "../../../Inputs/CustomSelectDimensions/types";

import {
  MapSettingsControl,
  MapSettingsControlName,
  MapSettingsControlsWrapper,
  MapSettingsDatasets,
  MapSettingsDatasetsHeading,
  MapSettingsDatasetsWrapper,
  MapSettingsLeftSide,
  MapSettingsRangeControlHeader,
  MapSettingsRangeControlName,
  MapSettingsRangeControlWrapper,
  MapSettingsRightSide,
  MapSettingsStyleFormating,
  MapSettingsStyleFormatingColors,
  MapSettingsStyleFormatingElement,
  MapSettingsStyleFormatingElementsList,
  MapSettingsStyleFormatingName,
  MapSettingsStyleFormatingParamSelect,
  MapSettingsStyleFormatingParamSelectContent,
  MapSettingsStyleFormatingParamSelectText,
  Marker,
  MarkerSelect,
} from "../../styles";

interface WidgetsConfigProps {
  currentWidget: WidgetItem;
  widgetData: WidgetItem;
  setWidgetData: React.Dispatch<React.SetStateAction<WidgetItem>>;
  projectData: ProjectDTO;
  selectedDimensions: string[];
  selectedMarker?: IMarkersType;
  setSelectedMarker: React.Dispatch<
    React.SetStateAction<IMarkersType | undefined>
  >;
  selectedKey?: string;
  setSelectedKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedColors: string[];
  selectedFormatting?: IFormatting;
  setSelectedFormatting: React.Dispatch<
    React.SetStateAction<IFormatting | undefined>
  >;
  colorRanges: number[];
  setColorRanges: React.Dispatch<React.SetStateAction<number[]>>;
  paletteIndex: number;
  setPaletteIndex: React.Dispatch<React.SetStateAction<number>>;
  currentChartPalette: () => any;
  setSelectedChart: (chart?: string) => void;
}

const WidgetsConfig = ({
  currentWidget,
  widgetData,
  setWidgetData,
  projectData,
  selectedDimensions,
  selectedMarker,
  setSelectedMarker,
  selectedKey,
  setSelectedKey,
  selectedColors,
  selectedFormatting,
  setSelectedFormatting,
  colorRanges,
  setColorRanges,
  paletteIndex,
  setPaletteIndex,
  currentChartPalette,
  setSelectedChart,
}: WidgetsConfigProps) => {
  let markerTypes = [...markers];
  if (["scatterplot", "scatterplotChart"].includes(widgetData.chartType)) {
    markerTypes = markerTypes.filter((type) => type !== "disabled");
    markerTypes.unshift("cross");
  }

  const chartPalette = currentChartPalette();

  const paletteLength = Array.isArray(chartPalette)
    ? 2
    : chartPalette.colors.length;

  const widgetFormatting = useMemo(() => {
    let formatting = widgetData?.formatting;
    if (currentWidget?.chartType === "polarAreaChart") {
      switch (widgetData?.chartType) {
        case "polarAreaChart":
          formatting = getPolarAreaChartDefaultColors(widgetData);
          break;
        case "barChart":
          formatting = getBarChartFormatting(widgetData);
          break;
        case "radar":
          formatting = getRadarChartDefaultColors(widgetData);
      }
    }

    setWidgetData((prev) => ({
      ...prev,
      formatting,
    }));

    return formatting;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWidget?.chartType, widgetData?.chartType]);

  const widgetMarkers = useMemo(() => {
    if (widgetData?.markers) {
      return widgetData.markers;
    }

    switch (widgetData?.chartType) {
      case "areaChart":
        return AreaChartDefaultMarkers(widgetData);
      case "lineChart":
        return getLineChartMarkers(widgetData);
      case "lollipopChart":
        return getLollipopChartMarkers(widgetData);
      case "radar":
        return getRadarChartDefaultMarkers(widgetData);
      case "scatterplot":
      case "scatterplotChart":
        return ScatterPlotDefaultMarkers(widgetData);
      default:
        return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData?.chartType]);

  //* Temporary deprecated Metrics
  // const handleOnCancel = (label: string) => {
  //   const removeFromArray = (
  //     array: string[] | undefined,
  //     label: string
  //   ): string[] | undefined => {
  //     return array ? array.filter((name) => name !== label) : array;
  //   };

  //   setWidgetData((prev) => {
  //     const updatedWidgetData: WidgetItem = { ...prev };
  //     const data: IGroupTypesV2[] = [
  //       "arrangeBy",
  //       "groupBy",
  //       "display",
  //       "xAxe",
  //       "yAxe",
  //     ];
  //     data.forEach((key) => {
  //       updatedWidgetData[key] = removeFromArray(prev[key], label);
  //     });

  //     return updatedWidgetData;
  //   });
  // };

  // const handleOnDrop = (item: DnDBadge, groupName: IGroupTypesV2) => {
  //   setWidgetData((prev) => {
  //     const group = prev[groupName];
  //     const isArray = Array.isArray(group);
  //     if (isArray) {
  //       if (!selectedDimensions.includes(item.key)) {
  //         return {
  //           ...prev,
  //           [groupName]: [...group, item.key],
  //         };
  //       } else if (selectedDimensions.includes(item.key)) {
  //         const obj: WidgetItem = {
  //           ...prev,
  //           arrangeBy: prev.arrangeBy?.includes(item.key)
  //             ? prev.arrangeBy.filter((key) => key !== item.key)
  //             : prev.arrangeBy,
  //           groupBy: prev.groupBy?.includes(item.key)
  //             ? prev.groupBy.filter((key) => key !== item.key)
  //             : prev.groupBy,
  //           display: prev.display?.includes(item.key)
  //             ? prev.display.filter((key) => key !== item.key)
  //             : prev.display,
  //           xAxe: prev.xAxe?.includes(item.key)
  //             ? prev.xAxe.filter((key) => key !== item.key)
  //             : prev.xAxe,
  //           yAxe: prev.yAxe?.includes(item.key)
  //             ? prev.yAxe.filter((key) => key !== item.key)
  //             : prev.yAxe,
  //         };

  //         return {
  //           ...obj,
  //           [groupName]: [...group, item.key],
  //         };
  //       } else {
  //         return prev;
  //       }
  //     } else {
  //       return {
  //         ...prev,
  //         [groupName]: [item.key],
  //       };
  //     }
  //   });
  // };

  // const selectedBagdes = (groupName: IGroupTypesV2): string[] => {
  //   return widgetData[groupName] as string[];
  // };
  //* END - Temporary deprecated Metrics

  const handlePickMarker = (type: IMarkers) => {
    if (selectedKey) {
      setSelectedMarker({ key: selectedKey, shape: type });

      setWidgetData({
        ...widgetData,
        markers: widgetMarkers?.map((item) => {
          if (item.key === selectedKey) {
            return { ...item, shape: type };
          }
          return item;
        }),
      });
    }
  };

  const handlePickColor = (pickedColor: number) => {
    const isSelected = selectedColors.includes(String(pickedColor));

    if (selectedKey && !isSelected) {
      setSelectedFormatting({ key: selectedKey, color: String(pickedColor) });

      setWidgetData((prev) => ({
        ...prev,
        formatting: prev.formatting?.map((item) => {
          if (item.key === selectedKey) {
            return { key: item.key, color: String(pickedColor) };
          }
          return item;
        }),
      }));
    }
  };

  const handleSelectLabel = (key: string | undefined) => {
    setSelectedKey(key);
    setSelectedFormatting(
      widgetData.formatting?.find((item) => item.key === key)
    );
    setSelectedMarker(widgetMarkers?.find((item) => item.key === key));
  };

  const handleColorChangePalette = (
    state: "left" | "right",
    paletteId: string
  ) => {
    if (state === "left") {
      setPaletteIndex((prev) => prev - 1);
    } else {
      setPaletteIndex((prev) => prev + 1);
    }
    setWidgetData((prev) => ({
      ...prev,
      palette: { ...prev.palette, paletteId: paletteId },
    }));
  };

  return (
    <>
      <MapSettingsLeftSide>
        <MapSettingsDatasetsWrapper>
          <MapSettingsDatasetsHeading>Parameters</MapSettingsDatasetsHeading>

          <MapSettingsDatasets>
            {projectData.datasets.map((dataset) => (
              <DatasetsItem
                key={dataset.filePath}
                dataset={dataset}
                // selectedDimensions={selectedDimensions}
                // isDraggable
                disablePreview
              />
            ))}
          </MapSettingsDatasets>
        </MapSettingsDatasetsWrapper>

        <ChartTypeSelect
          currentWidget={currentWidget}
          widgetData={widgetData}
          setWidgetData={setWidgetData}
          setSelectedChart={setSelectedChart}
        />
      </MapSettingsLeftSide>

      <MapSettingsRightSide>
        {/* //* Temporary deprecated Metrics */}
        {/* <MapSettingsLayoutWrapper>
          Metrics
          <MapSettingsLayouts>
            {getMetrics(
              widgetData.chartType,
              widgetData.orientation as "horizontal" | "vertical"
            ).map((item: IMetrics) => (
              <SelectDimensions
                onDrop={(dimension) => handleOnDrop(dimension, item.type)}
                onCancel={(name) => handleOnCancel(name)}
                selectedBadges={selectedBagdes(item.type)}
                lable={item.lable}
                subLable={item.subLable}
                icon={item.icon}
                axe={item.axe}
                acceptTypes={item.acceptTypes}
                allBadges={projectData.datasets[0].fields.map((item) => ({
                  ...item,
                  name: item.label,
                }))}
              />
            ))}
          </MapSettingsLayouts>
        </MapSettingsLayoutWrapper> */}

        <MapSettingsStyleFormating>
          <MapSettingsStyleFormatingName>
            Style formatting
          </MapSettingsStyleFormatingName>

          {!widgetMarkers?.length &&
            widgetData?.groupBy?.[0] &&
            !["barChart", "polarAreaChart"].includes(widgetData?.chartType) && (
              <MapSettingsStyleFormatingElementsList>
                {widgetData?.uniqueValues?.[widgetData?.groupBy?.[0]].map(
                  (key: string) => (
                    <MapSettingsStyleFormatingElement
                      key={key}
                      $selected={key === selectedKey}
                      onClick={() => {
                        handleSelectLabel(
                          selectedKey === key ? undefined : key
                        );
                      }}
                    >
                      <Marker
                        $type={"donut"}
                        $color={getCurrentColorV2(widgetData, key, "default")}
                      />
                      {key}
                    </MapSettingsStyleFormatingElement>
                  )
                )}
              </MapSettingsStyleFormatingElementsList>
            )}

          {!!widgetMarkers?.length &&
            !["barChart", "matrix"].includes(widgetData?.chartType) && (
              <MapSettingsStyleFormatingElementsList>
                {widgetMarkers
                  ?.filter((item) => item.key !== "default")
                  .map((item) => (
                    <MapSettingsStyleFormatingElement
                      key={item.key}
                      $selected={item.key === selectedKey}
                      onClick={() =>
                        handleSelectLabel(
                          selectedKey === item.key ? undefined : item.key
                        )
                      }
                    >
                      <Marker
                        $type={item.shape}
                        $color={getCurrentColorV2(
                          widgetData,
                          item.key,
                          "default"
                        )}
                      />
                      {item.key}
                    </MapSettingsStyleFormatingElement>
                  ))}
              </MapSettingsStyleFormatingElementsList>
            )}

          {!!widgetFormatting?.length &&
            ["barChart", "polarAreaChart"].includes(widgetData.chartType) && (
              <MapSettingsStyleFormatingElementsList>
                {widgetFormatting
                  ?.filter((item) => item.key !== "default")
                  .map((item) => (
                    <MapSettingsStyleFormatingElement
                      key={item.key}
                      $selected={false || item.key === selectedKey}
                      $disableHover
                      onClick={() => {
                        if (paletteLength === widgetFormatting?.length) {
                          return;
                        }
                        handleSelectLabel(
                          selectedKey === item.key ? undefined : item.key
                        );
                      }}
                    >
                      <Marker
                        $type="square"
                        $color={getCurrentColorV2(widgetData, item.key)}
                      />
                      {item.key}
                    </MapSettingsStyleFormatingElement>
                  ))}
              </MapSettingsStyleFormatingElementsList>
            )}

          <MapSettingsStyleFormatingParamSelect>
            <MapSettingsStyleFormatingParamSelectText>
              Color
            </MapSettingsStyleFormatingParamSelectText>

            <MapSettingsStyleFormatingColors>
              <ChartColorsSelect
                selectedFormatting={selectedFormatting?.color}
                handlePickColor={(color: number) => handlePickColor(color)}
                currentColors={chartPalette}
                paletteIndex={paletteIndex}
                handleColorChangePalette={handleColorChangePalette}
                type={
                  ["matrix", "sankey", "mapChart", "punchcardChart"].includes(
                    widgetData.chartType
                  )
                    ? "sequential"
                    : "qualitative"
                }
              />
            </MapSettingsStyleFormatingColors>
          </MapSettingsStyleFormatingParamSelect>

          {[
            "areaChart",
            "lollipopChart",
            "lineChart",
            "scatterplot",
            "scatterplotChart",
            "radar",
          ].includes(widgetData.chartType) && (
            <MapSettingsStyleFormatingParamSelect>
              <MapSettingsStyleFormatingParamSelectText>
                Markers
              </MapSettingsStyleFormatingParamSelectText>

              <MapSettingsStyleFormatingParamSelectContent>
                {markerTypes.map((item) => (
                  <MarkerSelect
                    key={item}
                    $selected={selectedMarker?.key === item}
                    onClick={() => handlePickMarker(item)}
                  >
                    <Marker
                      $type={item}
                      $color={
                        selectedMarker?.shape === item ? "#2D6E8D" : "#5F6877"
                      }
                    />
                  </MarkerSelect>
                ))}
              </MapSettingsStyleFormatingParamSelectContent>
            </MapSettingsStyleFormatingParamSelect>
          )}
        </MapSettingsStyleFormating>

        {["matrix", "punchcardChart"].includes(widgetData.chartType) && (
          <MapSettingsRangeControlWrapper>
            <MapSettingsRangeControlHeader>
              <MapSettingsRangeControlName>
                Data Points
              </MapSettingsRangeControlName>

              <SwitchComponent
                disableMargin
                checked={widgetData.palette?.autoRange ?? true}
                onChange={(e) =>
                  setWidgetData({
                    ...widgetData,
                    palette: {
                      ...widgetData.palette,
                      autoRange: e.target.checked,
                    },
                  })
                }
                label="Auto"
              />
            </MapSettingsRangeControlHeader>

            <DataRangeFilter
              colors={getSequentialColorsHex(
                widgetData.palette?.paletteId! || "SequentialColors1"
              )}
              widgetData={widgetData}
              setColorRanges={setColorRanges}
            />
          </MapSettingsRangeControlWrapper>
        )}

        <MapSettingsStyleFormating>
          {typeof widgetData.tooltip === "boolean" && (
            <MapSettingsControlsWrapper>
              <MapSettingsControlName>Tooltip</MapSettingsControlName>

              <MapSettingsControl>
                <SwitchComponent
                  label={widgetData.tooltip ? "Show" : "Don't show"}
                  checked={widgetData.tooltip}
                  onChange={(e) =>
                    setWidgetData((prev) => ({
                      ...prev,
                      tooltip: e.target.checked,
                    }))
                  }
                />
              </MapSettingsControl>
            </MapSettingsControlsWrapper>
          )}
        </MapSettingsStyleFormating>
      </MapSettingsRightSide>
    </>
  );
};

export default WidgetsConfig;
