import { WidgetItem } from "../../../../models/Widgets";
import { getAdditionalKeys } from "./getAdditionalKeys";

export const filledArrayWithZeroData = (currentWidget: WidgetItem) => {
  const {
    data: currentData = [],
    uniqueValues = {},
    xAxe = ["eligibility-month"],
    yAxe = ["value"],
  } = currentWidget;

  const additionalKeys = getAdditionalKeys(currentData, xAxe[0], yAxe[0]);
  const workAdditionalKey = additionalKeys.find((key) => uniqueValues[key]) ?? "county";
  const xAxeValues = uniqueValues[xAxe[0]] ?? [];
  const rows = uniqueValues[workAdditionalKey] ?? [];

  const allPossibleVariants = xAxeValues.flatMap((xAxeItem) =>
    rows.map((rowItem) => ({
      [xAxe[0]]: xAxeItem,
      [workAdditionalKey]: rowItem,
      [yAxe[0]]: 0,
    }))
  );

  if (allPossibleVariants.length === currentData.length) {
    return currentData;
  }

  const dataMap = new Map(
    currentData.map((obj) => {
      const key = `${obj[xAxe[0]]}-${obj[workAdditionalKey]}`;
      return [key, obj];
    })
  );

  const filledArray = allPossibleVariants.map((item) => {
    const key = `${item[xAxe[0]]}-${item[workAdditionalKey]}`;
    return dataMap.get(key) ?? item;
  });

  if(filledArray.length === 0) {
    return currentData;
  }

  return filledArray;
};