import { IFormatting, IMarkersType } from "../../../../models/Widgets";

export const get_xAxe = (currentWidget: any, lollipopChartSuggestion?: any) => {
  return currentWidget?.xAxe?.length
    ? currentWidget?.xAxe?.at(0)
    : lollipopChartSuggestion?.xAxe?.at(0);
};

export const get_yAxe = (currentWidget: any, lollipopChartSuggestion?: any) => {
  return currentWidget?.yAxe?.length
    ? currentWidget?.yAxe?.at(0)
    : lollipopChartSuggestion?.yAxe?.at(0);
};

export const get_data = (currentWidget: any, lollipopChartSuggestion?: any) => {
  const xAxe = get_xAxe(currentWidget, lollipopChartSuggestion);
  const yAxe = get_yAxe(currentWidget, lollipopChartSuggestion);

  return currentWidget?.data?.map((l: any) => ({
    x: xAxe ? l[xAxe] : l.year,
    y: yAxe ? l[yAxe] : l.value,
  }));
};

export const getGroupedData = (
  currentWidget: any,
  lollipopChartSuggestion?: any
) => {
  const xAxe = get_xAxe(currentWidget, lollipopChartSuggestion);
  const yAxe = get_yAxe(currentWidget, lollipopChartSuggestion);
  const data = get_data(currentWidget, lollipopChartSuggestion);
  const groupBy = currentWidget?.groupBy?.at(0);

  if (groupBy) {
    return currentWidget?.data?.reduce((acc: any, obj: any) => {
      const group = obj[groupBy];
      acc[group] = acc[group] || [];
      acc[group].push({
        x: xAxe ? obj[xAxe] : obj.year,
        y: yAxe ? obj[yAxe] : obj.value,
      });
      return acc;
    }, {});
  } else {
    return { default: data };
  }
};

export const getLollipopChartMarkers = (
  currentWidget: any,
  lollipopChartSuggestion?: any
): IMarkersType[] => {
  const keys = getGroupedData(currentWidget);


  if (!keys) {
    return [];
  }

  const markers: IMarkersType[] = Object.keys(keys).map((item) => ({
    key: item,
    shape: "rhombus",
  }));

  if(markers.length === currentWidget.markers) {
    return currentWidget.markers
  }

   return markers;
};

export const getLollipopChartColors = (
  currentWidget: any,
  lollipopChartSuggestion?: any
): IFormatting[] => {

  const keys = Object.keys(getGroupedData(currentWidget));

  if (!keys) {
    return [];
  }

  const colors: IFormatting[] = keys.map((item, index) => ({
    key: item,
    color: String(index + 1),
  }));

  return colors;
};
