import React from "react";
import {
  ProfileSideBarAboutCardDescription,
  ProfileSideBarAboutCardDetailsWrapper,
  ProfileSideBarAboutCardIcon,
  ProfileSideBarAboutCardTitle,
  ProfileSideBarAboutCardWrapper,
  ProfileSideBarHeading,
  ProfileSideBarSectionWrapper,
  ViewToggleOn,
} from "./styles";
import { Laptop, EnvelopeExclamation } from "react-bootstrap-icons";
import { setActiveModal } from "../../store/slices/modals";
import { useDispatch } from "react-redux";

export const NotificationsSection = () => {
  const dispatch = useDispatch()
  return (
    <ProfileSideBarSectionWrapper>
      <ProfileSideBarHeading>Notifications</ProfileSideBarHeading>
      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <EnvelopeExclamation />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Email notifications
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription
            onClick={() => {
              dispatch(setActiveModal({ id: "uploadProfileModal", firstItem: 5 }));
            }}
          >
            <ViewToggleOn /> Send Notifications by email
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>

      <ProfileSideBarAboutCardWrapper>
        <ProfileSideBarAboutCardIcon>
          <Laptop />
        </ProfileSideBarAboutCardIcon>
        <ProfileSideBarAboutCardDetailsWrapper>
          <ProfileSideBarAboutCardTitle>
            Desktop notifications
          </ProfileSideBarAboutCardTitle>
          <ProfileSideBarAboutCardDescription
            onClick={() => {
              dispatch(setActiveModal({ id: "uploadProfileModal", firstItem: 5 }));
            }}
          >
            <ViewToggleOn /> Send Notifications to my computer
          </ProfileSideBarAboutCardDescription>
        </ProfileSideBarAboutCardDetailsWrapper>
      </ProfileSideBarAboutCardWrapper>
    </ProfileSideBarSectionWrapper>
  );
};
