import { useNavigate } from "react-router-dom";

import { Button } from "../../../../components/Button";
import { CheckLg } from "react-bootstrap-icons";
import {
  ActionWrapper,
  ConfirmationsDescription,
  ConfirmationHeading,
  ConfirmationWrapper,
  InviteEmail,
} from "../../styles";

const AddMembers = () => {
  const navigate = useNavigate();

  return (
    <ConfirmationWrapper>
      <ConfirmationHeading>Add Members</ConfirmationHeading>
      <ConfirmationsDescription>
        Invite your colleagues to join your team and collaborate seamlessly.
        Simply enter their email addresses to send an invitation.
      </ConfirmationsDescription>
      <InviteEmail id="invite-email" placeholder="Invite via Email" />
      <ActionWrapper>
        <Button
          onClick={() => navigate("/projects")}
          variant="neutral"
          size="medium"
          name="Skip"
        />
        <Button
          onClick={() => {}}
          variant="secondary"
          size="medium"
          name="Send invitation"
          icon={<CheckLg />}
        />
      </ActionWrapper>
    </ConfirmationWrapper>
  );
};

export default AddMembers;
