import { useDispatch, useSelector } from "react-redux";
import { WidgetItem } from "../../../models/Widgets";
import { setPageWidgets } from "../../../store/slices/projectPages";
import { requestUpdateWidgets } from "../../../store/slices/widgets";
import { getPageSettings } from "../../../store/selectors/projects";
import { setThumbnailForceRegenerate } from "../../../store/slices/pdfSlice";

interface UpdateWidgetDTO {
  id: string;
  blockId: string;
}

const getRequestType = (chartType: string): string => {
  const typeMap: Record<string, string> = {
    mapChart: "mapChart",
    areaChart: "areaChart",
    lineChart: "lineChart",
    sparkLineChart: "sparkLineChart",
    sparkAreaChart: "sparkAreaChart",
    barChart: "barChart",
    lollipopChart: "lollipopChart",
    sankey: "sankeyChart",
    matrix: "matrixChart",
    polarAreaChart: "polarAreaChart",
    punchcardChart: "punchcardChart",
    radar: "radarChart",
    scatterplot: "scatterplotChart",
    bubbleChart: "bubbleChart",
    radialBarChart: "radialBarChart",
  };

  return typeMap[chartType] || "";
};

const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

export const useChartWidgetsReorder = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(getPageSettings);

  const handleDragEnd = (
    data: WidgetItem[],
    source: number,
    destination: number
  ) => {
    if (source === destination) return;

    const items = [...data];
    const [removed] = items.splice(source, 1);
    items.splice(destination, 0, removed);

    const reorderedItems = items.map((item, index) => ({
      ...item,
      blockId: String(index + 1),
    }));

    const chartRequests: Record<string, UpdateWidgetDTO[]> = {
      barChart: [],
      lineChart: [],
      sparkLineChart: [],
      sparkAreaChart: [],
      lollipopChart: [],
      sankeyChart: [],
      mapChart: [],
      areaChart: [],
      matrixChart: [],
      scatterplotChart: [],
      radarChart: [],
      punchcardChart: [],
      polarAreaChart: [],
      bubbleChart: [],
      radialBarChart: [],
    };

    reorderedItems.forEach((item) => {
      if (!item?.chartType) return;

      const requestType = getRequestType(item.chartType);
      if (requestType && chartRequests[requestType]) {
        chartRequests[requestType].push({
          id: item.id,
          blockId: item.blockId,
        });
      }
    });

    const totalCharts = Object.values(chartRequests).reduce(
      (sum, arr) => sum + arr.length,
      0
    );

    dispatch(setThumbnailForceRegenerate());

    if (totalCharts > 0) {
      dispatch(
        setPageWidgets({ items: reorderedItems, count: reorderedItems?.length })
      );
      dispatch(
        requestUpdateWidgets({
          barChart: chartRequests.barChart,
          lineChart: chartRequests.lineChart,
          sparkLineChart: chartRequests.sparkLineChart,
          sparkAreaChart: chartRequests.sparkAreaChart,
          lollipopChart: chartRequests.lollipopChart,
          sankeyChart: chartRequests.sankeyChart,
          areaChart: chartRequests.areaChart,
          matrixChart: chartRequests.matrixChart,
          mapChart: chartRequests.mapChart,
          scatterplotChart: chartRequests.scatterplotChart,
          polarAreaChart: chartRequests.polarAreaChart,
          punchcardChart: chartRequests.punchcardChart,
          radarChart: chartRequests.radarChart,
          bubbleChart: chartRequests.bubbleChart,
          radialBarChart: chartRequests.radialBarChart,
          pageId: currentPage.id!,
        })
      );
    }
  };

  const groupItems = (items: WidgetItem[]) => {
    return chunkArray(items, 2);
  };

  return {
    handleDragEnd,
    groupItems,
  };
};
