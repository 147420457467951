import styled, { css } from "styled-components";
import { ReactComponent as Users } from "../../assets/usersWithAccess.svg";
import { ChevronExpand } from "react-bootstrap-icons";

const textStyle = css`
  color: ${({theme}) => theme.neutralTextWeak};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border-right: 1px solid ${({theme}) => theme.neutralBorder};
  padding: 9px 8px;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
`

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${({theme}) => theme.neutralBorder};
  overflow: visible;
`
export const TableHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({theme}) => theme.neutralBackground};
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};

`
export const TableFilters = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
`

export const TableCell = styled.div<{$width?: number; $noPadding?: boolean}>`
  ${textStyle};
  ${({$width}) => $width ? `
    max-width: ${$width}px;
    min-width: ${$width}px;
  ` :`
    height: 100%;
    width: 100%;
  `}

  ${({$noPadding}) => $noPadding && `padding: 0;`}

  &:nth-child(1) {
    height: 100% !important;
    width: 100% !important;
  }

`