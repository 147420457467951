import axios from "./axios";
import { AxiosError, AxiosResponse } from "axios";
import { CLONE_PAGE, CREATE_PAGE, GET_PAGES } from "./constants";
import { generateQueryString } from "../helpers/queryGenerator";
import { PageCloneDTO, PageSettingsDTO } from "../models/Pages";

export type PagesQuery = {
  limit: number;
  projectId: string;
  skip: string;
  orderBy?: string;
  orderDirection?: string;
};

export const getAllPages = async ({
  limit,
  projectId,
  skip,
  orderBy,
  orderDirection,
}: Partial<PagesQuery>) => {
  const queryParams = generateQueryString<PagesQuery>({
    limit,
    projectId,
    skip,
    orderDirection,
    orderBy,
  });

  return axios
    .get(`${GET_PAGES}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const getAllPublicPages = async ({
  limit,
  projectId,
  skip,
}: Partial<PagesQuery>) => {
  const queryParams = generateQueryString<PagesQuery>({
    limit,
    projectId,
    skip,
  });

  return axios
    .get(`/public${GET_PAGES}${queryParams}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
};

export const createPage = (pageData: PageSettingsDTO) =>
  axios
    .post(CREATE_PAGE, pageData)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const clonePage = async (pageData: PageCloneDTO) => {
  const queryParams = generateQueryString<PagesQuery>(pageData);

  return axios
    .post(`${CLONE_PAGE.replace("{id}", pageData.id)}${queryParams}`, {})
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
}

export const getPageById = (id: string) =>
  axios
    .get(`/pages/${id}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const getPublicPageById = (id: string) =>
  axios
    .get(`/public/pages/${id}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const removePage = (id: string) =>
  axios
    .delete(`/pages/${id}`)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));

export const patchPage = ({
  id,
  pageData,
}: {
  id: string;
  pageData: Partial<PageSettingsDTO>;
}) =>
  axios
    .patch(`/pages/${id}`, pageData)
    .then((response: AxiosResponse) => ({ response }))
    .catch((error: AxiosError) => ({ error }));
