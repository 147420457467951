import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { KpiContainer, PageWrapper } from "./styles";
import {
  AddStorytellingSection,
  defaultHeader,
  defaultSection,
} from "../AddStorytellingSection";
import {
  addWidgets,
  IPageHeader,
  PageContentGeneratorItemType,
} from "../../store/slices/pageContentGenerator";
import { getPageContentGeneratorData } from "../../store/selectors/contentGenerator";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getCurrentProjectId,
  getPageBanner,
  getPageId,
  getPageSettings,
} from "../../store/selectors/projects";
import SectionItem from "./components/StoryTellingSection/components/SectionItem/SectionItem";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";
import {
  requestCreateAndUpdateWidgets,
  requestPageBanner,
  requestPageStories,
  requestUpdateWidget,
  requestUpdateWidgets,
} from "../../store/slices/widgets";
import { useDebounceCallback } from "usehooks-ts";
import {
  requestCreatePageSections,
  requestPageKpis,
  requestPagePublicKpis,
  requestPageSections,
  requestPageWidgets,
  requestPublicPageSections,
  requestRemovePageSections,
  requestUpdatePage,
  requestUpdatePageInBackgound,
  requestUpdatePageSections,
  setPageWidgets,
} from "../../store/slices/projectPages";
import { UpdateSingleWidgetDTO, UpdateWidgetDTO } from "../../models/Widgets";
import { defaultNewWidget } from "../../pages/ProjectPage/constants";
import TextImage from "./components/StoryTellingSection/components/TextImage/TextImage";
import { IRemoveSection, IUpdateSection } from "../../api/sections";
import { setActiveModal } from "../../store/slices/modals";
import SectionTextImage from "./components/StoryTellingSection/components/SectionTextImage/SectionTextImage";
import { PageSettingsDTO } from "../../models/Pages";
import { KPISettingsWrapperStory } from "../KPISettingsWrapperStory";
import Comments from "../Comments/Comments";
import { getCustomeCommentsCursor } from "../../store/selectors/comments";
import {
  setSelectWidgetId,
  toggleCommentsCursor,
} from "../../store/slices/commnets";

export const StoryTelling = () => {
  const dispatch = useDispatch();
  const pageWidgets = useSelector(getCurrentPageWidgets);
  const pageContentGenerator = useSelector(getPageContentGeneratorData);
  const isEditMode = useSelector(getIsEditMode);
  const isPublic = useSelector(getIsPublicMode);
  const currentPageId = useSelector(getPageId);
  const currentProjectId = useSelector(getCurrentProjectId);
  const currentPage = useSelector(getPageSettings);
  const header = useSelector(getPageBanner);
  const [firstLoad, setFirstLoad] = useState<string | undefined>();
  const [localItems, setLocalItems] = useState<any[]>([]);
  const [sections, setSections] = useState<any[]>([]);
  const { items } = pageContentGenerator;
  const aiKpi = useSelector(getCurrentPageKpi);
  const isCustomeCursor = useSelector(getCustomeCommentsCursor);

  const [contentItem, setContentItem] = useState<IPageHeader | undefined>(
    header || defaultHeader
  );

  const getKpis = useCallback(() => {
    if (currentPageId) {
      if (isPublic) {
        dispatch(
          requestPagePublicKpis({
            pageId: currentPageId,
            includeData: true,
          })
        );
      } else {
        dispatch(
          requestPageKpis({
            pageId: currentPageId,
            includeData: true,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId]);

  useEffect(() => {
    getKpis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId]);

  const debounced = useDebounceCallback(setContentItem, 500);

  const getAllSections = (callback?: any) => {
    if (isPublic) {
      dispatch(
        requestPublicPageSections({
          pageId: currentPage.id!,
          limit: 20,
          skip: 0,
          callbacks: {
            onSuccess: (data) => {
              setSections(data?.items || []);
              callback && callback();
            },
          },
        })
      );
    } else {
      dispatch(
        requestPageSections({
          pageId: currentPage.id!,
          limit: 20,
          skip: 0,
          callbacks: {
            onSuccess: (data) => {
              setSections(data?.items || []);
              callback && callback();
            },
          },
        })
      );
    }
  };

  const handleSave = (widget: UpdateSingleWidgetDTO) => {
    dispatch(
      requestUpdateWidget({
        ...widget,
        blockId: String(widget?.blockId),
        callbacks: {
          onSuccess: () => {
            dispatch(
              requestPageWidgets({
                pageId: currentPageId!,
                includeData: true,
              })
            );
          },
        },
      })
    );
  };

  const debouncedUpdateWidget = useDebounceCallback(handleSave, 500);
  useEffect(() => {
    getAllSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateHeader = (
    newHeader: IPageHeader,
    currentPage: PageSettingsDTO
  ) => {
    const newHeaderJson = JSON.stringify(newHeader);
    const headerJson = JSON.stringify(header);
    if (
      headerJson !== newHeaderJson &&
      !(contentItem?.description || contentItem?.title)
    ) {
      dispatch(
        requestUpdatePageInBackgound({
          ...currentPage,
          header: {
            ...newHeader,
            image: newHeader?.backgroundColor ? undefined : newHeader?.image,
          },
        })
      );
    }
  };

  const generateBanner = useCallback(() => {
    dispatch(
      requestPageBanner({
        pageId: currentPageId,
        projectId: currentProjectId,
        callbacks: {
          onSuccess: (resp) => {
            const newHeader = {
              ...header,
              description: resp?.banner_description,
              title: resp?.banner_title,
            };
            setContentItem(newHeader);
            updateHeader(newHeader, currentPage);
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId, currentProjectId, header]);

  const checkUpdatedHeader = useCallback(() => {
    const haveDescriptions = pageWidgets?.items?.filter(
      (w) => !w.description || w.description === "Add here description"
    )?.length;
    const noHavePageDescription = !(header?.description && header?.title);
    if (
      pageWidgets?.count > 0 &&
      firstLoad !== currentPageId &&
      !haveDescriptions &&
      noHavePageDescription
    ) {
      setFirstLoad(currentPageId);
      generateBanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    header,
    currentPageId,
    firstLoad,
    pageWidgets?.count,
    pageWidgets?.items,
  ]);

  useEffect(() => {
    checkUpdatedHeader();
  }, [checkUpdatedHeader]);

  const handleEditPage = useCallback(
    (
      widgetDescriptions?: {
        story: string;
        widget_id: string;
        insights: string[];
      }[]
    ) => {
      let barChartRequestData: UpdateWidgetDTO[] = [];
      let lineChartRequestData: UpdateWidgetDTO[] = [];
      let sparkLineChartRequestData: UpdateWidgetDTO[] = [];
      let sparkAreaChartRequestData: UpdateWidgetDTO[] = [];
      let lollipopChartRequestData: UpdateWidgetDTO[] = [];
      let sankeyChartRequestData: UpdateWidgetDTO[] = [];
      let mapChartRequestData: UpdateWidgetDTO[] = [];
      let areaChartRequestData: UpdateWidgetDTO[] = [];
      let matrixChartRequestData: UpdateWidgetDTO[] = [];
      let scatterplotChartRequestData: UpdateWidgetDTO[] = [];
      let radarChartRequestData: UpdateWidgetDTO[] = [];
      let punchcardChartRequestData: UpdateWidgetDTO[] = [];
      let polarAreaChartRequestData: UpdateWidgetDTO[] = [];
      let bubbleChartRequestData: UpdateWidgetDTO[] = [];
      let radialBarChartRequestData: UpdateWidgetDTO[] = [];

      if (widgetDescriptions?.length) {
        pageWidgets?.items.forEach((chart, index) => {
          const descriptionData = widgetDescriptions?.find(
            (w) => w.widget_id === chart.id
          );
          let descriptionChartData = {
            ...defaultNewWidget,
            description: chart?.description || defaultNewWidget.description,
            insights: chart?.insights || defaultNewWidget.insights,
          };
          if (descriptionData) {
            descriptionChartData = {
              ...descriptionChartData,
              description:
                descriptionData.story || descriptionChartData.description,
              insights:
                descriptionData.insights?.join(" ") ||
                descriptionChartData.insights,
            };
          }

          switch (chart?.chartType) {
            case "mapChart":
              mapChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "areaChart":
              areaChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "lineChart":
              lineChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "sparkLineChart":
              sparkLineChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "sparkAreaChart":
              sparkAreaChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "barChart":
              barChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "lollipopChart":
              lollipopChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "sankey":
              sankeyChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "matrix":
              matrixChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "polarAreaChart":
              polarAreaChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "punchcardChart":
              punchcardChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "radar":
              radarChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "scatterplot":
              scatterplotChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "bubbleChart":
              bubbleChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            case "radialBarChart":
              radialBarChartRequestData.push({
                id: chart.id,
                blockId: chart?.blockId!,
                ...descriptionChartData,
              });
              break;
            default:
          }
        });
      }
      const existCharts =
        barChartRequestData?.length +
        lineChartRequestData?.length +
        sparkLineChartRequestData?.length +
        sparkAreaChartRequestData?.length +
        lollipopChartRequestData?.length +
        sankeyChartRequestData?.length +
        areaChartRequestData?.length +
        matrixChartRequestData?.length +
        radarChartRequestData?.length +
        scatterplotChartRequestData?.length +
        punchcardChartRequestData?.length +
        polarAreaChartRequestData?.length +
        radialBarChartRequestData?.length +
        bubbleChartRequestData?.length;

      if (existCharts > 0) {
        dispatch(
          requestUpdateWidgets({
            barChart: barChartRequestData,
            lineChart: lineChartRequestData,
            sparkLineChart: sparkLineChartRequestData,
            sparkAreaChart: sparkAreaChartRequestData,
            lollipopChart: lollipopChartRequestData,
            sankeyChart: sankeyChartRequestData,
            areaChart: areaChartRequestData,
            matrixChart: matrixChartRequestData,
            mapChart: mapChartRequestData,
            scatterplotChart: scatterplotChartRequestData,
            polarAreaChart: polarAreaChartRequestData,
            punchcardChart: punchcardChartRequestData,
            radarChart: radarChartRequestData,
            bubbleChart: bubbleChartRequestData,
            radialBarChart: radialBarChartRequestData,
            pageId: currentPageId!,
          })
        );
      }
      const noHavePageDescription = !(header?.description && header?.title);
      if (noHavePageDescription) {
        setTimeout(() => {
          generateBanner();
        }, 600);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPageId, header, pageWidgets?.items]
  );

  const checkCreateDescriptions = useCallback(() => {
    const noHaveDescriptions = pageWidgets?.items?.some(
      (w) => !w.description || w.description === "Add here description"
    );

    if (!noHaveDescriptions) {
      return;
    }

    if (pageWidgets?.count > 0 && firstLoad !== currentPageId) {
      setFirstLoad(currentPageId);
    }

    dispatch(
      requestPageStories({
        pageId: currentPageId,
        projectId: currentProjectId,
        callbacks: {
          onSuccess: (resp) => {
            handleEditPage(resp?.widget_descriptions);
            if (
              pageWidgets?.items?.length !== resp?.widget_descriptions?.length
            ) {
              setTimeout(() => {
                checkCreateDescriptions();
              }, 500);
            }
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    header,
    currentPageId,
    currentProjectId,
    firstLoad,
    pageWidgets?.count,
    pageWidgets?.items,
  ]);

  useEffect(() => {
    checkCreateDescriptions();
  }, [checkCreateDescriptions]);

  useEffect(() => {
    if (pageWidgets.items) {
      const widgets = pageWidgets?.items
        ?.map((el, i): PageContentGeneratorItemType => {
          return {
            ...el,
            blockId: (Number(el.blockId) || 1) as any,
            sectionType: el.chartType === "mapChart" ? "map" : "widget",
          };
        })
        .sort(
          (a, b) =>
            //@ts-ignore
            a?.blockId - b?.blockId
        );

      dispatch(addWidgets(widgets));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageWidgets]);

  const setBLockId = (
    currentBlockId: number,
    fromPosition: number,
    toPosition: number
  ): number => {
    const isPositive = fromPosition > toPosition;
    let minPosition = Math.min(fromPosition, toPosition);
    let maxPosition = Math.max(fromPosition, toPosition);

    if (currentBlockId >= minPosition && currentBlockId <= maxPosition) {
      if (currentBlockId === fromPosition) {
        return toPosition;
      } else {
        return currentBlockId + (isPositive ? 1 : -1);
      }
    }

    return currentBlockId;
  };

  const updatePromiseSection = async (
    section: IUpdateSection,
    blockId: string
  ) => {
    return new Promise((resolve) => {
      dispatch(
        requestUpdatePageSections({
          ...section,
          blockId: blockId,
          callbacks: {
            onSuccess: (data) => {
              return resolve(data);
            },
          },
        })
      );
    });
  };

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) return;
    const from = result?.source?.index;
    const to = result?.destination?.index;
    if (from !== to) {
      let barChartRequestData: UpdateWidgetDTO[] = [];
      let lineChartRequestData: UpdateWidgetDTO[] = [];
      let sparkLineChartRequestData: UpdateWidgetDTO[] = [];
      let sparkAreaChartRequestData: UpdateWidgetDTO[] = [];
      let lollipopChartRequestData: UpdateWidgetDTO[] = [];
      let sankeyChartRequestData: UpdateWidgetDTO[] = [];
      let mapChartRequestData: UpdateWidgetDTO[] = [];
      let areaChartRequestData: UpdateWidgetDTO[] = [];
      let matrixChartRequestData: UpdateWidgetDTO[] = [];
      let scatterplotChartRequestData: UpdateWidgetDTO[] = [];
      let radarChartRequestData: UpdateWidgetDTO[] = [];
      let punchcardChartRequestData: UpdateWidgetDTO[] = [];
      let polarAreaChartRequestData: UpdateWidgetDTO[] = [];
      let bubbleChartUpdateData: UpdateWidgetDTO[] = [];
      let radialBarChartUpdateData: UpdateWidgetDTO[] = [];

      const newWidgets = [];
      for (let i = 0; i < pageWidgets?.items?.length; i++) {
        const chart = pageWidgets?.items[i];
        const curentChartBlockId = parseInt(chart.blockId!);
        const blockId: number = setBLockId(curentChartBlockId, from, to);

        newWidgets.push({ ...chart, blockId: blockId?.toString() });
        switch (chart?.chartType) {
          case "mapChart":
            mapChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "areaChart":
            areaChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "lineChart":
            lineChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "sparkLineChart":
            sparkLineChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "sparkAreaChart":
            sparkAreaChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "barChart":
            barChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "lollipopChart":
            lollipopChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "sankey":
            sankeyChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "matrix":
            matrixChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "polarAreaChart":
            polarAreaChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "punchcardChart":
            punchcardChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "radar":
            radarChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "scatterplot":
            scatterplotChartRequestData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "bubbleChart":
            bubbleChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          case "radialBarChart":
            radialBarChartUpdateData.push({
              id: chart.id,
              blockId: blockId?.toString(),
            });
            break;
          default:
        }
      }

      const promises = [];

      for (let i = 0; i < sections?.length; i++) {
        const section = sections[i];
        const curentBlockId = parseInt(section.blockId!);
        const blockId: number = setBLockId(curentBlockId, from, to);

        if (blockId !== curentBlockId) {
          promises.push(updatePromiseSection(section, blockId?.toString()));
        }
      }

      if (promises?.length) {
        await Promise.all(promises);
        getAllSections();
      }

      const existCharts =
        barChartRequestData?.length +
        lineChartRequestData?.length +
        sparkLineChartRequestData?.length +
        sparkAreaChartRequestData?.length +
        lollipopChartRequestData?.length +
        sankeyChartRequestData?.length +
        areaChartRequestData?.length +
        matrixChartRequestData?.length +
        mapChartRequestData?.length +
        radarChartRequestData?.length +
        scatterplotChartRequestData?.length +
        punchcardChartRequestData?.length +
        polarAreaChartRequestData?.length +
        radialBarChartUpdateData?.length +
        bubbleChartUpdateData?.length;

      if (existCharts > 0) {
        dispatch(
          setPageWidgets({ items: newWidgets, count: newWidgets?.length })
        );
        dispatch(
          requestUpdateWidgets({
            barChart: barChartRequestData,
            lineChart: lineChartRequestData,
            sparkLineChart: sparkLineChartRequestData,
            sparkAreaChart: sparkAreaChartRequestData,
            lollipopChart: lollipopChartRequestData,
            sankeyChart: sankeyChartRequestData,
            areaChart: areaChartRequestData,
            matrixChart: matrixChartRequestData,
            mapChart: mapChartRequestData,
            scatterplotChart: scatterplotChartRequestData,
            polarAreaChart: polarAreaChartRequestData,
            punchcardChart: punchcardChartRequestData,
            radarChart: radarChartRequestData,
            bubbleChart: bubbleChartUpdateData,
            radialBarChart: radialBarChartUpdateData,
            pageId: currentPage.id!,
          })
        );
      }
    }
  };

  const combineItems = useCallback(() => {
    const newItems = [...items, ...sections];

    const filteredItems = newItems.sort(
      (a, b) => parseInt(a.blockId) - parseInt(b.blockId)
    );
    setLocalItems(filteredItems);
  }, [items, sections]);

  useEffect(() => {
    combineItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, sections]);

  const updateWidgetsStorytelling = (positionItem: number, addPosition = 1) => {
    let barChartUpdateData: UpdateWidgetDTO[] = [];
    let lineChartUpdateData: UpdateWidgetDTO[] = [];
    let sparkLineChartUpdateData: UpdateWidgetDTO[] = [];
    let sparkAreaChartUpdateData: UpdateWidgetDTO[] = [];
    let lollipopChartUpdateData: UpdateWidgetDTO[] = [];
    let sankeyChartUpdateData: UpdateWidgetDTO[] = [];
    let mapChartUpdateData: UpdateWidgetDTO[] = [];
    let areaChartUpdateData: UpdateWidgetDTO[] = [];
    let matrixChartUpdateData: UpdateWidgetDTO[] = [];
    let scatterplotChartUpdateData: UpdateWidgetDTO[] = [];
    let radarChartUpdateData: UpdateWidgetDTO[] = [];
    let punchcardChartUpdateData: UpdateWidgetDTO[] = [];
    let polarAreaChartUpdateData: UpdateWidgetDTO[] = [];
    let bubbleChartUpdateData: UpdateWidgetDTO[] = [];
    let radialBarChartUpdateData: UpdateWidgetDTO[] = [];

    const newWidgets = [];
    for (let widget of pageWidgets.items) {
      const blockId =
        widget?.blockId && parseInt(widget?.blockId) >= positionItem
          ? parseInt(widget?.blockId) + localItems.length
          : parseInt(widget?.blockId!);

      newWidgets.push({ ...widget, blockId: blockId?.toString() });

      switch (widget.chartType) {
        case "mapChart":
          mapChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "areaChart":
          areaChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "lineChart":
          lineChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "sparkLineChart":
          sparkLineChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "sparkAreaChart":
          sparkAreaChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "barChart":
          barChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "lollipopChart":
          lollipopChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "sankey":
          sankeyChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "matrix":
          matrixChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "polarAreaChart":
          polarAreaChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "punchcardChart":
          punchcardChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "radar":
          radarChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "bubbleChart":
          bubbleChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "scatterplot":
          scatterplotChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        case "radialBarChart":
          radialBarChartUpdateData.push({
            id: widget.id,
            blockId: blockId?.toString(),
          });
          break;
        default:
      }
    }

    const existCharts =
      barChartUpdateData?.length +
      lineChartUpdateData?.length +
      sparkLineChartUpdateData?.length +
      sparkAreaChartUpdateData?.length +
      lollipopChartUpdateData?.length +
      sankeyChartUpdateData?.length +
      areaChartUpdateData?.length +
      matrixChartUpdateData?.length +
      mapChartUpdateData?.length +
      scatterplotChartUpdateData?.length +
      radarChartUpdateData?.length +
      punchcardChartUpdateData?.length +
      polarAreaChartUpdateData?.length +
      radialBarChartUpdateData?.length +
      bubbleChartUpdateData?.length;

    if (currentPage?.id && existCharts > 0) {
      if (existCharts > 0) {
        dispatch(
          setPageWidgets({ items: newWidgets, count: newWidgets?.length })
        );

        dispatch(
          requestCreateAndUpdateWidgets({
            createBarChart: [],
            createLineChart: [],
            createSparkLineChart: [],
            createSparkAreaChart: [],
            createLollipopChart: [],
            createMapChart: [],
            createSankeyChart: [],
            createAreaChart: [],
            createMatrixChart: [],
            createPolarAreaChart: [],
            createPunchcardChart: [],
            createRadarChart: [],
            createScatterplotChart: [],
            createBubbleChart: [],
            createRadialBarChart: [],
            barChart: barChartUpdateData,
            lineChart: lineChartUpdateData,
            sparkLineChart: sparkLineChartUpdateData,
            sparkAreaChart: sparkAreaChartUpdateData,
            lollipopChart: lollipopChartUpdateData,
            sankeyChart: sankeyChartUpdateData,
            areaChart: areaChartUpdateData,
            matrixChart: matrixChartUpdateData,
            mapChart: mapChartUpdateData,
            scatterplotChart: scatterplotChartUpdateData,
            polarAreaChart: polarAreaChartUpdateData,
            punchcardChart: punchcardChartUpdateData,
            radarChart: radarChartUpdateData,
            bubbleChart: bubbleChartUpdateData,
            radialBarChart: radialBarChartUpdateData,
            pageId: currentPage?.id,
          })
        );
      }
    }
  };

  const addNewSection = (blockId: number) => {
    dispatch(
      requestCreatePageSections({
        ...defaultSection,
        pageId: currentPageId!,
        blockId: blockId.toString(),
        callbacks: {
          onSuccess: () => {
            updateWidgetsStorytelling(blockId);
            getAllSections();
          },
        },
      })
    );
  };

  const updateSection = (section: IUpdateSection) => {
    dispatch(
      requestUpdatePageSections({
        ...section,
        callbacks: {
          onSuccess: () => {
            getAllSections();
          },
        },
      })
    );
  };

  const removeSection = (section: IRemoveSection) => {
    if (currentPageId)
      dispatch(
        requestRemovePageSections({
          ...section,
          pageId: currentPageId || "",
          callbacks: {
            onSuccess: () => {
              getAllSections();
            },
          },
        })
      );
  };

  const handleSlelectWidget = (id: string) => {
    if (isCustomeCursor) {
      dispatch(setSelectWidgetId(id));
      dispatch(toggleCommentsCursor(false));
      // dispatch(setIsEditMode(true));
    }
  };

  return (
    <PageWrapper id={currentPageId}>
      {!isPublic && isEditMode && !header && (
        <AddStorytellingSection
          addSection={() => {
            dispatch(
              requestUpdatePage({ ...currentPage, header: defaultHeader })
            );
          }}
          addWidget={() => {
            dispatch(
              setActiveModal({
                id: "pageRecommendedWidgetsModal",
                firstItem: 1,
              })
            );
          }}
        />
      )}

      {currentPageId && localItems?.length > 0 ? (
        <TextImage
          currentPageId={currentPageId!}
          contentItem={contentItem}
          setContentItem={debounced}
        />
      ) : null}

      {aiKpi.items?.length ? (
        <KpiContainer onClick={() => handleSlelectWidget(aiKpi.items?.[0]?.id)}>
          <Comments isStorytelling id={aiKpi.items?.[0]?.id} />
          <KPISettingsWrapperStory kpis={aiKpi.items} />
        </KpiContainer>
      ) : null}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-1">
          {(provided) => (
            <div
              {...provided.droppableProps}
              // style={{ gap: "8px" }}
              ref={provided.innerRef}
            >
              {!!localItems?.length &&
                localItems?.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={parseInt(item.blockId!)}
                    isDragDisabled={!isEditMode || isPublic}
                  >
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: "none",
                            minHeight: "50px",
                            margin: "8px",
                            position: "relative",
                            ...provided.draggableProps.style,
                          }}
                          onClick={() => handleSlelectWidget(item?.id)}
                        >
                          <Comments isStorytelling id={item?.id} />
                          {item?.chartType ? (
                            <>
                              {!isPublic && isEditMode && (
                                <AddStorytellingSection
                                  addSection={() =>
                                    addNewSection(parseInt(item.blockId))
                                  }
                                  addWidget={() => {
                                    dispatch(
                                      setActiveModal({
                                        id: "pageRecommendedWidgetsModal",
                                        firstItem: parseInt(item.blockId),
                                      })
                                    );
                                  }}
                                />
                              )}
                              <SectionItem
                                draggable={snapshot.isDragging}
                                key={`${item.id}_${index}`}
                                setContentItem={debouncedUpdateWidget}
                                item={item}
                              />
                            </>
                          ) : (
                            <SectionTextImage
                              draggable={snapshot.isDragging}
                              contentItem={item}
                              key={`${item.id}_${index}`}
                              setContentItem={updateSection}
                              removeSection={removeSection}
                            />
                          )}
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </PageWrapper>
  );
};
